let  hospital_name = [{"value": "平定县石门口乡卫生院（平定县医疗集团石门口乡分院）","address": ""},
    {"value": "大同康洁眼科医院","address": ""},
    {"value": "平定县妇幼保健院","address": ""},
    {"value": "太原市尖草坪区精诚门诊部","address": ""},
    {"value": "繁峙县中医院","address": ""},
    {"value": "太谷慈济医院","address": ""},
    {"value": "宁武县新堡乡卫生院","address": ""},
    {"value": "宁武县医疗集团怀道乡卫生院","address": ""},
    {"value": "宁武县余庄乡卫生院","address": ""},
    {"value": "宁武县迭台寺乡卫生院","address": ""},
    {"value": "宁武县西马坊乡卫生院","address": ""},
    {"value": "宁武县东马坊乡卫生院","address": ""},
    {"value": "宁武县东寨镇中心卫生院","address": ""},
    {"value": "宁武县医疗集团阳方口镇卫生院","address": ""},
    {"value": "大同煤矿集团阳方口矿业有限责任公司职工医院","address": ""},
    {"value": "宁武县中医院","address": ""},
    {"value": "宁武县薛家洼乡中心卫生院","address": ""},
    {"value": "平遥仁康医院","address": ""},
    {"value": "静乐县康家会镇中心卫生院（静乐县康家会镇妇幼保健计划生育服务站、静乐县医疗集团康家会镇中心卫生院）","address": ""},
    {"value": "静乐县娑婆乡卫生院（静乐县娑婆乡妇幼保健计划生育服务站、静乐县医疗集团娑婆乡卫生院）","address": ""},
    {"value": "平遥东兴心血管医院","address": ""},
    {"value": "静乐县娘子神乡卫生院（静乐县娘子神乡妇幼保健计划生育服务站、静乐县医疗集团娘子神乡卫生院）","address": ""},
    {"value": "静乐县段家寨乡中心卫生院（静乐县段家寨乡妇幼保健计划生育服务站、静乐县医疗集团段家寨乡中心卫生院）","address": ""},
    {"value": "静乐县双路镇卫生院（静乐县双路镇妇幼保健计划生育服务站、静乐县医疗集团双路镇卫生院）","address": ""},
    {"value": "静乐县双路镇卫生院中庄分院（静乐县双路镇卫生院中庄分院妇幼保健计划生育服务站、静乐县医疗集团双路镇卫生院中庄分院","address": ""},
    {"value": "静乐县神峪沟乡卫生院（静乐县神峪沟乡妇幼保健计划生育服务站、静乐县医疗集团神峪沟乡卫生院）","address": ""},
    {"value": "静乐县双路乡卫生院泉庄分院（静乐县医疗集团双路乡卫生院泉庄分院）","address": ""},
    {"value": "静乐县段家寨乡中心卫生院岔上分院（静乐县医疗集团段家寨乡中心卫生院岔上分院）","address": ""},
    {"value": "平遥康益内分泌医院","address": ""},
    {"value": "平遥精神疾病安康医院","address": ""},
    {"value": "平遥兴康医院","address": ""},
    {"value": "平遥康明眼科医院有限公司","address": ""},
    {"value": "灵石县肛泰肛肠医院","address": ""},
    {"value": "神池县八角镇中心卫生院（神池县医疗集团八角镇卫生院）","address": ""},
    {"value": "神池县贺职乡卫生院（神池县医疗集团贺职乡卫生院）","address": ""},
    {"value": "神池县龙泉镇卫生院（神池县医疗集团龙泉镇卫生院）","address": ""},
    {"value": "灵石民生医院","address": ""},
    {"value": "神池县义井镇中心卫生院（神池县医疗集团义井镇卫生院）","address": ""},
    {"value": "灵石普爱医院","address": ""},
    {"value": "神池县中医院","address": ""},
    {"value": "五台台城百姓诊疗有限公司","address": ""},
    {"value": "永济市开张镇卫生院黄营分院","address": ""},
    {"value": "大同西京中西医结合医院有限公司","address": ""},
    {"value": "乡宁县关王庙乡中心卫生院安汾分院","address": ""},
    {"value": "晋能控股装备制造集团有限公司寺河煤矿二号井医务室","address": ""},
    {"value": "太原市尖草坪区迎新街社区卫生服务中心","address": ""},
    {"value": "太原佳鑫口腔门诊部","address": ""},
    {"value": "五寨县李家坪乡卫生院（五寨县医疗集团李家坪乡卫生院）","address": ""},
    {"value": "五寨县新寨乡卫生院（五寨县医疗集团新寨乡卫生院）","address": ""},
    {"value": "五寨县胡会乡卫生院（五寨县医疗集团胡会乡卫生院）","address": ""},
    {"value": "五寨县前所乡中心卫生院（五寨县医疗集团前所乡中心卫生院）","address": ""},
    {"value": "五寨县孙家坪乡中心卫生院（五寨县医疗集团孙家坪乡中心卫生院）","address": ""},
    {"value": "五寨县中医院","address": ""},
    {"value": "大同新和医院","address": ""},
    {"value": "山西天瑞康康复医院","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处振武街社区卫生服务站","address": ""},
    {"value": "陵川县崇文镇卫生院","address": ""},
    {"value": "临汾临建外科医院（有限公司）","address": ""},
    {"value": "临汾经济技术开发区新时代口腔门诊部","address": ""},
    {"value": "太原市尖草坪区尖草坪社区卫生服务中心","address": ""},
    {"value": "古交市原相乡卫生院（古交市医疗集团原相乡卫生院）","address": ""},
    {"value": "太原市万柏林区三益西社区卫生服务站","address": ""},
    {"value": "灵石县东城社区卫生服务站","address": ""},
    {"value": "太原优唐糖友智慧中医门诊部有限公司杏花岭羊市街门诊部","address": ""},
    {"value": "大宁县医疗集团昕水镇卫生院","address": ""},
    {"value": "定襄县医疗集团晋昌镇卫生院","address": ""},
    {"value": "隰县医疗集团下李乡卫生院","address": ""},
    {"value": "山西盈康一生总医院","address": ""},
    {"value": "北石店镇阳光地带社区卫生服务站","address": ""},
    {"value": "晋城晋东骨科医院","address": ""},
    {"value": "河津市妇幼保健计划生育服务中心","address": ""},
    {"value": "河津社联医院","address": ""},
    {"value": "交口县桃红坡镇中心卫生院坛索分院","address": ""},
    {"value": "运城益民中医康复医院","address": ""},
    {"value": "静乐县神峪沟乡卫生院择善分院","address": ""},
    {"value": "定襄增寿口腔门诊部","address": ""},
    {"value": "云冈区平泉新回里卫生服务站","address": ""},
    {"value": "灵丘县东河南中心卫生院银厂分院","address": ""},
    {"value": "翼城县桥上镇卫生院（翼城县医疗集团桥上镇卫生院、桥上镇妇幼保健计划生育服务站）","address": ""},
    {"value": "山西省晋城市沁水县端氏镇卫生院","address": ""},
    {"value": "太原毓舫医疗管理有限公司小店平阳路综合门诊部","address": ""},
    {"value": "永济市赵柏社区卫生服务中心","address": ""},
    {"value": "盂县微笑口腔门诊部","address": ""},
    {"value": "襄汾县第二人民医院","address": ""},
    {"value": "朔州骨外科医院","address": ""},
    {"value": "河津市清涧办事处毓秀社区卫生服务站","address": ""},
    {"value": "晋中市榆次区路西街道晋星社区卫生服务站","address": ""},
    {"value": "朔州市开发区鸿泰门诊部","address": ""},
    {"value": "朔州市平鲁区桃苑卫生服务站","address": ""},
    {"value": "岢岚县宋家沟中心卫生院","address": ""},
    {"value": "长治市潞州区太行西街街道威远门北路社区卫生服务站","address": ""},
    {"value": "阳泉市安泰时代科技有限公司第一中医门诊部","address": ""},
    {"value": "长治市潞州区延安南路街道淮北社区卫生服务站","address": ""},
    {"value": "稷山县骨髓炎医院","address": ""},
    {"value": "五台县金岗库乡卫生院","address": ""},
    {"value": "洪洞县万安镇中心卫生院","address": ""},
    {"value": "（洪洞县医疗集团万安镇中心卫生院）","address": ""},
    {"value": "临汾市尧都区医疗集团段店乡卫生院","address": ""},
    {"value": "马兰镇中心卫生院安平街分院","address": ""},
    {"value": "太原市晋源区南城角社区卫生服务站","address": ""},
    {"value": "临县医疗集团人民医院","address": ""},
    {"value": "长治市潞州区延安南路街道淮海社区卫生服务西站","address": ""},
    {"value": "原平市轩岗镇中心卫生院（原平市医疗集团轩岗镇中心卫生院）","address": ""},
    {"value": "原平市医疗集团（原平市第一人民医院、原平市医疗集团第一人民医院）","address": ""},
    {"value": "原平市大林乡卫生院（原平市医疗集团大林乡卫生院）","address": ""},
    {"value": "原平市南白乡卫生院（原平市医疗集团南白乡卫生院）","address": ""},
    {"value": "原平市段家堡乡卫生院（原平市医疗集团段家堡乡卫生院）","address": ""},
    {"value": "介休三佳整骨专科医院","address": ""},
    {"value": "介休康和整骨专科医院","address": ""},
    {"value": "介休创伤外科医院","address": ""},
    {"value": "原平市云水镇卫生院楼板寨分院（原平市医疗集团云水镇卫生院楼板寨分院）","address": ""},
    {"value": "介休顾氏中医整骨专科医院","address": ""},
    {"value": "原平市第二人民医院（原平市医疗集团第二人民医院）","address": ""},
    {"value": "介休董氏医院","address": ""},
    {"value": "介休绵山医院","address": ""},
    {"value": "介休宏生医院","address": ""},
    {"value": "介休定阳肛肠医院","address": ""},
    {"value": "介休朝阳医院","address": ""},
    {"value": "介休市第二人民医院","address": ""},
    {"value": "介休德康创伤手足外科医院","address": ""},
    {"value": "原平市东社镇中心卫生院（原平市医疗集团东社镇中心卫生院）","address": ""},
    {"value": "介休北辛武整骨专科医院","address": ""},
    {"value": "原平市闫庄镇中心卫生院（原平市医疗集团闫庄镇中心卫生院）","address": ""},
    {"value": "原平市南城社区卫生服务中心（原平市医疗集团南城社区卫生服务中心）","address": ""},
    {"value": "山西铝厂职工医院","address": ""},
    {"value": "运城新康中医肝病医院","address": ""},
    {"value": "闻喜复康心脑专科医院","address": ""},
    {"value": "运城东康医院","address": ""},
    {"value": "运城新民中医院","address": ""},
    {"value": "风陵渡开发区博雅妇幼专科医院","address": ""},
    {"value": "山西省风陵渡经济开发区骨伤专科医院","address": ""},
    {"value": "运城安心中医精神病医院","address": ""},
    {"value": "运城同德医院","address": ""},
    {"value": "新绛县脑病专科医院","address": ""},
    {"value": "运城肛肠病专科医院","address": ""},
    {"value": "永济口腔医院","address": ""},
    {"value": "运城曙光医院","address": ""},
    {"value": "永济安可儿妇产医院","address": ""},
    {"value": "河津昕昱医院","address": ""},
    {"value": "晋中市第一人民医院（山西医科大学附属医院）","address": ""},
    {"value": "运城建武中医白癜风病专科医院","address": ""},
    {"value": "运城耳鼻喉医院","address": ""},
    {"value": "永济黄河中医院","address": ""},
    {"value": "运城皮肤病医院","address": ""},
    {"value": "运城慈爱中医心理疾病医院","address": ""},
    {"value": "晋中市传染病医院","address": ""},
    {"value": "永济电机医院","address": ""},
    {"value": "晋中市中医院","address": ""},
    {"value": "运城武氏中医院","address": ""},
    {"value": "运城仁安睡眠病医院","address": ""},
    {"value": "山西省风陵渡开发区济民医院","address": ""},
    {"value": "运城经济开发区社区卫生服务中心","address": ""},
    {"value": "运城惠仁医院","address": ""},
    {"value": "榆次区使赵卫生院（榆次区医疗集团使赵卫生院）","address": ""},
    {"value": "运城泌尿医院","address": ""},
    {"value": "运城空港开发区社区卫生服务中心","address": ""},
    {"value": "运城经济开发区安中妇幼保健院","address": ""},
    {"value": "新绛康复医院","address": ""},
    {"value": "晋中市第四人民医院","address": ""},
    {"value": "运城医磊结石病专科医院","address": ""},
    {"value": "运城康爱中医血液病医院","address": ""},
    {"value": "运城华荣中医风湿病专科医院","address": ""},
    {"value": "河津延坤中医院","address": ""},
    {"value": "运城现代妇科医院","address": ""},
    {"value": "运城市盐湖区南城办事处圣惠嘉园社区卫生服务站","address": ""},
    {"value": "运城市盐湖区南城办事处银湖街社区卫生服务站","address": ""},
    {"value": "运城立明眼科医院","address": ""},
    {"value": "偏关县尚峪卫生院","address": ""},
    {"value": "北大街中社区卫生服务站","address": ""},
    {"value": "太谷县夕阳红养老院医务室","address": ""},
    {"value": "文水县下曲镇中心卫生院","address": ""},
    {"value": "太原恒伦并州路口腔门诊部有限公司","address": ""},
    {"value": "吕梁市离石区滨河街道办锦绣社区卫生服务站","address": ""},
    {"value": "云冈区民胜社区卫生服务中心","address": ""},
    {"value": "太原东山口腔门诊部","address": ""},
    {"value": "沁州爱民医院","address": ""},
    {"value": "孝义市医疗集团崇文分院（孝义市崇文社区卫生服务中心）","address": ""},
    {"value": "长治市第二人民医院","address": ""},
    {"value": "天镇县医疗集团马家皂乡卫生院","address": ""},
    {"value": "河津市城区办事处新兴社区卫生服务（一）站","address": ""},
    {"value": "河曲县皮肤病专科医院","address": ""},
    {"value": "河曲县中医医院","address": ""},
    {"value": "保德县医疗集团（保德县人民医院、保德县医疗集团人民医院）","address": ""},
    {"value": "偏关县医疗集团（偏关县人民医院、偏关县医疗集团人民医院）","address": ""},
    {"value": "岚县达康卫生服务站","address": ""},
    {"value": "吕梁市离石区凤山街道办和平社区卫生服务站","address": ""},
    {"value": "太原市尖草坪区西墕乡卫生院","address": ""},
    {"value": "介休市义安镇中心卫生院万户堡分院","address": ""},
    {"value": "原平市崞阳镇中心卫生院","address": ""},
    {"value": "吕梁市离石区凤山街道办久安社区卫生服务站","address": ""},
    {"value": "吕梁市中医药研究院","address": ""},
    {"value": "大同市平城区京华医院","address": ""},
    {"value": "广灵陆元康心理医院","address": ""},
    {"value": "朔州亿厚医院","address": ""},
    {"value": "岚县利民医院","address": ""},
    {"value": "孝义市胜溪中医门诊部","address": ""},
    {"value": "阳泉市矿区医疗集团（阳泉市矿区医院）","address": ""},
    {"value": "太原不锈钢产业园区社区卫生服务中心","address": ""},
    {"value": "侯马金方堂中医门诊部有限公司","address": ""},
    {"value": "太原市杏花岭区沙河堡社区卫生服务站","address": ""},
    {"value": "柳林县于玥口腔门诊","address": ""},
    {"value": "云冈区四台社区卫生服务中心","address": ""},
    {"value": "晋中市老年养护院医务室","address": ""},
    {"value": "绛县医疗集团郝庄乡卫生院","address": ""},
    {"value": "太原市杏花岭区东华门社区卫生服务站","address": ""},
    {"value": "五台县建安乡卫生院（五台县建安乡妇幼保健服务站、五台县医疗集团建安乡卫生院）","address": ""},
    {"value": "柳林灵光医院","address": ""},
    {"value": "榆次区北田镇中心卫生院（榆次区医疗集团北田镇中心卫生院）","address": ""},
    {"value": "晋中市榆次区学苑医院","address": ""},
    {"value": "山西省汾西县人民医院","address": ""},
    {"value": "临汾盛泽微创外科医院有限公司","address": ""},
    {"value": "大同瑞慈康复医院","address": ""},
    {"value": "太原卓雅口腔门诊部有限责任公司","address": ""},
    {"value": "新荣华瑞中西医结合医院","address": ""},
    {"value": "静乐县鹅城镇小康苑卫生服务中心","address": ""},
    {"value": "天脊医院","address": ""},
    {"value": "侯马市紫金山南街社区卫生服务站","address": ""},
    {"value": "吕梁市离石区田家会街道办高崖湾社区卫生服务站","address": ""},
    {"value": "偏关县医疗集团万家寨镇卫生院","address": ""},
    {"value": "太原市小店区太航社区卫生服务站","address": ""},
    {"value": "山西老龄胃肠病医院","address": ""},
    {"value": "定襄县医疗集团南王乡中心卫生院官庄分院","address": ""},
    {"value": "临汾市尧都区西街办事处鼓楼社区卫生服务站","address": ""},
    {"value": "平定县城北社区卫生服务站","address": ""},
    {"value": "高平铭卓口腔门诊部","address": ""},
    {"value": "山西航空产业集团有限公司门诊部","address": ""},
    {"value": "云冈区永定庄社区卫生服务中心","address": ""},
    {"value": "太原市小店区小马社区卫生服务站","address": ""},
    {"value": "太原中山生殖医学医院","address": ""},
    {"value": "平城区向阳里源茂街社区卫生服务站","address": ""},
    {"value": "太原博冠口腔门诊部有限公司万柏林丽华门诊部","address": ""},
    {"value": "中国人民武装警察部队山西省总队医院","address": ""},
    {"value": "保德县腰庄乡卫生院","address": ""},
    {"value": "绛县佳俊口腔门诊部","address": ""},
    {"value": "交城县医疗集团洪相镇卫生院","address": ""},
    {"value": "运城厚朴中医院","address": ""},
    {"value": "运城市盐湖区空港新区社区卫生服务中心","address": ""},
    {"value": "运城双有医院","address": ""},
    {"value": "运城市盐湖区北城办事处康乐社区卫生服务站","address": ""},
    {"value": "运城市盐湖区北城办事处天和社区卫生服务站","address": ""},
    {"value": "运城市盐湖区东城办事处名邸社区卫生服务站","address": ""},
    {"value": "运城战军中医院","address": ""},
    {"value": "盐湖爱雅口腔门诊部","address": ""},
    {"value": "运城市盐湖区北城办事处新桥社区卫生服务站","address": ""},
    {"value": "运城华仁医院","address": ""},
    {"value": "运城博仁骨伤专科医院","address": ""},
    {"value": "运城众泰肛肠医院","address": ""},
    {"value": "运城华康医院","address": ""},
    {"value": "运城忠良中医颈肩腰腿痛专科医院","address": ""},
    {"value": "运城仁和堂中医院","address": ""},
    {"value": "运城市盐湖区东城新区康馨门诊部","address": ""},
    {"value": "运城陈波脉管炎专科医院","address": ""},
    {"value": "运城惠民中医康复医院","address": ""},
    {"value": "运城市盐湖区西城办事处红旗西街社区卫生服务站","address": ""},
    {"value": "运城民生泌尿专科医院","address": ""},
    {"value": "运城福东中医医院","address": ""},
    {"value": "运城盛义堂颈肩腰腿痛医院","address": ""},
    {"value": "运城博爱妇婴医院","address": ""},
    {"value": "运城广文医院","address": ""},
    {"value": "运城庄华骨关节病医院","address": ""},
    {"value": "运城崇济医院","address": ""},
    {"value": "运城市盐湖区北城办事处葡萄园社区卫生服务站","address": ""},
    {"value": "运城莲菊医院","address": ""},
    {"value": "运城市盐湖区北城办事处理想城社区卫生服务站","address": ""},
    {"value": "运城盐湖天明医院","address": ""},
    {"value": "运城市盐湖区安邑办事处禹都社区卫生服务站","address": ""},
    {"value": "运城牛记齿科医院","address": ""},
    {"value": "运城市盐湖区安邑办事处学府名都社区卫生服务站","address": ""},
    {"value": "运城盐湖朝阳医院","address": ""},
    {"value": "运城市盐湖区北城办事处四季绿城社区卫生服务站","address": ""},
    {"value": "运城市盐湖区北城办事处兴棉社区卫生服务站","address": ""},
    {"value": "运城市盐湖区姚孟办事处五洲观澜社区卫生服务站","address": ""},
    {"value": "运城市盐湖区安邑办事处东方华城社区卫生服务站","address": ""},
    {"value": "运城市盐湖区东城办事处禹都花园社区卫生服务站","address": ""},
    {"value": "运城市盐湖区东城办事处槐东社区卫生服务站","address": ""},
    {"value": "运城玛丽妇科医院","address": ""},
    {"value": "太原恒瑞口腔门诊部","address": ""},
    {"value": "吕梁市离石区凤山街道办美丽社区卫生服务站","address": ""},
    {"value": "晋城市城区南街街道办事处驿后社区卫生服务站","address": ""},
    {"value": "晋城市城区钟家庄街道办事处文峰社区卫生服务站","address": ""},
    {"value": "永济市医疗集团城东街道社区卫生服务中心（永济市第四医院）","address": ""},
    {"value": "芮城县妇幼保健计划生育服务中心","address": ""},
    {"value": "高平艾瑞特口腔门诊部","address": ""},
    {"value": "吕梁市荣军医院","address": ""},
    {"value": "清徐县王答乡卫生院","address": ""},
    {"value": "中北大学医院","address": ""},
    {"value": "阳城县东冶镇中心卫生院","address": ""},
    {"value": "古交市河口镇中心卫生院（古交市医疗集团河口镇中心卫生院）","address": ""},
    {"value": "运城市盐湖区妇幼保健计划生育服务中心（运城市盐湖区妇幼保健院）","address": ""},
    {"value": "绛县卫庄镇卫生院（绛县医疗集团卫庄镇卫生院）","address": ""},
    {"value": "侯马市浍滨街道办事处浍滨南社区卫生服务站","address": ""},
    {"value": "大同市云冈区西韩岭乡卫生院","address": ""},
    {"value": "大同云冈安益康中西医结合医院","address": ""},
    {"value": "盂县阳光百姓药房有限公司龙潭门诊","address": ""},
    {"value": "清徐县马峪乡卫生院碾底分院","address": ""},
    {"value": "临汾市尧都区车站街办事处向阳社区卫生服务站","address": ""},
    {"value": "霍州市医疗集团大张镇下乐坪卫生分院","address": ""},
    {"value": "盂县医疗集团下社乡卫生院","address": ""},
    {"value": "兴县瓦塘镇中心卫生院裴家川口分院","address": ""},
    {"value": "沁水县土沃乡中心卫生院","address": ""},
    {"value": "太原牛丽口腔门诊管理有限公司杏花岭万达口腔门诊部","address": ""},
    {"value": "运城市盐湖区新城区荟萃花园社区卫生服务站","address": ""},
    {"value": "霍州市医疗集团大张镇卫生院","address": ""},
    {"value": "大同冠华口腔门诊部","address": ""},
    {"value": "山阴县医疗集团玉井镇中心卫生院","address": ""},
    {"value": "吕梁市人民医院","address": ""},
    {"value": "大同肛泰肛肠医院","address": ""},
    {"value": "新华街新和锦城社区卫生服务站","address": ""},
    {"value": "太原市迎泽区青年路二社区卫生服务站","address": ""},
    {"value": "太原市迎泽区双塔寺街二社区","address": ""},
    {"value": "卫生服务站","address": ""},
    {"value": "柳林县残联康复门诊部","address": ""},
    {"value": "静乐县赤泥洼乡中心卫生院","address": ""},
    {"value": "大同安泰中医医院","address": ""},
    {"value": "沁县牛寺乡卫生院","address": ""},
    {"value": "永济市医疗集团城北街道社区卫生服务中心","address": ""},
    {"value": "中共运城市委党校医疗室","address": ""},
    {"value": "临县医疗集团丛罗峪镇卫生院","address": ""},
    {"value": "神池县长畛乡卫生院","address": ""},
    {"value": "长治市潞州区西街街道颐龙湾社区卫生服务站","address": ""},
    {"value": "洪洞县大槐树镇卫生院南王分院（洪洞县医疗集团大槐树镇卫生院南王分院）","address": ""},
    {"value": "原平市中医医院","address": ""},
    {"value": "临猗同德医院","address": ""},
    {"value": "太谷明医堂中医院","address": ""},
    {"value": "朔州爱目眼科医院","address": ""},
    {"value": "柳林县医疗集团庄上镇卫生院","address": ""},
    {"value": "云冈区平盛路安庆里社区卫生服务站","address": ""},
    {"value": "太原东华门医院","address": ""},
    {"value": "太原市小店区207社区卫生服务站","address": ""},
    {"value": "襄汾屈轩齿科医院有限公司","address": ""},
    {"value": "古县南垣乡卫生院","address": ""},
    {"value": "长治市潞州区东街街道东方社区卫生服务站","address": ""},
    {"value": "朔州市朔城区区直机关门诊部","address": ""},
    {"value": "孝义市康维门诊部","address": ""},
    {"value": "临猗双塔社区卫生服务站","address": ""},
    {"value": "平定县冠山镇东大街社区卫生服务站","address": ""},
    {"value": "运城正华医院","address": ""},
    {"value": "运城同心风湿病医院","address": ""},
    {"value": "临猗民杰颈肩腰腿痛医院","address": ""},
    {"value": "临猗运生骨伤专科医院","address": ""},
    {"value": "榆社县北寨乡卫生院","address": ""},
    {"value": "榆社县社城中心卫生院","address": ""},
    {"value": "临猗力英医院","address": ""},
    {"value": "榆社县讲堂乡卫生院","address": ""},
    {"value": "榆社县郝北中心卫生院","address": ""},
    {"value": "临猗康桥康复医院","address": ""},
    {"value": "榆社县云竹镇中心卫生院","address": ""},
    {"value": "榆社县人民医院","address": ""},
    {"value": "榆社县中医院","address": ""},
    {"value": "榆社县箕城镇卫生院","address": ""},
    {"value": "榆社县兰峪乡卫生院","address": ""},
    {"value": "左权县辽阳镇卫生院（左权县医疗集团辽阳镇卫生院）","address": ""},
    {"value": "左权县芹泉镇下庄卫生院（左权县医疗集团芹泉镇下庄卫生院）","address": ""},
    {"value": "左权县辽阳镇河南卫生院（左权县医疗集团辽阳镇河南卫生院）","address": ""},
    {"value": "左权县寒王乡卫生院（左权县医疗集团寒王乡卫生院）","address": ""},
    {"value": "万荣福祯医院","address": ""},
    {"value": "万荣鑫华德医院","address": ""},
    {"value": "万荣庄头骨科医院","address": ""},
    {"value": "左权县麻田镇中心卫生院（左权县医疗集团麻田镇中心卫生院）","address": ""},
    {"value": "万荣现代医院","address": ""},
    {"value": "左权县拐儿镇卫生院（左权县医疗集团拐儿镇卫生院）","address": ""},
    {"value": "左权县人民医院","address": ""},
    {"value": "左权县羊角乡卫生院（左权县医疗集团羊角乡卫生院）","address": ""},
    {"value": "左权县芹泉镇中心卫生院（左权县医疗集团芹泉镇中心卫生院）","address": ""},
    {"value": "万荣博仁骨科医院","address": ""},
    {"value": "左权县粟城乡卫生院（左权县医疗集团粟城乡卫生院）","address": ""},
    {"value": "和顺县医疗集团松烟镇卫生院","address": ""},
    {"value": "和顺县横岭镇卫生院","address": ""},
    {"value": "闻喜慈和疼痛专科医院","address": ""},
    {"value": "闻喜陈坚创伤专科医院","address": ""},
    {"value": "闻喜秋梅医院","address": ""},
    {"value": "和顺县平松乡卫生院瓦房分院","address": ""},
    {"value": "闻喜礼元脊柱病医院","address": ""},
    {"value": "和顺县义兴镇卫生院","address": ""},
    {"value": "和顺县义兴镇卫生院紫罗分院","address": ""},
    {"value": "和顺县中医院","address": ""},
    {"value": "和顺县牛川乡卫生院","address": ""},
    {"value": "闻喜正骨医院","address": ""},
    {"value": "和顺县李阳镇卫生院","address": ""},
    {"value": "和顺县喂马乡卫生院","address": ""},
    {"value": "昔阳县东冶头镇卫生院（昔阳县东冶头中心卫生院、昔阳县医疗集团东冶头镇卫生院）","address": ""},
    {"value": "昔阳县孔氏乡卫生院（昔阳县医疗集团孔氏乡卫生院）","address": ""},
    {"value": "昔阳县界都乡卫生院","address": ""},
    {"value": "昔阳县沾尚中心卫生院","address": ""},
    {"value": "昔阳县乐平镇卫生院(昔阳县城区社区卫生服务中心、昔阳县医疗集团乐平镇卫生院)","address": ""},
    {"value": "昔阳县赵壁中心卫生院","address": ""},
    {"value": "昔阳县皋落镇卫生院（昔阳县医疗集团皋落镇卫生院）","address": ""},
    {"value": "昔阳县医疗集团人民医院","address": ""},
    {"value": "昔阳县三都乡卫生院","address": ""},
    {"value": "稷山稷峰现代口腔医院","address": ""},
    {"value": "稷山县济世烧伤医院（稷山东北街济世烧伤专科门诊）","address": ""},
    {"value": "昔阳县闫庄乡卫生院（昔阳县医疗集团闫庄乡卫生院）","address": ""},
    {"value": "稷山正身医院","address": ""},
    {"value": "稷山痔瘘医院","address": ""},
    {"value": "稷山博华外科医院","address": ""},
    {"value": "稷山童康医院","address": ""},
    {"value": "新绛中城社区卫生服务站","address": ""},
    {"value": "新绛纺织厂医院","address": ""},
    {"value": "新绛俊安心脑血管病医院","address": ""},
    {"value": "新纺社区卫生服务站","address": ""},
    {"value": "新绛康养中西医医院","address": ""},
    {"value": "新绛史家崖骨科医院","address": ""},
    {"value": "新绛永安中医骨科医院","address": ""},
    {"value": "新绛汾河骨伤专科医院","address": ""},
    {"value": "绛县安森骨科医院","address": ""},
    {"value": "寿阳县宗艾镇中心卫生院（寿阳县宗艾镇妇幼保健计划生育服务站、寿阳县医疗集团宗艾镇中心卫生院）","address": ""},
    {"value": "寿阳县温家庄乡卫生院（寿阳县温家庄乡妇幼保健计划生育服务站、寿阳县医疗集团温家庄乡卫生院）","address": ""},
    {"value": "寿阳县平舒乡卫生院（寿阳县平舒乡妇幼保健计划生育服务站、寿阳县医疗集团平舒乡卫生院）","address": ""},
    {"value": "寿阳县松塔镇中心卫生院（寿阳县松塔镇妇幼保健计划生育服务站、寿阳县医疗集团松塔镇卫生院）","address": ""},
    {"value": "寿阳县羊头崖乡卫生院白云分院（寿阳县医疗集团白云分院）","address": ""},
    {"value": "寿阳县尹灵芝镇卫生院落摩寺分院（寿阳县医疗集团落摩寺分院）","address": ""},
    {"value": "垣曲景孟中医骨伤病医院","address": ""},
    {"value": "寿阳县中医院","address": ""},
    {"value": "垣曲晶晶微美口腔门诊","address": ""},
    {"value": "寿阳县平头镇中心卫生院南庄分院（寿阳县医疗集团南庄分院）","address": ""},
    {"value": "大同恒奇精神病医院","address": ""},
    {"value": "寿阳县上湖乡卫生院（寿阳县上湖乡妇幼保健计划生育服务站、寿阳县医疗集团上湖乡卫生院）","address": ""},
    {"value": "垣曲祈康肛肠医院","address": ""},
    {"value": "垣曲欣欣妇产医院","address": ""},
    {"value": "垣曲保尔康综合医院","address": ""},
    {"value": "寿阳县尹灵芝镇卫生院（寿阳县尹灵芝镇妇幼保健计划生育服务站、寿阳县医疗集团尹灵芝镇卫生院）","address": ""},
    {"value": "垣曲齿科医院","address": ""},
    {"value": "永济市城西街道电机社区卫生服务中心","address": ""},
    {"value": "忻州市忻府区长航医院有限公司","address": ""},
    {"value": "柳林师斌口腔门诊部","address": ""},
    {"value": "运城市盐湖区新城区关铝花园社区卫生服务站","address": ""},
    {"value": "太原市小店区前进社区卫生服务站","address": ""},
    {"value": "兴县固贤乡卫生院（兴县医疗集团固贤乡卫生院）","address": ""},
    {"value": "新绛县三泉镇卫生院","address": ""},
    {"value": "云冈区平盛路安福里社区卫生服务站","address": ""},
    {"value": "迎春社区卫生服务站","address": ""},
    {"value": "山西高校新校区社区卫生服务中心","address": ""},
    {"value": "榆次区张庆乡卫生院（榆次区医疗集团张庆乡卫生院）","address": ""},
    {"value": "榆次区新建街道社区卫生服务中心","address": ""},
    {"value": "榆次区东阳镇中心卫生院（榆次区医疗集团东阳镇中心卫生院）","address": ""},
    {"value": "榆次区妇幼保健计划生育服务中心（榆次区妇幼保健院、榆次区医疗集团妇幼保健计划生育服务中心）","address": ""},
    {"value": "榆次区医疗集团（榆次区人民医院、榆次区医疗集团人民医院）","address": ""},
    {"value": "榆次区长凝镇卫生院（榆次区医疗集团长凝镇卫生院）","address": ""},
    {"value": "榆次区修文镇卫生院（榆次区医疗集团修文镇卫生院）","address": ""},
    {"value": "榆次区郭家堡乡卫生院（榆次区医疗集团郭家堡乡卫生院）","address": ""},
    {"value": "榆次区中医院","address": ""},
    {"value": "榆次区东赵乡卫生院（榆次区医疗集团东赵乡卫生院）","address": ""},
    {"value": "盂县阳光百姓药房有限公司汇丰堂门诊","address": ""},
    {"value": "霍州市医疗集团白龙镇卫生院","address": ""},
    {"value": "大同市平城区武定街道社区卫生服务中心","address": ""},
    {"value": "朔州市朔城区南城街道办事处上地嘉园社区卫生服务站","address": ""},
    {"value": "山西省太原市迎泽区双塔西街二社区卫生服务站","address": ""},
    {"value": "大同仁爱医院有限公司","address": ""},
    {"value": "河津和信口腔医院","address": ""},
    {"value": "平定岩会医院","address": ""},
    {"value": "吕梁康佳口腔医院有限公司","address": ""},
    {"value": "阳泉市城区南深沟社区卫生服务站","address": ""},
    {"value": "太原市晋源区晋源社区卫生服务站","address": ""},
    {"value": "东圣君悦湾社区卫生服务站","address": ""},
    {"value": "太原广誉远中医门诊部有限公司杏花岭三桥中医门诊部","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处世纪星城社区卫生服务站","address": ""},
    {"value": "新绛县眼病专科医院","address": ""},
    {"value": "平顺县苗庄中心卫生院","address": ""},
    {"value": "太原诺尔口腔门诊部有限公司","address": ""},
    {"value": "大同市肿瘤医院","address": ""},
    {"value": "大同市第二人民医院","address": ""},
    {"value": "绛县博森口腔门诊部","address": ""},
    {"value": "龙城新苑第一社区卫生服务站","address": ""},
    {"value": "太原齐雅口腔门诊部有限公司","address": ""},
    {"value": "垣曲县皋落乡卫生院","address": ""},
    {"value": "柳林城西中西医结合医院有限公司","address": ""},
    {"value": "尧都明博仕口腔门诊部","address": ""},
    {"value": "大同市云冈区平泉丽秀苑社区卫生服务站","address": ""},
    {"value": "大同西京中西医结合医院","address": ""},
    {"value": "定昌镇东苑社区卫生二所","address": ""},
    {"value": "晋城市城区东街街道办事处东关社区卫生服务站","address": ""},
    {"value": "万荣县南张乡卫生院","address": ""},
    {"value": "临汾市尧都区解放路办事处商会社区卫生服务站","address": ""},
    {"value": "泽州县富强医疗服务有限公司口腔门诊","address": ""},
    {"value": "沁县漳源镇中心卫生院","address": ""},
    {"value": "岚县大蛇头乡中心卫生院","address": ""},
    {"value": "河津堡子沟中医骨伤专科医院","address": ""},
    {"value": "长治市郊区故县街道办事处铁运公司社区卫生服务站","address": ""},
    {"value": "浑源县医疗集团沙圪坨镇卫生院杨庄分院","address": ""},
    {"value": "夏县埝掌镇卫生院（夏县医疗集团埝掌镇卫生院）","address": ""},
    {"value": "韩可维第二门诊","address": ""},
    {"value": "静乐同康健康体检门诊部","address": ""},
    {"value": "寿阳县南燕竹镇卫生院（南燕竹镇妇幼保健计划生育服务站、寿阳县医疗集团南燕竹镇卫生院）","address": ""},
    {"value": "寿阳县西洛镇卫生院（西洛镇妇幼保健计划生育服务站、寿阳县医疗集团西洛镇卫生院）","address": ""},
    {"value": "寿阳县马首乡卫生院（寿阳县马首乡妇幼保健计划生育服务站、寿阳县医疗集团马首乡卫生院）","address": ""},
    {"value": "寿阳县南燕竹镇卫生院太安驿分院（寿阳县医疗集团太安驿分院）","address": ""},
    {"value": "寿阳县羊头崖乡中心卫生院（寿阳县羊头崖乡妇幼保健计划生育服务站、寿阳县医疗集团羊头崖乡中心卫生院）","address": ""},
    {"value": "寿阳县平头镇中心卫生院（寿阳县平头镇妇幼保健计划生育服务站、寿阳县医疗集团平头镇中心卫生院）","address": ""},
    {"value": "寿阳县解愁乡卫生院（寿阳县解愁乡妇幼保健计划生育服务站、寿阳县医疗集团解愁乡卫生院）","address": ""},
    {"value": "寿阳县朝阳镇卫生院（寿阳县朝阳镇妇幼保健计划生育服务站、寿阳县医疗集团朝阳镇卫生院）","address": ""},
    {"value": "寿阳县西洛镇卫生院纂木分院（寿阳县医疗集团纂木分院）","address": ""},
    {"value": "寿阳县松塔镇中心卫生院长岭分院（寿阳县医疗集团长岭分院）","address": ""},
    {"value": "垣曲致仁中医颈肩腰腿痛专科医院","address": ""},
    {"value": "寿阳县朝阳镇卫生院七里河分院（丹凤城区管理委员会妇幼保健计划生育服务站、寿阳县医疗集团七里河分院）","address": ""},
    {"value": "夏县韩氏中医疮疡病医院","address": ""},
    {"value": "夏县段氏眼科医院","address": ""},
    {"value": "夏县颐臻中医院","address": ""},
    {"value": "夏县博雅医院","address": ""},
    {"value": "夏县云鹏中医肛肠医院","address": ""},
    {"value": "夏县惠仁脑血管专科医院","address": ""},
    {"value": "夏县夏禹中医骨伤医院","address": ""},
    {"value": "夏县吕氏中医医院","address": ""},
    {"value": "晋中市太谷区小白乡卫生院","address": ""},
    {"value": "晋中市太谷区北洸乡卫生院","address": ""},
    {"value": "平陆自安中医心脑血管病专科医院","address": ""},
    {"value": "平陆爱康医院","address": ""},
    {"value": "平陆卉然外科医院","address": ""},
    {"value": "祁县医疗集团（祁县人民医院）","address": ""},
    {"value": "祁县贾令镇卫生院（祁县医疗集团贾令镇卫生院）","address": ""},
    {"value": "祁县昭馀镇卫生院（祁县医疗集团昭馀镇卫生院）","address": ""},
    {"value": "祁县西六支乡卫生院（祁县医疗集团西六支乡卫生院）","address": ""},
    {"value": "祁县峪口乡卫生院（祁县医疗集团峪口乡卫生院）","address": ""},
    {"value": "芮城县中西医结合医院","address": ""},
    {"value": "祁县中医院","address": ""},
    {"value": "永济崇德医院","address": ""},
    {"value": "介休市城关乡卫生院（介休市医疗集团城关乡分院）","address": ""},
    {"value": "介休市东南社区卫生服务中心","address": ""},
    {"value": "介休市西南社区卫生服务中心","address": ""},
    {"value": "永济皮肤病专科医院","address": ""},
    {"value": "介休市张兰镇中心卫生院（介休市医疗集团张兰分院）","address": ""},
    {"value": "介休市三佳乡卫生院（介休市医疗集团三佳分院）","address": ""},
    {"value": "介休市义棠镇中心卫生院（介休市医疗集团义棠分院）","address": ""},
    {"value": "介休市义安镇中心卫生院（介休市医疗集团义安分院）","address": ""},
    {"value": "介休市宋古乡卫生院（介休市医疗集团宋古分院）","address": ""},
    {"value": "永济康宁医院","address": ""},
    {"value": "永济哮喘病专科医院","address": ""},
    {"value": "永济文正脑心肾专科医院","address": ""},
    {"value": "介休市中医医院","address": ""},
    {"value": "介休市人民医院","address": ""},
    {"value": "介休市北坛社区卫生服务中心（介休市医疗集团北坛街道社区卫生服务中心）","address": ""},
    {"value": "孝义大爱医院","address": ""},
    {"value": "吕梁市离石区凤山街道办中央公园社区卫生服务站","address": ""},
    {"value": "岢岚县阳坪中心卫生院","address": ""},
    {"value": "绛县狮瑞血液透析中心","address": ""},
    {"value": "和顺县义兴镇卫生院联坪分院","address": ""},
    {"value": "山西六合同泰中医药文化有限公司太原华济堂中医门诊部","address": ""},
    {"value": "太原市杏花岭区花南社区卫生服务站","address": ""},
    {"value": "宁武民福医院","address": ""},
    {"value": "大宁县医疗集团太德乡卫生院","address": ""},
    {"value": "打石腰乡卫生院","address": ""},
    {"value": "运城市盐湖区北城办事处锦绣社区卫生服务站","address": ""},
    {"value": "平遥县城东社区卫生服务中心","address": ""},
    {"value": "平遥县襄垣乡卫生院","address": ""},
    {"value": "平遥县卜宜乡卫生院","address": ""},
    {"value": "平遥县宁固中心卫生院","address": ""},
    {"value": "平遥县岳壁乡卫生院","address": ""},
    {"value": "平遥县人民医院","address": ""},
    {"value": "平遥县古城社区卫生服务中心","address": ""},
    {"value": "平遥县朱坑中心卫生院","address": ""},
    {"value": "平遥县中都乡卫生院","address": ""},
    {"value": "平遥县洪善中心卫生院","address": ""},
    {"value": "平遥县城西社区卫生服务中心","address": ""},
    {"value": "平遥县杜家庄乡卫生院","address": ""},
    {"value": "平遥县香乐乡卫生院","address": ""},
    {"value": "平遥县东泉中心卫生院","address": ""},
    {"value": "灵石县翠峰镇卫生院南焉分院","address": ""},
    {"value": "灵石县南关镇卫生院仁义分院","address": ""},
    {"value": "灵石县英武乡卫生院","address": ""},
    {"value": "灵石县梁家墕乡卫生院","address": ""},
    {"value": "灵石县夏门镇卫生院","address": ""},
    {"value": "灵石县马和乡卫生院","address": ""},
    {"value": "永济市开张镇卫生院（永济市医疗集团开张镇卫生院）","address": ""},
    {"value": "太原市万柏林区西铭社区卫生服务中心","address": ""},
    {"value": "单寨乡卫生院","address": ""},
    {"value": "灵石县中医院","address": ""},
    {"value": "太原市迎泽区恒大山水城社区卫生服务站","address": ""},
    {"value": "沙泉镇卫生院赵家沟分院","address": ""},
    {"value": "北大医疗潞安医院余吾医务室","address": ""},
    {"value": "大同京城皮肤病医院","address": ""},
    {"value": "代县峪口乡卫生院","address": ""},
    {"value": "晋中市妇幼保健院、晋中市儿童医院、晋中市妇产医院","address": ""},
    {"value": "太原海一阳口腔门诊部","address": ""},
    {"value": "汾阳市益康健康体检部","address": ""},
    {"value": "晋中榆次润芽口腔门诊部（有限公司）","address": ""},
    {"value": "长治市自愿戒毒医院","address": ""},
    {"value": "交城县福利医院","address": ""},
    {"value": "吕梁安定精神病医院","address": ""},
    {"value": "临汾市尧都区路东办事处新兴社区卫生服务站","address": ""},
    {"value": "太原金诺口腔门诊部","address": ""},
    {"value": "侯马建浍医院","address": ""},
    {"value": "河津市城区街道办汾雁社区卫生服站","address": ""},
    {"value": "太谷县水秀乡卫生院（太谷县医疗集团水秀乡卫生院）","address": ""},
    {"value": "北大医疗潞安医院王庄分院","address": ""},
    {"value": "长治惠峰口腔医疗有限公司口腔门诊部","address": ""},
    {"value": "阳泉市矿区医疗集团（阳泉市矿区医院）桥头社区卫生服务中心","address": ""},
    {"value": "太原西山医院","address": ""},
    {"value": "岢岚皮肤病常见病专科医院","address": ""},
    {"value": "临汾市尧都区中医院","address": ""},
    {"value": "阳泉市城区上站社区卫生服务中心","address": ""},
    {"value": "晋城市城区西街街道办事处秀水苑社区卫生服务站","address": ""},
    {"value": "阳城县妇幼保健计划生育服务中心","address": ""},
    {"value": "山西晋德外科医院","address": ""},
    {"value": "清徐县东于镇中心卫生院","address": ""},
    {"value": "原平市原平口腔医院","address": ""},
    {"value": "繁峙县大营镇卫生院（繁峙县医疗集团大营镇卫生院）","address": ""},
    {"value": "大同市新荣区堡子湾乡卫生院","address": ""},
    {"value": "长治市潞州区紫金街道紫苑社区卫生服务站","address": ""},
    {"value": "长治太行医院","address": ""},
    {"value": "武乡县分水岭卫生院","address": ""},
    {"value": "光社社区卫生服务中心","address": ""},
    {"value": "太原市尖草坪区兴华西社区卫生服务站","address": ""},
    {"value": "夏县胡张乡中心卫生院","address": ""},
    {"value": "大同新侨妇产医院有限公司","address": ""},
    {"value": "太原市尖草坪区翠馨苑社区卫生服务站","address": ""},
    {"value": "晋城市城区北石店中心卫生院","address": ""},
    {"value": "河津口腔医院","address": ""},
    {"value": "河津市北午芹医院","address": ""},
    {"value": "河津圣济医院","address": ""},
    {"value": "河津市脑神经病医院","address": ""},
    {"value": "河津康轩康复医院","address": ""},
    {"value": "河津城区办瑞康门诊部","address": ""},
    {"value": "河津市儿童医院","address": ""},
    {"value": "河津东方医院","address": ""},
    {"value": "河津复明眼科医院","address": ""},
    {"value": "忻州恒康医院","address": ""},
    {"value": "忻州荣民骨伤专科医院","address": ""},
    {"value": "忻州亿佳医院","address": ""},
    {"value": "原平市轩煤矿区吉祥社区卫生服务站","address": ""},
    {"value": "忻州爱尔眼科医院","address": ""},
    {"value": "原平安康医院","address": ""},
    {"value": "忻州显微手外科医院","address": ""},
    {"value": "代县仁心中西医结合医院","address": ""},
    {"value": "临汾职业技术学院第一附属医院（临汾市第二人民医院、临汾市骨科医院、临汾市职业病防治院）","address": ""},
    {"value": "五台新城医院","address": ""},
    {"value": "临汾市人民医院（山西医科大学附属临汾医院、山西医科大学第七临床医学院）","address": ""},
    {"value": "临汾市妇幼保健院（临汾市儿童医院）","address": ""},
    {"value": "临汾市振业中医心脑血管病专科医院","address": ""},
    {"value": "忻州繁峙白求恩血液净化门诊部","address": ""},
    {"value": "临汾市中心医院","address": ""},
    {"value": "忻州脑科医院","address": ""},
    {"value": "临汾市第三人民医院（临汾市传染病医院）","address": ""},
    {"value": "临汾市中医医院","address": ""},
    {"value": "忻州市忻府区虹侨医院","address": ""},
    {"value": "忻州安康眼科医院","address": ""},
    {"value": "临汾市荣军康复医院","address": ""},
    {"value": "忻州市忻府区和平街和平东社区卫生服务站","address": ""},
    {"value": "忻州市忻府区气象社区卫生服务站","address": ""},
    {"value": "忻州市忻府区玫瑰苑社区卫生服务站","address": ""},
    {"value": "忻府区新建路长南社区卫生服务站","address": ""},
    {"value": "忻州市忻府区张根康和医院","address": ""},
    {"value": "忻州市忻府区元遗山社区卫生服务站","address": ""},
    {"value": "忻州市忻府区长西社区卫生服务站","address": ""},
    {"value": "忻州市忻府区长江医院","address": ""},
    {"value": "忻州市忻府区康复护理医院","address": ""},
    {"value": "忻州五官专科医院","address": ""},
    {"value": "忻州心脑血管病医院","address": ""},
    {"value": "忻州烧伤医院","address": ""},
    {"value": "忻州骨科医院","address": ""},
    {"value": "忻州市忻府区和平街云中社区卫生服务站","address": ""},
    {"value": "忻州市忻府区长征街利东社区卫生服务站","address": ""},
    {"value": "忻州现代医院","address": ""},
    {"value": "忻州市忻府区安康口腔门诊部","address": ""},
    {"value": "忻州康宁医院","address": ""},
    {"value": "忻州市忻府区建南社区卫生服务站","address": ""},
    {"value": "忻州市忻府区金水苑社区卫生服务站","address": ""},
    {"value": "忻州市忻府区迎宾社区卫生服务站","address": ""},
    {"value": "忻州市忻府区忻源社区卫生服务站","address": ""},
    {"value": "忻州市忻府区君华苑社区卫生服务站","address": ""},
    {"value": "忻州曙光医院","address": ""},
    {"value": "曲沃县医疗集团高显镇中心卫生院","address": ""},
    {"value": "曲沃县医疗集团北董中心卫生院","address": ""},
    {"value": "定襄仁康门诊部","address": ""},
    {"value": "曲沃县医疗集团高显镇中心卫生院安居分院","address": ""},
    {"value": "曲沃县医疗集团乐昌镇卫生院","address": ""},
    {"value": "定襄中和小山医院","address": ""},
    {"value": "定襄城区医院","address": ""},
    {"value": "定襄利民医院","address": ""},
    {"value": "定襄世纪口腔门诊部","address": ""},
    {"value": "定襄康宏医院","address": ""},
    {"value": "曲沃县中医医院","address": ""},
    {"value": "定襄正大医院","address": ""},
    {"value": "定襄仁爱医院","address": ""},
    {"value": "襄汾县新城镇卫生院","address": ""},
    {"value": "临县医疗集团第八堡乡卫生院","address": ""},
    {"value": "阳泉市宏康医院","address": ""},
    {"value": "沁县南泉乡卫生院","address": ""},
    {"value": "山西省阳泉第二监狱医院","address": ""},
    {"value": "山西阳泉固兴医院","address": ""},
    {"value": "中阳县医疗集团下枣林乡卫生院吴家峁分院","address": ""},
    {"value": "运城市盐湖区北城办事处圣惠北路社区卫生服务站","address": ""},
    {"value": "曲沃县医疗集团杨谈乡卫生院","address": ""},
    {"value": "偏关县医疗集团（新关镇卫生院）","address": ""},
    {"value": "运城黄河中医肝胃病专科医院","address": ""},
    {"value": "临汾市尧都区西街办事处社区卫生服务中心（临汾市尧都区医疗集团西街办事处社区卫生服务中心）","address": ""},
    {"value": "临汾市尧都区贺家庄乡卫生院（临汾市尧都区医疗集团贺家庄卫生院）","address": ""},
    {"value": "临汾市尧都区眼科医院","address": ""},
    {"value": "临汾市尧都区贾得乡卫生院（临汾市尧都区医疗集团贾得乡卫生院）","address": ""},
    {"value": "长治医学院附属和济医院","address": ""},
    {"value": "阳城县中医院","address": ""},
    {"value": "兴县蔚汾镇卫生院肖家洼分院","address": ""},
    {"value": "榆社县河峪乡卫生院","address": ""},
    {"value": "阳城县凤城镇下李丘社区卫生服务站","address": ""},
    {"value": "襄垣县医疗集团下良镇卫生院","address": ""},
    {"value": "古交市常安乡中心卫生院（古交市医疗集团常安乡中心卫生院）","address": ""},
    {"value": "武乡县丰州中心卫生院","address": ""},
    {"value": "北大医疗潞安医院古城医务所","address": ""},
    {"value": "晋城健齿口腔医院门诊","address": ""},
    {"value": "平定县冠山镇南关街社区卫生服务站","address": ""},
    {"value": "蒋坊乡卫生院","address": ""},
    {"value": "闻喜华神精神病医院","address": ""},
    {"value": "山西佳仁堂中医馆有限公司","address": ""},
    {"value": "临猗县东张镇中心卫生院","address": ""},
    {"value": "太原市迎泽区迎宾社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区五龙湾社区卫生服务站","address": ""},
    {"value": "大同市第六人民医院","address": ""},
    {"value": "中国人民解放军联勤保障部队第九八五医院","address": ""},
    {"value": "天镇县医疗集团","address": ""},
    {"value": "太原市迎泽区善爱口腔门诊部","address": ""},
    {"value": "代县华时康宁医院有限公司","address": ""},
    {"value": "五台县石咀乡中心卫生院","address": ""},
    {"value": "太原星辰口腔有限公司万柏林漪汾街口腔门诊部","address": ""},
    {"value": "怀仁市医疗集团鹅毛口社区卫生服务站","address": ""},
    {"value": "医疗集团洪井乡卫生院","address": ""},
    {"value": "黎城县洪井乡卫生院","address": ""},
    {"value": "阳城县凤城镇荪庄社区卫生服务站","address": ""},
    {"value": "五台县第二人民医院东冶镇卫生院","address": ""},
    {"value": "原平市长梁沟镇卫生院神山堡社区卫生服务站","address": ""},
    {"value": "中阳县安康医院有限责任公司","address": ""},
    {"value": "山西佳诚口腔门诊管理有限公司小店学府街口腔门诊部","address": ""},
    {"value": "临县医疗集团克虎镇卫生院","address": ""},
    {"value": "芮城仁康医院","address": ""},
    {"value": "云冈区西花园社区卫生服务中心","address": ""},
    {"value": "稷山段氏眼科医院","address": ""},
    {"value": "向阳里齿欣社区卫生服务站","address": ""},
    {"value": "绛县东城区第二社区卫生服务站","address": ""},
    {"value": "原平市王家庄乡卫生院","address": ""},
    {"value": "太原市迎泽区老军营小区第一社区卫生服务站","address": ""},
    {"value": "定襄张俊生口腔门诊部","address": ""},
    {"value": "翼城县隆化中心卫生院浇底分院（翼城县医疗集团隆化中心卫生院浇底分院）","address": ""},
    {"value": "翼城县南梁中心卫生院（翼城县医疗集团南梁中心卫生院、南梁镇妇幼保健计划生育服务站）","address": ""},
    {"value": "翼城县中卫乡卫生院（翼城县医疗集团中卫乡卫生院、中卫乡妇幼保健计划生育服务站）","address": ""},
    {"value": "翼城县唐兴镇卫生院（翼城县医疗集团唐兴镇卫生院、唐兴镇妇幼保健计划生育服务站）","address": ""},
    {"value": "翼城县南唐乡卫生院（翼城县医疗集团南唐乡卫生院、翼城县南唐乡妇幼保健计划生育服务站）","address": ""},
    {"value": "翼城县王庄镇卫生院辛安分院（翼城县医疗集团王庄镇卫生院辛安分院）","address": ""},
    {"value": "翼城县南梁中心卫生院武池分院（翼城县医疗集团南梁中心卫生院武池分院）","address": ""},
    {"value": "翼城县王庄镇卫生院（翼城县医疗集团王庄镇卫生院、王庄镇妇幼保健计划生育服务站）","address": ""},
    {"value": "翼城县隆化中心卫生院北捍分院","address": ""},
    {"value": "翼城县西阎中心卫生院（翼城县医疗集团西阎中心卫生院、西阎镇妇幼保健计划生育服务站）","address": ""},
    {"value": "翼城县中卫乡卫生院甘泉分院（翼城县医疗集团中卫乡卫生院甘泉分院）","address": ""},
    {"value": "翼城县医疗集团（翼城县人民医院、翼城县医疗集团人民医院）","address": ""},
    {"value": "翼城县中医医院","address": ""},
    {"value": "襄汾县医疗集团（襄汾县人民医院、襄汾县医疗集团人民医院）","address": ""},
    {"value": "代县和平医院","address": ""},
    {"value": "代县平泰中医院","address": ""},
    {"value": "襄汾县中医医院","address": ""},
    {"value": "襄汾县赵曲精神病医院","address": ""},
    {"value": "繁峙县德慧缘中医门诊部","address": ""},
    {"value": "繁峙现代医院","address": ""},
    {"value": "洪洞县中医医院（洪洞县医疗集团中医医院）","address": ""},
    {"value": "宁武县惠民医院","address": ""},
    {"value": "洪洞县明姜镇中心卫生院圣王分院（洪洞县医疗集团明姜镇中心卫生院圣王分院）","address": ""},
    {"value": "洪洞县赵城镇中心卫生院南沟分院（洪洞县医疗集团赵城镇中心卫生院南沟分院）","address": ""},
    {"value": "宁武县广济医院","address": ""},
    {"value": "洪洞县龙马乡中心卫生院（洪洞县医疗集团龙马乡中心卫生院）","address": ""},
    {"value": "洪洞县淹底乡卫生院（洪洞县医疗集团淹底乡卫生院）","address": ""},
    {"value": "洪洞县万安镇中心卫生院左家沟分院（洪洞县医疗集团万安镇中心卫生院左家沟分院）","address": ""},
    {"value": "洪洞县曲亭镇中心卫生院古罗分院（洪洞县医疗集团曲亭镇中心卫生院古罗分院）","address": ""},
    {"value": "洪洞县甘亭镇卫生院（洪洞县医疗集团甘亭镇卫生院）","address": ""},
    {"value": "宁武康宁医院","address": ""},
    {"value": "洪洞县大槐树镇卫生院（洪洞县医疗集团大槐树镇卫生院）","address": ""},
    {"value": "洪洞县淹底乡卫生院孔峪分院（洪洞县医疗集团淹底乡卫生院孔峪分院）","address": ""},
    {"value": "宁武县利民医院","address": ""},
    {"value": "洪洞县苏堡镇卫生院（洪洞县医疗集团苏堡镇卫生院）","address": ""},
    {"value": "洪洞县明姜镇中心卫生院（洪洞县医疗集团明姜镇中心卫生院）","address": ""},
    {"value": "洪洞县广胜寺镇中心卫生院（洪洞县医疗集团广胜寺镇中心卫生院）","address": ""},
    {"value": "洪洞县山目乡卫生院山头分院（洪洞县医疗集团山目乡卫生院山头分院）","address": ""},
    {"value": "洪洞县万安镇中心卫生院双昌分院（洪洞县医疗集团万安镇中心卫生院双昌分院）","address": ""},
    {"value": "洪洞县赵城镇中心卫生院（洪洞县医疗集团赵城镇中心卫生院）","address": ""},
    {"value": "洪洞县大槐树镇卫生院冯张分院（洪洞县医疗集团大槐树镇卫生院冯张分院）","address": ""},
    {"value": "宁武仁爱医院","address": ""},
    {"value": "静乐县惠康门诊","address": ""},
    {"value": "古县医疗集团（古县人民医院）","address": ""},
    {"value": "古县三合镇卫生院","address": ""},
    {"value": "古县岳阳镇卫生院","address": ""},
    {"value": "古县北平镇卫生院","address": ""},
    {"value": "古县旧县镇卫生院","address": ""},
    {"value": "古县古阳镇卫生院","address": ""},
    {"value": "五寨县惠民医院","address": ""},
    {"value": "浮山县北王镇中心卫生院（浮山县医疗集团北王镇中心卫生院）","address": ""},
    {"value": "浮山县张庄镇卫生院（浮山县医疗集团张庄镇卫生院）","address": ""},
    {"value": "浮山县东张乡卫生院（浮山县医疗集团东张乡卫生院）","address": ""},
    {"value": "浮山县医疗集团（浮山县医疗集团人民医院","address": ""},
    {"value": "浮山县人民医院）","address": ""},
    {"value": "浮山县张庄镇米家垣卫生服务站（浮山县医疗集团张庄镇米家垣卫生服务站）","address": ""},
    {"value": "吉县医疗集团壶口镇卫生院(吉县壶口镇卫生院、吉县壶口管理局中心卫生院、吉县壶口镇中心卫生院)","address": ""},
    {"value": "吉县医疗集团文城乡卫生院（吉县文城乡卫生院、吉县文城乡中心卫生院）","address": ""},
    {"value": "吉县医疗集团车城乡卫生院（吉县车城乡卫生院）","address": ""},
    {"value": "吉县东城乡卫生院（吉县医疗集团东城乡卫生院）","address": ""},
    {"value": "吉县中医医院","address": ""},
    {"value": "吉县屯里镇卫生院（吉县医疗集团屯里镇卫生院）","address": ""},
    {"value": "乡宁县管头镇卫生院（乡宁县医疗集团管头卫生院）","address": ""},
    {"value": "乡宁县尉庄乡卫生院吉家原分院（乡宁县医疗集团尉庄乡卫生院吉家原分院）","address": ""},
    {"value": "乡宁县医疗集团枣岭乡卫生院","address": ""},
    {"value": "乡宁县尉庄乡卫生院（乡宁县医疗集团尉庄乡卫生院）","address": ""},
    {"value": "乡宁县枣岭乡卫生院谭坪分院（乡宁县医疗集团枣岭乡卫生院谭坪分院）","address": ""},
    {"value": "乡宁县光华镇中心卫生院（乡宁县医疗集团光华镇中心卫生院）","address": ""},
    {"value": "乡宁县昌宁镇卫生院张马分院（乡宁县医疗集团昌宁镇卫生院张马分院）","address": ""},
    {"value": "保德德馨医院","address": ""},
    {"value": "乡宁县双鹤乡卫生院双凤淹分院（乡宁县医疗集团双鹤乡卫生院双凤淹分院）","address": ""},
    {"value": "乡宁县医疗集团西交口乡卫生院","address": ""},
    {"value": "乡宁县双鹤乡卫生院（乡宁县医疗集团双鹤乡卫生院）","address": ""},
    {"value": "乡宁县医疗集团（乡宁县人民医院）","address": ""},
    {"value": "乡宁县医疗集团西坡镇中心卫生院（乡宁县西坡镇中心卫生院）","address": ""},
    {"value": "蒲县红道乡卫生院（蒲县医疗集团红道乡卫生院）","address": ""},
    {"value": "蒲县医疗集团（蒲县人民医院、蒲县医疗集团人民医院）","address": ""},
    {"value": "蒲县红道乡卫生院西坪垣分院（蒲县医疗集团红道乡卫生院西坪垣分院）","address": ""},
    {"value": "蒲县克城镇卫生院（蒲县医疗集团克城镇卫生院）","address": ""},
    {"value": "蒲县中医医院","address": ""},
    {"value": "蒲县蒲城镇卫生院（蒲县医疗集团蒲城镇卫生院）","address": ""},
    {"value": "蒲县薛关镇卫生院（蒲县医疗集团薛关镇卫生院）","address": ""},
    {"value": "蒲县乔家湾乡卫生院（蒲县医疗集团乔家湾乡卫生院）","address": ""},
    {"value": "蒲县山中乡卫生院（蒲县医疗集团山中乡卫生院）","address": ""},
    {"value": "运城市盐湖区王范乡卫生院（运城市盐湖区医疗集团王范乡卫生院）","address": ""},
    {"value": "太原麦芽口腔门诊有限责任公司万柏林下元口腔门诊部","address": ""},
    {"value": "阳泉庆鸿口腔医疗有限公司庆鸿口腔门诊部","address": ""},
    {"value": "岚县上明乡卫生院","address": ""},
    {"value": "怀仁市医疗集团何家堡卫生院","address": ""},
    {"value": "左云县医疗集团张家场乡卫生院","address": ""},
    {"value": "朔州平鲁敬德医院","address": ""},
    {"value": "平遥仁安精神康复医院","address": ""},
    {"value": "五台县妇幼保健服务中心","address": ""},
    {"value": "太原恒伦口腔新民门诊部","address": ""},
    {"value": "柳林薛家湾明康中医院","address": ""},
    {"value": "朔州济仁医院","address": ""},
    {"value": "大宁县医疗集团（大宁县人民医院）","address": ""},
    {"value": "永和县交口乡卫生院（永和县医疗集团交口乡卫生院）","address": ""},
    {"value": "永和县坡头乡卫生院（永和县医疗集团坡头乡卫生院）","address": ""},
    {"value": "永和县阁底乡中心卫生院（永和县医疗集团阁底乡中心卫生院）","address": ""},
    {"value": "永和县芝河镇卫生院（永和县医疗集团芝河镇卫生院）","address": ""},
    {"value": "隰县中医医院","address": ""},
    {"value": "长治市上党区妇幼保健计划生育服务中心","address": ""},
    {"value": "大同市云州区西坪诺尔泰医院有限公司","address": ""},
    {"value": "太原市杏花岭区教场巷社区卫生服务站","address": ""},
    {"value": "怀仁新时代棚户区医院","address": ""},
    {"value": "原平市云水镇卫生院","address": ""},
    {"value": "山西省人民医院","address": ""},
    {"value": "太原品贝口腔门诊部","address": ""},
    {"value": "太原市迎泽区棉花巷社区卫生服务中心","address": ""},
    {"value": "长治潞州仁禾医院","address": ""},
    {"value": "运城市盐湖区中城办事处站前社区卫生服务站","address": ""},
    {"value": "汾阳市文峰街道社区卫生服务中心","address": ""},
    {"value": "太原市尖草坪区上兰社区卫生服务中心","address": ""},
    {"value": "原平市子干乡卫生院","address": ""},
    {"value": "沁县人民医院","address": ""},
    {"value": "稷山恒德医院","address": ""},
    {"value": "朔州市朔城区北城街道办事处马邑路社区卫生服务站","address": ""},
    {"value": "灵石县妇幼保健计划生育服务中心","address": ""},
    {"value": "代县医疗集团胡峪卫生院","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处滨河社区卫生服务站","address": ""},
    {"value": "太原市小店区教院社区卫生服务站","address": ""},
    {"value": "山西财经大学校医院","address": ""},
    {"value": "阳高县王官屯镇卫生院朱家窑头分院","address": ""},
    {"value": "太原市迎泽区校尉营社区卫生服务站","address": ""},
    {"value": "太原恒伦并东口腔门诊部有限公司","address": ""},
    {"value": "大同康友精神病医院","address": ""},
    {"value": "长治市潞州区西街街道正西社区卫生服务站","address": ""},
    {"value": "永济市肝胆胃病专科医院","address": ""},
    {"value": "孝义市医疗集团驿马分院城区服务站","address": ""},
    {"value": "宁武阳方口顺康医院","address": ""},
    {"value": "岚县界河口镇卫生院","address": ""},
    {"value": "阳泉煤业（集团）有限责任公司总医院亚美医院","address": ""},
    {"value": "石楼济仁医院","address": ""},
    {"value": "吕梁市离石区滨河街道办城东社区卫生服务站","address": ""},
    {"value": "吕梁安康精神病医院","address": ""},
    {"value": "昔阳四通一轩综合医院有限公司","address": ""},
    {"value": "盂县下社乡卫生院庄里分院","address": ""},
    {"value": "朔州市朔城区妇幼保健院","address": ""},
    {"value": "云冈区燕子山社区卫生服务中心","address": ""},
    {"value": "山西汾西矿业（集团）有限责任公司柳湾煤矿综合门诊部","address": ""},
    {"value": "灵石县静升镇卫生院","address": ""},
    {"value": "晋中榆次恩睦口腔门诊部（有限公司）","address": ""},
    {"value": "长治医学院附属淮海医院","address": ""},
    {"value": "太原市晋源区义井社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区机床东社区卫生站","address": ""},
    {"value": "古交市晋民医院","address": ""},
    {"value": "阳泉市郊区妇幼保健计划生育服务中心","address": ""},
    {"value": "永和县医疗集团","address": ""},
    {"value": "祁县均达堂血液透析中心","address": ""},
    {"value": "原平市南城康宁社区卫生服务站","address": ""},
    {"value": "侯马市痔瘘专科医院","address": ""},
    {"value": "原平崞阳康泰医院","address": ""},
    {"value": "侯马市五官科医院","address": ""},
    {"value": "原平市原平李字齿科门诊部","address": ""},
    {"value": "原平同泽医院","address": ""},
    {"value": "侯马安定医院","address": ""},
    {"value": "临汾皮肤病医院","address": ""},
    {"value": "运城市第二医院","address": ""},
    {"value": "洪洞妇产医院","address": ""},
    {"value": "临汾经济技术开发区新立医院","address": ""},
    {"value": "临汾永旺脑病医院","address": ""},
    {"value": "洪洞洪德眼科医院","address": ""},
    {"value": "运城市妇幼保健计划生育服务中心（运城市妇幼保健院）","address": ""},
    {"value": "运城市中医医院","address": ""},
    {"value": "五四一总医院（中信运城医院）","address": ""},
    {"value": "运城市第三医院（运城市血液病研究所、运城市老年病医院）","address": ""},
    {"value": "临汾中研中医院","address": ""},
    {"value": "运城护理职业学院附属医院","address": ""},
    {"value": "临汾荣健外科医院","address": ""},
    {"value": "临汾胜杰齿科医院","address": ""},
    {"value": "临汾仁和中西医结合医院","address": ""},
    {"value": "临汾莉轶综合医院","address": ""},
    {"value": "临汾誉圣结石病专科医院","address": ""},
    {"value": "临汾癫痫病医院","address": ""},
    {"value": "临汾显微外科医院","address": ""},
    {"value": "临汾民仁眼科医院","address": ""},
    {"value": "临汾今正元万圣医院有限责任公司","address": ""},
    {"value": "临汾和泽佳源中西医结合医院","address": ""},
    {"value": "临汾建峰齿科医院","address": ""},
    {"value": "临汾泽华外科医院","address": ""},
    {"value": "临汾爱尔眼科医院","address": ""},
    {"value": "临汾尧民外科医院","address": ""},
    {"value": "临汾柴氏中医康复医院有限公司","address": ""},
    {"value": "临汾美年健康管理咨询有限公司综合门诊部","address": ""},
    {"value": "临汾先平妇产医院","address": ""},
    {"value": "忻府区三交镇中心卫生院","address": ""},
    {"value": "五寨县东秀庄乡中心卫生院","address": ""},
    {"value": "临汾市尧都区路东办事处临钢一区社区卫生服务站","address": ""},
    {"value": "大同市平城区金冠口腔门诊部","address": ""},
    {"value": "应县医疗集团南河种镇中心卫生院、应县南河种镇中心卫生院","address": ""},
    {"value": "汾西县佃坪乡卫生院（汾西县医疗集团佃坪乡卫生院）","address": ""},
    {"value": "汾西县永安镇卫生院","address": ""},
    {"value": "汾西县僧念镇卫生院（汾西县医疗集团僧念镇卫生院）","address": ""},
    {"value": "山西省汾西县和平中心卫生院（汾西县医疗集团和平中心卫生院）","address": ""},
    {"value": "霍州市医疗集团（霍州市人民医院）","address": ""},
    {"value": "运城市盐湖区西城办事处社区卫生服务中心（运城市盐湖区医疗集团西城办事处社区卫生服务中心、运城市盐湖区九针医院）","address": ""},
    {"value": "运城市盐湖区北城办事处社区卫生服务中心（运城市盐湖区医疗集团北城办事处社区卫生服务中心）","address": ""},
    {"value": "运城市盐湖区人民医院","address": ""},
    {"value": "运城市盐湖区南城办事处社区卫生服务中心（运城市盐湖区医疗集团南城办事处社区卫生服务中心）","address": ""},
    {"value": "运城市盐湖区安邑办事处社区卫生服务中心（运城市盐湖区医疗集团安邑办事处社区卫生服务中心）","address": ""},
    {"value": "运城市盐湖区泓芝驿镇卫生院（运城市盐湖区医疗集团泓芝驿镇卫生院）","address": ""},
    {"value": "运城市盐湖区金井乡卫生院（运城市盐湖区医疗集团金井乡卫生院）","address": ""},
    {"value": "运城市盐湖区解州镇卫生院（运城市盐湖区医疗集团解州镇卫生院）","address": ""},
    {"value": "运城市盐湖区席张乡卫生院（运城市盐湖区医疗集团席张乡卫生院）","address": ""},
    {"value": "运城市盐湖区姚孟办事处社区卫生服务中心（运城市盐湖区医疗集团姚孟办事处社区卫生服务中心）","address": ""},
    {"value": "运城市盐湖区上郭乡卫生院（运城市盐湖区医疗集团上郭乡卫生院）","address": ""},
    {"value": "运城市盐湖区中城办事处社区卫生服务中心（运城市盐湖区医疗集团中城办事处社区卫生服务中心、运城市盐湖区第二人民医院）","address": ""},
    {"value": "运城市盐湖区东郭镇卫生院（运城市盐湖区医疗集团东郭镇卫生院）","address": ""},
    {"value": "运城市盐湖区解州镇卫生院车盘分院（运城市盐湖区医疗集团解州镇卫生院车盘分院）","address": ""},
    {"value": "运城市盐湖区龙居镇卫生院（运城市盐湖区医疗集团龙居镇卫生院）","address": ""},
    {"value": "运城市盐湖区姚孟办事处皮革城社区卫生服务站","address": ""},
    {"value": "太原王维权门诊部","address": ""},
    {"value": "长治市潞州区常青街道社区卫生服务中心长治市潞州区医疗集团常青街道社区卫生服务中心","address": ""},
    {"value": "垣曲县长直乡卫生院","address": ""},
    {"value": "垣曲县妇幼保健院","address": ""},
    {"value": "太原市万柏林区重院社区卫生服务站","address": ""},
    {"value": "中阳县医疗集团车鸣峪乡卫生院","address": ""},
    {"value": "晋城诺贝口腔门诊有限公司","address": ""},
    {"value": "岢岚县中医院","address": ""},
    {"value": "山西省永济虞乡强制隔离戒毒所医院","address": ""},
    {"value": "石楼县医疗集团小蒜镇中心卫生院","address": ""},
    {"value": "灵石县王禹乡卫生院","address": ""},
    {"value": "太原市尖草坪区102社区卫生服务站","address": ""},
    {"value": "隰县医疗集团龙泉镇卫生院","address": ""},
    {"value": "蒲县云良齿科","address": ""},
    {"value": "平城区新华街山橡社区卫生服务站","address": ""},
    {"value": "太原市尖草坪区康馨堂门诊","address": ""},
    {"value": "太原市杏花岭区中涧河镇卫生院","address": ""},
    {"value": "朔州市朔城区北城街道办事处府西社区卫生服务站","address": ""},
    {"value": "长治市潞城区医疗集团成家川街道办事处社区卫生服务中心","address": ""},
    {"value": "石楼县灵泉镇卫生院（石楼县医疗集团灵泉镇卫生院）","address": ""},
    {"value": "大同御和中医医院","address": ""},
    {"value": "太原优贝亲贤口腔门诊部","address": ""},
    {"value": "芮城中山医院","address": ""},
    {"value": "阳高县大白登镇卫生院","address": ""},
    {"value": "太原市万柏林区朝北社区卫生服务站","address": ""},
    {"value": "忻州市忻府区口腔医院","address": ""},
    {"value": "侯马胡氏骨伤科医院","address": ""},
    {"value": "太原康尔雅口腔门诊有限责任公司杏花岭迎春街口腔门诊部","address": ""},
    {"value": "太原翼心专科门诊部","address": ""},
    {"value": "中国人民解放军63710部队医院","address": ""},
    {"value": "太原乐享之家护理院","address": ""},
    {"value": "山西汾西矿业集团有限公司高阳煤矿职工医院","address": ""},
    {"value": "太原市晋源区晋祠社区卫生服务站","address": ""},
    {"value": "古县妇幼保健计划生育服务中心","address": ""},
    {"value": "永济市人民医院","address": ""},
    {"value": "临汾市尧都区医疗集团河底乡卫生院","address": ""},
    {"value": "盐湖百斯特口腔门诊部","address": ""},
    {"value": "太原德熠挚诚口腔服务有限公司万柏林远大口腔门诊部","address": ""},
    {"value": "阳泉市矿区医疗集团（阳泉市矿区医院）蔡洼社区卫生服务中心","address": ""},
    {"value": "晋中榆次爱可口腔门诊部（有限公司）","address": ""},
    {"value": "乡宁县黎明医疗技术有限公司黎明三部口腔门诊部","address": ""},
    {"value": "平陆县南城社区卫生服务站","address": ""},
    {"value": "曲沃仁惠医院","address": ""},
    {"value": "河津市脑神经病医院翼城春雷分院","address": ""},
    {"value": "翼城新东方医院","address": ""},
    {"value": "沁水县郑庄镇中心卫生院张峰分院","address": ""},
    {"value": "翼城仁爱医院","address": ""},
    {"value": "翼城司一聪口腔门诊部","address": ""},
    {"value": "芮城县阳城镇卫生院杜庄分院（芮城县医疗集团阳城镇卫生院杜庄分院）","address": ""},
    {"value": "芮城县南磑镇卫生院（芮城县医疗集团南磑镇卫生院）","address": ""},
    {"value": "芮城县阳城镇卫生院（芮城县医疗集团阳城镇卫生院）","address": ""},
    {"value": "襄汾红德眼科医院","address": ""},
    {"value": "襄汾天和医院","address": ""},
    {"value": "襄汾汇康医院","address": ""},
    {"value": "襄汾北众骨科医院","address": ""},
    {"value": "襄汾温泉外科医院（个人独资）","address": ""},
    {"value": "襄汾中山脉管炎专科医院","address": ""},
    {"value": "芮城县人民医院","address": ""},
    {"value": "襄汾四季安康医院","address": ""},
    {"value": "芮城县康复医院","address": ""},
    {"value": "芮城县东垆乡卫生院（芮城县医疗集团东垆乡卫生院）","address": ""},
    {"value": "芮城县陌南镇卫生院（芮城县医疗集团陌南镇卫生院）","address": ""},
    {"value": "芮城县学张乡卫生院（芮城县医疗集团学张乡卫生院）","address": ""},
    {"value": "襄汾县赋荣精神病专科医院","address": ""},
    {"value": "襄汾良陌中医门诊部","address": ""},
    {"value": "芮城县古魏镇卫生院（芮城县医疗集团古魏镇卫生院）","address": ""},
    {"value": "临猗县北辛乡中心卫生院（临猗县医疗集团北辛乡中心卫生院）","address": ""},
    {"value": "临猗县猗氏镇卫生院（临猗县医疗集团猗氏镇卫生院）","address": ""},
    {"value": "临猗博爱医院","address": ""},
    {"value": "临猗县耽子镇卫生院（临猗县医疗集团耽子镇卫生院）","address": ""},
    {"value": "临猗县庙上乡中心卫生院（临猗县医疗集团庙上乡中心卫生院）","address": ""},
    {"value": "临猗县嵋阳镇中心卫生院（临猗县医疗集团嵋阳镇中心卫生院）","address": ""},
    {"value": "临猗县孙吉镇中心卫生院南赵分院（临猗县医疗集团孙吉镇中心卫生院南赵分院）","address": ""},
    {"value": "洪洞恒健医院","address": ""},
    {"value": "洪洞增光肛肠医院","address": ""},
    {"value": "洪洞乐德思口腔门诊部","address": ""},
    {"value": "洪洞盛来老年病专科医院","address": ""},
    {"value": "洪洞和济医院","address": ""},
    {"value": "临猗县孙吉镇中心卫生院（临猗县医疗集团孙吉镇中心卫生院）","address": ""},
    {"value": "洪洞占虎骨科医院","address": ""},
    {"value": "临猗县临晋镇卫生院","address": ""},
    {"value": "洪洞爱仁医院","address": ""},
    {"value": "洪洞晋峰医院","address": ""},
    {"value": "临猗县耽子镇卫生院卓里分院（临猗县医疗集团耽子镇卓里分院）","address": ""},
    {"value": "洪洞临谐医院","address": ""},
    {"value": "古县康庄街文亮口腔门诊部","address": ""},
    {"value": "万荣县解店镇卫生院","address": ""},
    {"value": "万荣县通化镇卫生院","address": ""},
    {"value": "万荣县荣河镇卫生院（万荣县医疗集团荣河镇卫生院;万荣县荣河镇妇幼保健计划生育服务站）万荣县第二人民医院","address": ""},
    {"value": "万荣县光华乡卫生院","address": ""},
    {"value": "万荣县汉薛镇卫生院","address": ""},
    {"value": "万荣县汉薛镇卫生院三文分院","address": ""},
    {"value": "万荣县人民医院","address": ""},
    {"value": "万荣县万泉乡卫生院","address": ""},
    {"value": "万荣县皇甫乡卫生院","address": ""},
    {"value": "万荣县裴庄镇卫生院","address": ""},
    {"value": "万荣县皇甫乡卫生院埝底分院","address": ""},
    {"value": "新绛县阳王中心卫生院（新绛县医疗集团阳王中心卫生院、新绛新绛县阳王镇妇幼保健计划生育服务站）","address": ""},
    {"value": "新绛县三泉镇卫生院南社分院（新绛县医疗集团三泉镇卫生院南社分院）","address": ""},
    {"value": "新绛县古交镇中心卫生院","address": ""},
    {"value": "新绛县横桥镇中心卫生院柳泉分院（新绛县医疗集团横桥镇中心卫生院柳泉分院）","address": ""},
    {"value": "新绛县中医院","address": ""},
    {"value": "新绛县糖尿病医院","address": ""},
    {"value": "新绛县龙兴镇卫生院（新绛县医疗集团龙兴镇卫生院、新绛县龙兴镇计划生育服务站）","address": ""},
    {"value": "稷山县妇幼保健计划生育服务中心（稷山县妇幼保健院）","address": ""},
    {"value": "稷山县精神病医院（运城市第六医院）","address": ""},
    {"value": "稷山县医疗集团稷峰镇卫生院","address": ""},
    {"value": "稷山县医疗集团西社镇卫生院","address": ""},
    {"value": "稷山县医疗集团化峪镇卫生院","address": ""},
    {"value": "稷山县医疗集团清河镇卫生院","address": ""},
    {"value": "稷山县太阳乡中心卫生院修善分院","address": ""},
    {"value": "稷山县稷峰镇卫生院下迪分院","address": ""},
    {"value": "闻喜县郭家庄镇中心卫生院（闻喜县医疗集团郭家庄镇中心卫生院）","address": ""},
    {"value": "乡宁宏康齿科医院","address": ""},
    {"value": "乡宁百姓医院","address": ""},
    {"value": "闻喜县裴社乡卫生院（闻喜县医疗集团裴社乡卫生院）","address": ""},
    {"value": "闻喜县桐城镇卫生院（闻喜县医疗集团桐城镇卫生院）","address": ""},
    {"value": "闻喜县石门乡卫生院（闻喜县医疗集团石门乡卫生院）","address": ""},
    {"value": "闻喜县人民医院","address": ""},
    {"value": "闻喜县东镇中心卫生院（闻喜县医疗集团东镇中心卫生院）","address": ""},
    {"value": "闻喜县后宫乡卫生院（闻喜县医疗集团后宫乡卫生院）","address": ""},
    {"value": "闻喜县阳隅镇卫生院神柏分院（闻喜县医疗集团阳隅镇卫生院神柏分院）","address": ""},
    {"value": "闻喜县畖底镇中心卫生院（闻喜县医疗集团畖底镇中心卫生院）","address": ""},
    {"value": "闻喜县礼元镇中心卫生院（闻喜县医疗集团礼元镇中心卫生院）","address": ""},
    {"value": "闻喜县阳隅乡卫生院（闻喜县医疗集团阳隅乡卫生院）","address": ""},
    {"value": "乡宁民安医院","address": ""},
    {"value": "乡宁黎明一部口腔门诊部","address": ""},
    {"value": "乡宁爱心医院","address": ""},
    {"value": "闻喜县中医院","address": ""},
    {"value": "夏县瑶峰镇卫生院（夏县医疗集团瑶峰镇卫生院）","address": ""},
    {"value": "夏县尉郭乡卫生院","address": ""},
    {"value": "夏县禹王镇卫生院","address": ""},
    {"value": "夏县中医医院","address": ""},
    {"value": "夏县庙前镇中心卫生院（夏县医疗集团庙前镇中心卫生院）","address": ""},
    {"value": "夏县祁家河乡中心卫生院（夏县医疗集团祁家河乡中心卫生院）","address": ""},
    {"value": "夏县骨伤科医院","address": ""},
    {"value": "绛县中医医院","address": ""},
    {"value": "绛县横水镇中心卫生院（绛县医疗集团横水镇中心卫生院）","address": ""},
    {"value": "绛县大交镇中心卫生院（绛县医疗集团大交镇中心卫生院）","address": ""},
    {"value": "绛县冷口乡卫生院（绛县医疗集团冷口乡卫生院）","address": ""},
    {"value": "绛县磨里镇卫生院（绛县医疗集团磨里镇卫生院）","address": ""},
    {"value": "绛县南樊镇中心卫生院（绛县医疗集团南樊镇中心卫生院）","address": ""},
    {"value": "绛县骨科医院","address": ""},
    {"value": "绛县古绛镇中心卫生院（绛县医疗集团古绛镇中心卫生院）","address": ""},
    {"value": "绛县人民医院","address": ""},
    {"value": "绛县妇幼保健计划生育服务中心","address": ""},
    {"value": "绛县眼科医院","address": ""},
    {"value": "平陆县圣人涧镇中心卫生院（平陆县优抚医院）（平陆县医疗集团圣人涧镇中心卫生院）","address": ""},
    {"value": "平陆县圣人涧镇中心卫生院晴岚分院（平陆县医疗集团圣人涧镇中心卫生院晴岚分院）","address": ""},
    {"value": "平陆县洪池镇卫生院（平陆县医疗集团洪池镇卫生院）","address": ""},
    {"value": "平陆县常乐镇中心卫生院（平陆县医疗集团常乐镇中心卫生院）","address": ""},
    {"value": "太原市迎泽区青年路三社区卫生服务站","address": ""},
    {"value": "太原恒伦平阳路口腔门诊部","address": ""},
    {"value": "古交市桃园街道办事处桃园社区卫生服务站","address": ""},
    {"value": "大同市康林中西医结合医院","address": ""},
    {"value": "运城市盐湖区大渠办事处社区卫生服务中心","address": ""},
    {"value": "沁源县中峪乡卫生院","address": ""},
    {"value": "原平市新原乡卫生院","address": ""},
    {"value": "太原市万柏林区虎胜街社区卫生服务站","address": ""},
    {"value": "怀仁市医疗集团吴家窑卫生院","address": ""},
    {"value": "大同市第五人民医院","address": ""},
    {"value": "煤峪口社区卫生服务中心","address": ""},
    {"value": "高平武承谋骨伤专科医院","address": ""},
    {"value": "忻州市忻府区秀容办事处社区卫生服务中心","address": ""},
    {"value": "翼城立清医院","address": ""},
    {"value": "闻喜牙病防治所","address": ""},
    {"value": "盂县妇女儿童医院","address": ""},
    {"value": "向阳里柳港园社区卫生服务站","address": ""},
    {"value": "山西江阳化工有限公司职工医院","address": ""},
    {"value": "太原恒雅兴口腔门诊部","address": ""},
    {"value": "忻州市恒伦口腔门诊","address": ""},
    {"value": "平泉北秀苑社区卫生服务站","address": ""},
    {"value": "太原新欣口腔门诊部","address": ""},
    {"value": "大同宏德综合门诊部","address": ""},
    {"value": "阳城县董封乡卫生院李圪塔分院（阳城县医疗集团董封乡卫生院李圪塔分院）","address": ""},
    {"value": "山西省永和县中医医院","address": ""},
    {"value": "吉县医疗集团屯里镇卫生院","address": ""},
    {"value": "应县医疗集团大临河乡中心卫生院北楼口分院","address": ""},
    {"value": "大同创新骨科医院","address": ""},
    {"value": "太原市小店区平阳路社区卫生服务中心","address": ""},
    {"value": "太原雅舒美健口腔门诊部有限公司","address": ""},
    {"value": "山西省长治市潞城区潞华办学府街社区卫生服务站","address": ""},
    {"value": "阳曲县凌井店乡卫生院（阳曲县医疗集团凌井店乡卫生院）","address": ""},
    {"value": "襄汾县汾城镇卫生院贾岗分院","address": ""},
    {"value": "阳城县朗朗盛悦口腔医疗有限公司南城口腔门诊部","address": ""},
    {"value": "繁峙县东山乡卫生院","address": ""},
    {"value": "晋中市榆次区锦康综合医院","address": ""},
    {"value": "灵石县两渡镇卫生院","address": ""},
    {"value": "洪洞洪赵骨科医院","address": ""},
    {"value": "忻州市忻府区新建路办事处社区卫生服务中心","address": ""},
    {"value": "壶关县医疗集团东柏林分院","address": ""},
    {"value": "太原市万柏林区御德苑社区卫生服务站","address": ""},
    {"value": "太原化肥厂职工医院","address": ""},
    {"value": "太原市杏花岭区享堂社区卫生服务站","address": ""},
    {"value": "大同康复医院有限公司","address": ""},
    {"value": "襄垣黄河医院","address": ""},
    {"value": "长治市潞州区太行东街街道和济社区卫生服务西站","address": ""},
    {"value": "榆次区庄子乡卫生院（榆次区医疗集团庄子乡卫生院）","address": ""},
    {"value": "文水县刘胡兰镇中心卫生院","address": ""},
    {"value": "朔州市荣军康宁医院","address": ""},
    {"value": "应县医疗集团金城镇卫生院","address": ""},
    {"value": "天镇康宁中医医院","address": ""},
    {"value": "繁峙县柏家庄乡卫生院","address": ""},
    {"value": "方山县人民医院","address": ""},
    {"value": "介休今晨门诊部","address": ""},
    {"value": "平陆县医疗集团三门镇卫生院坡底分院","address": ""},
    {"value": "平陆县中医医院","address": ""},
    {"value": "平陆县中医癫痫脑病医院","address": ""},
    {"value": "平陆县曹川镇中心卫生院（平陆县医疗集团曹川镇中心卫生院）","address": ""},
    {"value": "垣曲县人民医院（垣曲县医疗集团人民医院）","address": ""},
    {"value": "垣曲县华峰乡中心卫生院（垣曲县医疗集团华峰乡中心卫生院）","address": ""},
    {"value": "垣曲县中医医院（垣曲县肿瘤医院）","address": ""},
    {"value": "蒲县克城现代医院","address": ""},
    {"value": "垣曲县历山镇中心卫生院（垣曲县医疗集团历山镇中心卫生院）","address": ""},
    {"value": "垣曲县古城镇卫生院","address": ""},
    {"value": "垣曲县古城镇卫生院谭家分院（垣曲县医疗集团古城镇卫生院谭家分院）","address": ""},
    {"value": "垣曲县蒲掌乡中心卫生院（垣曲县医疗集团蒲掌乡中心卫生院）","address": ""},
    {"value": "垣曲县华峰乡中心卫生院陈堡分院（垣曲县医疗集团华峰乡中心卫生院陈堡分院）","address": ""},
    {"value": "侯马友爱综合门诊部","address": ""},
    {"value": "侯马慈济中西医结合医院","address": ""},
    {"value": "永济市城西街道社区卫生服务中心","address": ""},
    {"value": "侯马艾美奥视佳眼科医院有限公司","address": ""},
    {"value": "永济市栲栳镇卫生院（永济市医疗集团栲栳镇卫生院）","address": ""},
    {"value": "侯马安益生中医医院","address": ""},
    {"value": "永济市妇幼保健计划生育服务中心（永济市妇幼保健院）","address": ""},
    {"value": "永济市韩阳镇卫生院（永济市医疗集团韩阳镇卫生院）","address": ""},
    {"value": "河津市僧楼镇中心卫生院（河津市医疗集团僧楼镇中心卫生院）","address": ""},
    {"value": "霍州济仁堂医院","address": ""},
    {"value": "霍州健桥医院","address": ""},
    {"value": "河津市人民医院","address": ""},
    {"value": "霍州常氏仁德医院","address": ""},
    {"value": "霍州同德医院","address": ""},
    {"value": "霍州益草堂中医医院","address": ""},
    {"value": "霍州仁福堂医院","address": ""},
    {"value": "河津市僧楼镇卫生院张吴分院","address": ""},
    {"value": "霍州汾东人和医院","address": ""},
    {"value": "河津市下化乡卫生院（河津市医疗集团下化乡卫生院）","address": ""},
    {"value": "霍州市西街社区卫生服务站","address": ""},
    {"value": "河津市柴家镇中心卫生院（河津市医疗集团柴家镇中心卫生院）","address": ""},
    {"value": "河津市中医医院","address": ""},
    {"value": "河津市小梁乡卫生院（河津市医疗集团小梁乡卫生院）","address": ""},
    {"value": "河津市清涧社区卫生服务中心（河津市医疗集团清涧社区卫生服务中心）","address": ""},
    {"value": "霍州紫晟医院","address": ""},
    {"value": "吕梁市离石区滨河街道办学府社区卫生服务站","address": ""},
    {"value": "石楼惠民医院","address": ""},
    {"value": "吕梁市离石区滨河街道办交通路社区卫生服务站","address": ""},
    {"value": "兴县利民医院","address": ""},
    {"value": "吕梁市离石区莲花池街道办南关社区卫生服务站","address": ""},
    {"value": "吕梁市离石区莲花池街道办丽景社区卫生服务站","address": ""},
    {"value": "太原市万柏林区西兴苑社区卫生服务站","address": ""},
    {"value": "大同市城区广誉远中医门诊部","address": ""},
    {"value": "忻州市忻府区元遗山社区卫生服务站","address": ""},
    {"value": "龙港镇卫生院十字街门诊","address": ""},
    {"value": "神池县东湖乡卫生院","address": ""},
    {"value": "灵石县段纯镇卫生院","address": ""},
    {"value": "太原市小店区水总社区卫生服务站","address": ""},
    {"value": "太原安定医院","address": ""},
    {"value": "晋西机器工业集团有限责任公司医院","address": ""},
    {"value": "宁武县医疗集团涔山乡卫生院","address": ""},
    {"value": "太原惠康中西医结合医院有限公司杏花岭惠康门诊部","address": ""},
    {"value": "太原市小店区刘家堡乡卫生院","address": ""},
    {"value": "定襄县医疗集团河边镇中心卫生院","address": ""},
    {"value": "石楼县义牒镇中心卫生院","address": ""},
    {"value": "原平市西镇乡卫生院","address": ""},
    {"value": "古县中医医院","address": ""},
    {"value": "绛县医疗集团安峪镇卫生院","address": ""},
    {"value": "河曲县医疗集团前川乡卫生院","address": ""},
    {"value": "长治市潞州区堠北庄镇卫生院","address": ""},
    {"value": "介休达康门诊部","address": ""},
    {"value": "襄汾县陶寺乡卫生院","address": ""},
    {"value": "平遥县岳壁乡卫生院梁坡底卫生站","address": ""},
    {"value": "朔州现代医院","address": ""},
    {"value": "长治市英中医院","address": ""},
    {"value": "临汾市尧都区汾河办事处社区卫生服务中心","address": ""},
    {"value": "太原爱瑞思口腔管理有限公司小店亲贤北街口腔门诊部","address": ""},
    {"value": "朔州开发区晨光医疗门诊部","address": ""},
    {"value": "太原星博雅口腔门诊部","address": ""},
    {"value": "芮城县医道堂中医所","address": ""},
    {"value": "太原市晋源区晋源新城东区社区卫生服务站","address": ""},
    {"value": "临县医疗集团大禹乡中心卫生院","address": ""},
    {"value": "原平市北城阳光社区卫生服务站","address": ""},
    {"value": "北大医疗潞安医院煤基油医务所","address": ""},
    {"value": "山西长治王庄煤业有限责任公司医务室","address": ""},
    {"value": "运城市盐湖区西城办事处圣惠桥社区卫生服务站","address": ""},
    {"value": "长治市潞城区西华北路社区卫生服务站","address": ""},
    {"value": "柳林利康精神病医院","address": ""},
    {"value": "太原恒伦口腔门诊管理有限公司万柏林文兴路口腔门诊部","address": ""},
    {"value": "山西汾西重工有限责任公司职工医院","address": ""},
    {"value": "临汾市尧都区辛寺街办事处光明社区卫生服务站","address": ""},
    {"value": "代县妇幼保健服务中心","address": ""},
    {"value": "孝义市医疗集团胜溪湖分院（孝义市胜溪湖社区卫生服务中心）","address": ""},
    {"value": "吕梁市离石区莲花池街道办城内社区卫生服务站","address": ""},
    {"value": "吕梁市离石区滨河街道办龙祥社区卫生服务站","address": ""},
    {"value": "吕梁市离石区西属巴街道办恒大社区卫生服务站","address": ""},
    {"value": "文水德济医院","address": ""},
    {"value": "交城北辰医院","address": ""},
    {"value": "交城康济医院","address": ""},
    {"value": "交城杏盛医院","address": ""},
    {"value": "兴县福康广济医院","address": ""},
    {"value": "兴县益达仁济医院","address": ""},
    {"value": "兴县鑫德医院","address": ""},
    {"value": "临县友德医院","address": ""},
    {"value": "临县华神精神病医院","address": ""},
    {"value": "柳林弘德医院","address": ""},
    {"value": "柳林县济仁堂中医医院","address": ""},
    {"value": "柳林城区医院","address": ""},
    {"value": "柳林和济医院","address": ""},
    {"value": "石楼兴楼医院","address": ""},
    {"value": "岚县益民卫生服务站","address": ""},
    {"value": "方山县武当社区卫生服务中心","address": ""},
    {"value": "方山县积翠社区卫生服务中心","address": ""},
    {"value": "方山县济森渊中西医结合医院","address": ""},
    {"value": "中阳县安康综合门诊部","address": ""},
    {"value": "孝义市齿科医院","address": ""},
    {"value": "孝义市赵庆仙口腔门诊部","address": ""},
    {"value": "孝义正骨医院","address": ""},
    {"value": "太原市尖草坪区南寨社区卫生服务中心","address": ""},
    {"value": "吕梁大健康科技健康管理有限公司吕梁美年综合门诊部","address": ""},
    {"value": "石楼县中医医院","address": ""},
    {"value": "大同凯霖耳鼻喉医院有限责任公司","address": ""},
    {"value": "太原中医肝病医院","address": ""},
    {"value": "云冈区新胜华杰里社区卫生服务站","address": ""},
    {"value": "河曲县沙坪乡卫生院","address": ""},
    {"value": "太原优牙口腔门诊部","address": ""},
    {"value": "阳泉新医正国医院","address": ""},
    {"value": "长治市潞州区太行西街街道桥北社区卫生服务站","address": ""},
    {"value": "汾阳市南薰街道社区卫生服务中心","address": ""},
    {"value": "古交西山医院","address": ""},
    {"value": "静乐县堂尔上乡卫生院","address": ""},
    {"value": "临汾市尧都区医疗集团人民医院","address": ""},
    {"value": "沁县段柳乡卫生院（沁县医疗集团段柳乡卫生院）","address": ""},
    {"value": "太原含贝口腔门诊部","address": ""},
    {"value": "古交市医疗集团（古交市医疗集团中心医院","address": ""},
    {"value": "古交市中心医院）","address": ""},
    {"value": "尧都金辉口腔门诊部","address": ""},
    {"value": "大同明康医学检验实验室","address": ""},
    {"value": "大同书荣口腔门诊部","address": ""},
    {"value": "运城广济医院","address": ""},
    {"value": "朔州市朔城区神头街道办事处神电社区卫生服务站","address": ""},
    {"value": "古交市屯兰社区卫生服务中心/古交市屯兰社区医院","address": ""},
    {"value": "大寨中心卫生院杜庄分院","address": ""},
    {"value": "太原康明眼科医院有限公司","address": ""},
    {"value": "侯马市路东街道办事处侯运社区卫生服务站","address": ""},
    {"value": "安泽县医疗集团","address": ""},
    {"value": "忻州惠宁医院","address": ""},
    {"value": "左权县妇幼保健计划生育服务中心","address": ""},
    {"value": "陵川博奕口腔有限公司","address": ""},
    {"value": "柳林县妇幼保健计划生育服务中心","address": ""},
    {"value": "临汾新华商业外科医院","address": ""},
    {"value": "屯兰街道办事处冷泉社区卫生服务站","address": ""},
    {"value": "文水县北张乡卫生院","address": ""},
    {"value": "山西省地方病防治研究所附属医院","address": ""},
    {"value": "绛县爱牙仕口腔门诊部","address": ""},
    {"value": "吕梁亿杰骨创外科医院","address": ""},
    {"value": "汾阳市妇幼保健计划生育服务中心","address": ""},
    {"value": "太原凯尔医院有限公司尖草坪大同路门诊部","address": ""},
    {"value": "灵丘兴荣联合医院","address": ""},
    {"value": "灵丘县上寨中心卫生院","address": ""},
    {"value": "吕梁市离石区滨河街道办前瓦社区卫生服务站","address": ""},
    {"value": "山西汾西工程建设有限责任公司综合门诊部","address": ""},
    {"value": "沁源县妇幼保健计划生育服务中心","address": ""},
    {"value": "太原上观综合门诊有限公司","address": ""},
    {"value": "朔州宁慈医院","address": ""},
    {"value": "太原恩典口腔门诊部","address": ""},
    {"value": "下栅乡卫生院","address": ""},
    {"value": "临县弘济中医院","address": ""},
    {"value": "静乐县康泰医院有限公司","address": ""},
    {"value": "阳泉市矿区医疗集团（阳泉市矿区医院）赛鱼社区卫生服务中心","address": ""},
    {"value": "乡宁蓝云医院","address": ""},
    {"value": "晋中刘双全定阳口腔门诊部有限公司","address": ""},
    {"value": "应县医疗集团南河种镇中心卫生院北曹山分院","address": ""},
    {"value": "芮城县永乐镇卫生院","address": ""},
    {"value": "平陆县部官镇卫生院（平陆县医疗集团部官镇卫生院）","address": ""},
    {"value": "和顺县青城镇卫生院","address": ""},
    {"value": "太原启典口腔门诊部有限公司","address": ""},
    {"value": "山西汾西矿业（集团）有限责任公司双柳煤矿职工医院","address": ""},
    {"value": "灵丘县独峪乡卫生院","address": ""},
    {"value": "孝义玛丽娅女子医院","address": ""},
    {"value": "孝义曙光骨科医院","address": ""},
    {"value": "孝义康宁医院","address": ""},
    {"value": "孝义市康明眼科门诊部","address": ""},
    {"value": "孝义瞳明医院","address": ""},
    {"value": "汾阳市万寿堂中医医院","address": ""},
    {"value": "汾阳市古庄医院","address": ""},
    {"value": "汾阳前瑞中西医结合医院","address": ""},
    {"value": "汾阳市盛大口腔门诊部","address": ""},
    {"value": "汾阳精协医院","address": ""},
    {"value": "汾阳市酒都医院有限公司","address": ""},
    {"value": "河津市城区办事处虎岗社区卫生服务站","address": ""},
    {"value": "临汾市尧都区医疗集团大阳镇卫生院","address": ""},
    {"value": "高平市三甲镇卫生院（高平市医疗集团三甲镇卫生院）","address": ""},
    {"value": "浮山县天坛镇卫生院","address": ""},
    {"value": "太原医大医院","address": ""},
    {"value": "汾阳西苑医院","address": ""},
    {"value": "山西省汾阳医院","address": ""},
    {"value": "孝义市医疗集团下堡分院（孝义市下堡中心卫生院）","address": ""},
    {"value": "孝义市医疗集团柱濮分院（孝义市柱濮镇卫生院）","address": ""},
    {"value": "孝义市医疗集团高阳分院（孝义市高阳镇卫生院）","address": ""},
    {"value": "孝义市医疗集团西辛庄分院（孝义市西辛庄中心卫生院）","address": ""},
    {"value": "孝义市医疗集团新义分院（孝义市新义社区卫生服务中心）","address": ""},
    {"value": "孝义市人民医院（孝义市医疗集团、孝义市医疗集团人民医院）","address": ""},
    {"value": "孝义市中医院","address": ""},
    {"value": "孝义市儿童医院","address": ""},
    {"value": "孝义市医疗集团兑镇分院（孝义市兑镇中心卫生院）","address": ""},
    {"value": "孝义市医疗集团梧桐分院（孝义市梧桐中心卫生院）","address": ""},
    {"value": "孝义市医疗集团阳泉曲分院（孝义市阳泉曲镇卫生院）","address": ""},
    {"value": "吕梁市离石区医疗集团凤山街道办社区卫生服务中心（吕梁市离石区凤山街道办社区卫生服务中心）","address": ""},
    {"value": "吕梁市离石区医疗集团坪头乡中心卫生院（吕梁市离石区坪头乡中心卫生院）","address": ""},
    {"value": "吕梁市离石区医疗集团城北街道办社区卫生服务中心（吕梁市离石区城北街道办社区卫生服务中心）","address": ""},
    {"value": "吕梁市离石区医疗集团信义镇卫生院（吕梁市离石区信义镇卫生院）","address": ""},
    {"value": "吕梁市离石区医疗集团吴城镇卫生院（吕梁市离石区吴城镇卫生院）","address": ""},
    {"value": "吕梁市离石区医疗集团交口街道办事处社区卫生服务中心（吕梁市离石区交口街道办事处社区卫生服务中心）","address": ""},
    {"value": "吕梁市离石区医疗集团枣林乡卫生院（吕梁市离石区枣林乡卫生院）","address": ""},
    {"value": "汾阳市中医院","address": ""},
    {"value": "汾阳市汾酒集团社区卫生服务中心","address": ""},
    {"value": "汾阳市人民医院","address": ""},
    {"value": "交城县中医医院","address": ""},
    {"value": "交城县医疗集团水峪贯镇卫生院（交城县水峪贯镇卫生院）","address": ""},
    {"value": "交城县医疗集团庞泉沟镇卫生院（交城县庞泉沟镇中心卫生院）","address": ""},
    {"value": "交城县医疗集团东坡底乡卫生院（交城县东坡底乡卫生院）","address": ""},
    {"value": "交城县医疗集团夏家营镇卫生院（交城县医疗集团夏家营镇中心卫生院）","address": ""},
    {"value": "交城县医疗集团西社镇中心卫生院（交城县西社镇中心卫生院）","address": ""},
    {"value": "兴县魏家滩镇中心卫生院（兴县医疗集团魏家滩镇卫生院）","address": ""},
    {"value": "兴县蔚汾镇卫生院关家崖分院","address": ""},
    {"value": "兴县罗峪口镇卫生院（兴县医疗集团罗峪口镇卫生院）","address": ""},
    {"value": "兴县蔚汾镇卫生院（兴县医疗集团蔚汾镇卫生院）","address": ""},
    {"value": "兴县魏家滩镇中心卫生院白家沟分院","address": ""},
    {"value": "兴县蔡家崖乡卫生院杨家坡分院","address": ""},
    {"value": "兴县贺家会乡卫生院（兴县医疗集团贺家会乡卫生院）","address": ""},
    {"value": "兴县康宁镇中心卫生院（兴县医疗集团康宁镇卫生院）","address": ""},
    {"value": "兴县蔡家会镇中心卫生院（兴县医疗集团蔡家会镇卫生院）","address": ""},
    {"value": "临县第二人民医院","address": ""},
    {"value": "临县青凉寺乡中心卫生院（临县医疗集团青凉寺乡中心卫生院）","address": ""},
    {"value": "临县医疗集团林家坪镇中心卫生院","address": ""},
    {"value": "临县招贤镇中心卫生院（临县医疗集团招贤镇中心卫生院）","address": ""},
    {"value": "临县玉坪乡卫生院（临县医疗集团玉坪乡卫生院）","address": ""},
    {"value": "临县兔坂镇中心卫生院（临县医疗集团兔坂镇中心卫生院）","address": ""},
    {"value": "柳林县医疗集团（柳林县人民医院、柳林县医疗集团人民医院）","address": ""},
    {"value": "柳林县医疗集团贾家垣乡卫生院分院（柳林县贾家垣乡卫生院分院）","address": ""},
    {"value": "柳林县医疗集团贾家垣乡卫生院（柳林县贾家垣乡卫生院）","address": ""},
    {"value": "柳林县医疗集团高家沟乡卫生院（柳林县高家沟乡卫生院）","address": ""},
    {"value": "柳林县医疗集团李家湾乡卫生院（柳林县李家湾乡卫生院）","address": ""},
    {"value": "柳林县医疗集团孟门镇卫生院（柳林县孟门镇卫生院）","address": ""},
    {"value": "柳林县医疗集团三交镇中心卫生院（柳林县三交镇中心卫生院）","address": ""},
    {"value": "柳林县医疗集团王家沟乡卫生院分院","address": ""},
    {"value": "柳林县医疗集团留誉镇卫生院（柳林县留誉镇卫生院）","address": ""},
    {"value": "柳林县医疗集团柳林镇卫生院（柳林县柳林镇卫生院）","address": ""},
    {"value": "柳林县医疗集团金家庄乡中心卫生院（柳林县金家庄乡中心卫生院）","address": ""},
    {"value": "柳林县医疗集团留誉镇卫生院分院（柳林县留誉镇卫生院分院）","address": ""},
    {"value": "石楼县曹家垣乡卫生院（石楼县医疗集团曹家垣乡卫生院）","address": ""},
    {"value": "石楼县辛关镇卫生院（石楼县医疗集团辛关镇卫生院）","address": ""},
    {"value": "石楼县龙交乡中心卫生院（石楼县医疗集团龙交乡中心卫生院）","address": ""},
    {"value": "石楼县和合乡卫生院西山分院（石楼县医疗集团和合乡卫生院西山分院）","address": ""},
    {"value": "石楼县小蒜镇卫生院韩家山分院（石楼县医疗集团小蒜镇卫生院韩家山分院）","address": ""},
    {"value": "石楼县和合乡卫生院（石楼县医疗集团和合乡卫生院）","address": ""},
    {"value": "石楼县裴沟乡卫生院（石楼县医疗集团裴沟乡卫生院）","address": ""},
    {"value": "石楼县医疗集团辛关镇卫生院韦家湾分院","address": ""},
    {"value": "岚县岚城镇中心卫生院河口分院","address": ""},
    {"value": "岚县医疗集团（岚县医疗集团人民医院、岚县人民医院）","address": ""},
    {"value": "岚县王狮乡卫生院","address": ""},
    {"value": "岚县中医院","address": ""},
    {"value": "岚县梁家庄乡中心卫生院","address": ""},
    {"value": "岚县岚城镇中心卫生院","address": ""},
    {"value": "岚县顺会乡卫生院","address": ""},
    {"value": "岚县社科乡卫生院","address": ""},
    {"value": "方山县峪口镇卫生院（方山县医疗集团峪口分院）","address": ""},
    {"value": "方山县圪洞镇卫生院（方山县医疗集团圪洞分院）","address": ""},
    {"value": "方山县马坊镇卫生院开府分院（方山县医疗集团开府分院）","address": ""},
    {"value": "方山县中医院","address": ""},
    {"value": "方山县峪口镇卫生院张家塔分院（方山县医疗集团张家塔分院）","address": ""},
    {"value": "方山县大武镇中心卫生院（方山县医疗集团大武分院）","address": ""},
    {"value": "方山县北武当镇卫生院（方山县医疗集团北武当分院）","address": ""},
    {"value": "中阳县职工医院","address": ""},
    {"value": "交口县石口中心卫生院（交口县医疗集团石口中心卫生院）","address": ""},
    {"value": "交口县桃红坡中心卫生院（交口县医疗集团桃红坡中心卫生院）","address": ""},
    {"value": "交口县双池中心卫生院（交口县医疗集团双池中心卫生院）","address": ""},
    {"value": "交口县温泉乡卫生院（交口县医疗集团温泉乡卫生院）","address": ""},
    {"value": "交口县回龙乡卫生院（交口县医疗集团回龙乡卫生院）","address": ""},
    {"value": "交口县康城中心卫生院（交口县医疗集团康城中心卫生院）","address": ""},
    {"value": "晋城市城区南大街社区服务站","address": ""},
    {"value": "闻喜老年公寓医院","address": ""},
    {"value": "晋能控股煤业集团轩岗煤电有限责任公司医院","address": ""},
    {"value": "灵丘县医疗集团石家田中心卫生院","address": ""},
    {"value": "太原市审美口腔门诊有限公司万柏林鑫美天地审美口腔门诊部","address": ""},
    {"value": "侯马市路东街道办事处花园南街社区卫生服务站","address": ""},
    {"value": "岚县城西社区卫生服务站","address": ""},
    {"value": "临汾市尧都区路东办事处第二社区卫生服务中心","address": ""},
    {"value": "大同市云冈区瑞婕皮肤病门诊部","address": ""},
    {"value": "忻州泰康堂消化病医院","address": ""},
    {"value": "繁峙县横涧乡卫生院","address": ""},
    {"value": "芮城县寿圣社区第二卫生服务站","address": ""},
    {"value": "永济市张营镇卫生院","address": ""},
    {"value": "山西博瑞中西医结合医院","address": ""},
    {"value": "晋中市榆次区奕贝尔口腔门诊部有限公司","address": ""},
    {"value": "河津市城区办事处府东社区卫生服务站","address": ""},
    {"value": "方山县麻地会乡卫生院","address": ""},
    {"value": "汾西县中医院","address": ""},
    {"value": "平定县冠山镇卫生院维社分院","address": ""},
    {"value": "中阳县痔瘘烧伤专科医院","address": ""},
    {"value": "大同市平城区迎宾街道社区卫生服务中心","address": ""},
    {"value": "闻喜县郭家庄镇中心卫生院七里坡分院","address": ""},
    {"value": "泽州县妇幼保健院","address": ""},
    {"value": "交城县妇幼保健计划生育服务中心","address": ""},
    {"value": "侯马市路西街道办事处平阳南社区卫生服务站","address": ""},
    {"value": "太原市迎泽区水西关街一社区卫生服务站","address": ""},
    {"value": "临汾市尧都区滨河办事处高河店社区卫生服务站","address": ""},
    {"value": "阳曲县北小店乡卫生院（阳曲县医疗集团北小店乡卫生院）","address": ""},
    {"value": "原平市段家堡乡卫生院牛食尧社区卫生服务站","address": ""},
    {"value": "晋中市榆次区晋华街道北窑社区卫生服务站","address": ""},
    {"value": "长治市潞城区水岸春城社区卫生服务站","address": ""},
    {"value": "古交市阁上乡卫生院（古交市医疗集团阁上乡卫生院）","address": ""},
    {"value": "太原市杏花岭区七一社区卫生服务站","address": ""},
    {"value": "灵丘县中医院","address": ""},
    {"value": "汾阳市汾酒集团社区南苑卫生站","address": ""},
    {"value": "太原美佳口腔医疗科技有限公司万柏林和平路口腔门诊部","address": ""},
    {"value": "太原达美联合口腔门诊部","address": ""},
    {"value": "浑源县医疗集团吴城乡卫生院","address": ""},
    {"value": "闻喜县桐城镇卫生院下阳分院","address": ""},
    {"value": "华晋焦煤有限责任公司沙曲矿医务室","address": ""},
    {"value": "阳高县鳌石乡卫生院","address": ""},
    {"value": "南关柳航里社区卫生服务站","address": ""},
    {"value": "大同市长生堂中医门诊部有限公司","address": ""},
    {"value": "绛县口腔医院","address": ""},
    {"value": "阳泉市郊区医疗集团平坦分院（阳泉市郊区平坦镇卫生院）","address": ""},
    {"value": "晋城医动健康体检有限公司体检中心","address": ""},
    {"value": "朔州市平鲁区安南社区卫生服务站","address": ""},
    {"value": "阳城县河北镇卫生院西交分院","address": ""},
    {"value": "太原市尖草坪区心和堂门诊部","address": ""},
    {"value": "左云县冯东涛医疗服务部","address": ""},
    {"value": "岚县普明镇中心卫生院","address": ""},
    {"value": "太原市三和堂中医馆有限公司三和堂中医门诊部","address": ""},
    {"value": "北大医疗潞安医院石圪节分院","address": ""},
    {"value": "米峪镇乡卫生院","address": ""},
    {"value": "榆次区西南街道社区卫生服务中心","address": ""},
    {"value": "介休友谊创伤外科医院","address": ""},
    {"value": "太原市小店区平北西二社区卫生服务站","address": ""},
    {"value": "汾西县医疗集团勍香中心卫生院","address": ""},
    {"value": "朔州市平鲁区勤易社区卫生服务站","address": ""},
    {"value": "介休恒康门诊部","address": ""},
    {"value": "孝义市医疗集团兑镇分院城区服务站","address": ""},
    {"value": "运城市盐湖区北城办事处学苑花都社区卫生服务站","address": ""},
    {"value": "定襄城镇社区综合门诊部","address": ""},
    {"value": "山阴县医疗集团史家屯分院","address": ""},
    {"value": "曲沃县乐昌镇西南街卫生分所","address": ""},
    {"value": "新绛民安医院","address": ""},
    {"value": "临县医疗集团安业乡卫生院","address": ""},
    {"value": "临县临泉镇卫生院万安里社区卫生服务站","address": ""},
    {"value": "山西兰花煤化工有限责任公司医疗室","address": ""},
    {"value": "昔阳县晋祥老年养护院医疗服务点","address": ""},
    {"value": "大同古城仲泰中西医结合医院","address": ""},
    {"value": "襄汾县南辛店乡卫生院","address": ""},
    {"value": "运城市盐湖区姚孟办事处天茂城社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区耐火社区卫生服务站","address": ""},
    {"value": "介休鑫科糖尿病门诊部","address": ""},
    {"value": "盂县秀水西街孝义门诊","address": ""},
    {"value": "府东街社区卫生服务站","address": ""},
    {"value": "大同市平城区新旺街道社区卫生服务中心","address": ""},
    {"value": "太原市杏花岭区太行路社区卫生服务站","address": ""},
    {"value": "临汾冯亚平中医门诊部","address": ""},
    {"value": "吕梁市离石区天使齿科门诊部","address": ""},
    {"value": "忻州市建筑工程总公司微创医院","address": ""},
    {"value": "长子县鲍店镇卫生院（长子县医疗集团鲍店镇卫生院）","address": ""},
    {"value": "国药同煤总医院","address": ""},
    {"value": "河曲县医疗集团沙泉镇卫生院","address": ""},
    {"value": "忻州市忻府区优抚医院","address": ""},
    {"value": "太原市迎泽区双塔西街一社区卫生服务站","address": ""},
    {"value": "大同市轩草堂中医门诊部有限公司","address": ""},
    {"value": "晋中正和医院","address": ""},
    {"value": "平遥县古陶镇卫生院","address": ""},
    {"value": "运城瑞芝中医康复医院","address": ""},
    {"value": "襄汾县景毛乡卫生院","address": ""},
    {"value": "山西省太原市杏花岭区职工新街社区卫生服务中心","address": ""},
    {"value": "介休市连福中心卫生院","address": ""},
    {"value": "太原达美门诊管理有限公司迎西口腔门诊部","address": ""},
    {"value": "河津市铝基地社区卫生服务中心","address": ""},
    {"value": "沁源县王陶中心卫生院","address": ""},
    {"value": "朔州市朔城区振华西街社区卫生服务站","address": ""},
    {"value": "怀仁广仁堂医院","address": ""},
    {"value": "北京中能建朔州医院","address": ""},
    {"value": "保德县尧圪台乡卫生院","address": ""},
    {"value": "侯马市医疗集团/侯马市医疗集团人民医院/侯马市人民医院","address": ""},
    {"value": "陵川县人民医院","address": ""},
    {"value": "运城市口腔卫生学校附属口腔医院","address": ""},
    {"value": "忻州市恒伦口腔公园街门诊有限公司","address": ""},
    {"value": "中阳县三和泰医院有限公司","address": ""},
    {"value": "临汾市尧都区解放路办事处绿苑社区卫生服务站","address": ""},
    {"value": "阳泉煤业（集团）有限责任公司总医院工程处医院","address": ""},
    {"value": "运城文博骨科医院","address": ""},
    {"value": "临猗县中医医院","address": ""},
    {"value": "太原市杏花岭区剪子湾社区卫生服务站","address": ""},
    {"value": "太原市木子口腔门诊部","address": ""},
    {"value": "壶关县大峡谷镇卫生院鹅屋分院","address": ""},
    {"value": "晋城市城区运祥口腔门诊部","address": ""},
    {"value": "太原市幸福里口腔门诊有限公司万柏林晋祠路口腔门诊部","address": ""},
    {"value": "中部战区空军医院","address": ""},
    {"value": "吕梁爱尔眼科医院","address": ""},
    {"value": "太原市尖草坪区选煤社区卫生服务站","address": ""},
    {"value": "吕梁市离石区凤山街道办梧桐社区卫生服务站","address": ""},
    {"value": "清徐县孟封镇中心卫生院杨房分院","address": ""},
    {"value": "忻府区解原乡卫生院上社社区卫生服务站","address": ""},
    {"value": "临汾侨联眼科医院","address": ""},
    {"value": "五花城分院","address": ""},
    {"value": "古交市桃园街道办事处当中街社区卫生服务站","address": ""},
    {"value": "盐湖泽华口腔门诊部","address": ""},
    {"value": "保德县孙家沟乡卫生院","address": ""},
    {"value": "山西省康复研究中心附属医院","address": ""},
    {"value": "沾尚中心卫生院西寨分院","address": ""},
    {"value": "太原精诚口腔门诊部","address": ""},
    {"value": "大同市云冈区老平旺绿洲西城社区卫生服务站","address": ""},
    {"value": "大同五洲心血管病医院","address": ""},
    {"value": "运城市盐湖区南城社区卫生服务中心西姚分中心","address": ""},
    {"value": "古交市西曲街道西曲社区卫生服务站","address": ""},
    {"value": "岚县聚先中医康复医院","address": ""},
    {"value": "长治市潞州区西街街道长轴社区卫生服务站","address": ""},
    {"value": "平定县冠山镇大林山街社区卫生服务站","address": ""},
    {"value": "繁峙县人民医院","address": ""},
    {"value": "岢岚仁爱医院","address": ""},
    {"value": "大同市云州区峰峪乡卫生院徐疃社区卫生服务站","address": ""},
    {"value": "云冈区平泉社区卫生服务中心","address": ""},
    {"value": "长治亿大口腔医院","address": ""},
    {"value": "五台县门限石乡卫生院湾子社区服务站","address": ""},
    {"value": "太原市晋源区义井社区卫生服务中心","address": ""},
    {"value": "繁峙县职工医院","address": ""},
    {"value": "古县惠民医院","address": ""},
    {"value": "岚县岚海医院有限公司","address": ""},
    {"value": "长治卫生学校附属医院","address": ""},
    {"value": "阳高安怡医院","address": ""},
    {"value": "孝义博爱医院","address": ""},
    {"value": "河津安康医院","address": ""},
    {"value": "霍州市医疗集团退沙社区卫生服务中心","address": ""},
    {"value": "长治清华机械厂职工医院","address": ""},
    {"value": "古交市邢家社乡中心卫生院（古交市医疗集团邢家社乡卫生院）","address": ""},
    {"value": "太原市小店区许坦一社区卫生服务站","address": ""},
    {"value": "襄汾县邓庄镇卫生院张礼分院","address": ""},
    {"value": "山西农业大学医院","address": ""},
    {"value": "山西恒依菲尔口腔门诊有限公司小店龙城南街口腔门诊部","address": ""},
    {"value": "临县大禹乡中心卫生院后大禹社区卫生服务站","address": ""},
    {"value": "平顺县虹梯关乡卫生院","address": ""},
    {"value": "洪洞县第二人民医院（洪洞县医疗集团第二人民医院）","address": ""},
    {"value": "吕梁市离石区莲花池街道办兴南社区卫生服务站","address": ""},
    {"value": "忻州岳贵才皮肤专科门诊部","address": ""},
    {"value": "大同长生同康肾病医院有限责任公司","address": ""},
    {"value": "隰县医疗集团","address": ""},
    {"value": "静乐县人民医院","address": ""},
    {"value": "汾阳残康中医医院","address": ""},
    {"value": "文水县南武乡卫生院","address": ""},
    {"value": "振华南街绿园社区卫生服务站","address": ""},
    {"value": "郭权按摩院","address": ""},
    {"value": "太原康斯亚森普通专科门诊部","address": ""},
    {"value": "太原龙城中医医院","address": ""},
    {"value": "艾德仕医疗有限责任公司万柏林艾德仕义井口腔门诊部","address": ""},
    {"value": "长治市曼陀精神康复医院有限公司","address": ""},
    {"value": "长治市潞州区英雄中路街道社区卫生服务中心","address": ""},
    {"value": "太原市杏花岭区精神康复医院","address": ""},
    {"value": "临汾市尧都区医疗集团屯里镇卫生院","address": ""},
    {"value": "盐湖正好事合口腔门诊部","address": ""},
    {"value": "运城市盐湖区北城办事处星河社区卫生服务站","address": ""},
    {"value": "中铁十二局集团有限公司中心医院","address": ""},
    {"value": "霍州市医疗集团三教乡卫生院","address": ""},
    {"value": "太原市慈仁堂门诊部","address": ""},
    {"value": "太原市亿森口腔门诊管理有限公司杏花岭万达口腔门诊部","address": ""},
    {"value": "繁峙县金山铺乡卫生院","address": ""},
    {"value": "襄汾泰鑫妇产医院","address": ""},
    {"value": "永济市卿头镇卫生院（永济市医疗集团卿头镇卫生院））","address": ""},
    {"value": "吕梁怡华妇产医院","address": ""},
    {"value": "云冈区泰昌社区卫生服务中心","address": ""},
    {"value": "长治市上党区贾掌镇卫生院","address": ""},
    {"value": "长治市都市卫康复有限公司屯留康复门诊部","address": ""},
    {"value": "新绛气象路社区卫生服务站","address": ""},
    {"value": "东缉虎营社区卫生服务站","address": ""},
    {"value": "曲沃王琳口腔门诊部","address": ""},
    {"value": "河津同信医院","address": ""},
    {"value": "平鲁万众鑫医院","address": ""},
    {"value": "浑源县医疗集团永安镇卫生院张庄分院","address": ""},
    {"value": "太原市万柏林区太白街社区卫生服务站","address": ""},
    {"value": "太原市万柏林区国兴社区卫生服务站","address": ""},
    {"value": "太原西山白家庄医院","address": ""},
    {"value": "太原国一堂中医门诊部","address": ""},
    {"value": "临县医疗集团临泉镇街道卫生院","address": ""},
    {"value": "左云县中医医院","address": ""},
    {"value": "介休市洪山镇卫生院（介休市医疗集团洪山分院）","address": ""},
    {"value": "新绛县城区社区卫生服务中心","address": ""},
    {"value": "介休市义安镇中心卫生院北辛武分院（介休市医疗集团北辛武分院）","address": ""},
    {"value": "宁武县医疗集团凤凰镇卫生院","address": ""},
    {"value": "盂县医疗集团秀水镇卫生院","address": ""},
    {"value": "潞城区翟店镇卫生院","address": ""},
    {"value": "大同平城中医医院","address": ""},
    {"value": "壶关县龙泉镇城北社区北卫生服务站","address": ""},
    {"value": "太原橙益口腔门诊有限责任公司万柏林奥林口腔门诊部","address": ""},
    {"value": "泽州县金佰仁医疗服务有限公司","address": ""},
    {"value": "岚县康民乐社区卫生服务站","address": ""},
    {"value": "壶关县妇幼保健计划生育服务中心","address": ""},
    {"value": "运城市盐湖区中医医院","address": ""},
    {"value": "临县医疗集团临泉镇卫生院","address": ""},
    {"value": "晋中市太谷区阳邑乡中心卫生院","address": ""},
    {"value": "应县妇幼保健计划生育服务中心","address": ""},
    {"value": "宁武县民康医院","address": ""},
    {"value": "榆社县计生妇幼保健院","address": ""},
    {"value": "朔州市朔城区市府西街社区卫生服务站","address": ""},
    {"value": "万荣怡康老年中西医结合医院","address": ""},
    {"value": "晋城高新肾病专科医院","address": ""},
    {"value": "蒲县医疗集团山中乡卫生院","address": ""},
    {"value": "山西日报报业集团医务室","address": ""},
    {"value": "山西汾西矿业（集团）有限责任公司职工总医院","address": ""},
    {"value": "隰县医疗集团寨子乡卫生院","address": ""},
    {"value": "朔州市朔城区民福小区社区卫生服务站","address": ""},
    {"value": "晋中市第三人民医院","address": ""},
    {"value": "山西和谐医疗健康中心","address": ""},
    {"value": "新绛县横桥中心卫生院","address": ""},
    {"value": "晋中市榆次区晋华街道晋华社区卫生服务站","address": ""},
    {"value": "垣曲县英言镇中心卫生院","address": ""},
    {"value": "阳曲县西凌井乡卫生院（阳曲县医疗集团西凌井乡卫生院）","address": ""},
    {"value": "太原德富华中医医院","address": ""},
    {"value": "大同市新荣区花元屯乡卫生院","address": ""},
    {"value": "北大医疗潞安医院容海医务室","address": ""},
    {"value": "云冈区四老沟社区卫生服务中心","address": ""},
    {"value": "孝义市医疗集团阳泉曲分院城区服务站","address": ""},
    {"value": "祁县城赵镇中心卫生院（祁县医疗集团城赵镇中心卫生院）","address": ""},
    {"value": "孟家坪乡卫生院","address": ""},
    {"value": "介休维卫医院","address": ""},
    {"value": "山西省大同市阳高县人民医院","address": ""},
    {"value": "阳曲县中医医院","address": ""},
    {"value": "临猗蓉德口腔医院","address": ""},
    {"value": "新绛县牙病防治所","address": ""},
    {"value": "平定县冠山镇东门街社区卫生服务站","address": ""},
    {"value": "太原市小店区北格镇卫生院","address": ""},
    {"value": "文水县苍儿会旅游开发区卫生院","address": ""},
    {"value": "柳林康民中医院","address": ""},
    {"value": "太原市杏花岭区响水湾社区卫生服务站","address": ""},
    {"value": "大同市康顺连锁门诊部有限公司晨馨店","address": ""},
    {"value": "晋城市中和正元堂医药有限公司","address": ""},
    {"value": "平定县巨城镇卫生院（平定县医疗集团巨城镇分院）","address": ""},
    {"value": "文水县南庄镇中心卫生院","address": ""},
    {"value": "临汾云心风湿心理医院","address": ""},
    {"value": "邓庄镇卫生院","address": ""},
    {"value": "太原市尖草坪区迎新医院","address": ""},
    {"value": "岢岚县高家会乡卫生院","address": ""},
    {"value": "太原市迎泽区双塔寺街一社区卫生服务站","address": ""},
    {"value": "太原晋步口腔门诊管理有限公司桥东街口腔门诊部","address": ""},
    {"value": "太原市迎泽区并州路一社区卫生服务站","address": ""},
    {"value": "临猗县第二人民医院","address": ""},
    {"value": "偏关县医疗集团陈家营乡卫生院","address": ""},
    {"value": "晋城市妇幼保健计划生育服务中心（晋城市妇幼保健院、晋城市儿童医院）","address": ""},
    {"value": "太原市晋源区兰亭御湖城社区卫生服务站","address": ""},
    {"value": "太原云华爱心护理院","address": ""},
    {"value": "新建南路北馨园社区卫生服务站","address": ""},
    {"value": "晋中市榆次区华仁医院","address": ""},
    {"value": "岚县社区卫生服务中心","address": ""},
    {"value": "长治杏林康复医院","address": ""},
    {"value": "繁峙县繁城镇卫生院杏园分院","address": ""},
    {"value": "长治市潞城区中医院","address": ""},
    {"value": "大同蓝丁格尔妇女保健门诊部","address": ""},
    {"value": "大同市滋润堂中医门诊部","address": ""},
    {"value": "介休市连福中心卫生院门诊","address": ""},
    {"value": "忻州市忻府区尚德医院","address": ""},
    {"value": "原平市大牛店镇卫生院上阳武社区卫生服务站","address": ""},
    {"value": "临汾市尧都区乡贤街办事处乡贤社区卫生服务站","address": ""},
    {"value": "太原市尖草坪区滨河路南社区卫生服务站","address": ""},
    {"value": "芮城县中医医院","address": ""},
    {"value": "吕梁市离石区城北街道办站前社区卫生服务站","address": ""},
    {"value": "太原市迎泽区老军营社区卫生服务站","address": ""},
    {"value": "太原市小店区永康社区卫生服务站","address": ""},
    {"value": "阳泉市郊区宏苑社区卫生服务站","address": ""},
    {"value": "芮城县寿圣社区卫生服务站","address": ""},
    {"value": "吕梁春珍齿科医院","address": ""},
    {"value": "应县医疗集团大临河乡中心卫生院","address": ""},
    {"value": "朔州市朔城区市府街社区卫生服务站","address": ""},
    {"value": "山西颐宁康养老服务医务室","address": ""},
    {"value": "山西省职业病医院（山西省第二人民医院、山西省职工职业病防治院）","address": ""},
    {"value": "定襄县医疗集团神山乡卫生院","address": ""},
    {"value": "襄垣县西营镇卫生院（襄垣县医疗集团西营镇卫生院）","address": ""},
    {"value": "山西省民政康复医院","address": ""},
    {"value": "陵川县平城镇中心卫生院","address": ""},
    {"value": "清徐县中医院","address": ""},
    {"value": "太原其美妇产医院","address": ""},
    {"value": "大同市云冈区新胜社区卫生服务中心","address": ""},
    {"value": "大同市云冈区医疗集团口泉乡卫生院","address": ""},
    {"value": "汾阳市石庄镇卫生院","address": ""},
    {"value": "运城祥贤居康复护理院","address": ""},
    {"value": "汾阳市西河街道中华街社区卫生服务站","address": ""},
    {"value": "盐湖现代佳美口腔门诊部","address": ""},
    {"value": "文水县人民医院","address": ""},
    {"value": "大宁县医疗集团曲峨镇中心卫生院","address": ""},
    {"value": "晋中恒伦口腔门诊部（有限公司）","address": ""},
    {"value": "朔州市平鲁区下木角乡卫生院","address": ""},
    {"value": "太原馨晔第二门诊部","address": ""},
    {"value": "交城县医疗集团人民医院","address": ""},
    {"value": "五台县高洪口乡卫生院","address": ""},
    {"value": "应县医疗集团","address": ""},
    {"value": "应县医疗集团人民医院；应县人民医院","address": ""},
    {"value": "临汾市尧都区医疗集团辛寺街办事处社区卫生服务中心","address": ""},
    {"value": "太原市万柏林区迁出线社区卫生服务站","address": ""},
    {"value": "太原嘉洁口腔门诊部","address": ""},
    {"value": "代县雁门中西医结合医院","address": ""},
    {"value": "柳林三盛堂中医医院","address": ""},
    {"value": "孝义市崇文街道新建社区卫生服务站","address": ""},
    {"value": "晋城市城区金盾专科门诊部","address": ""},
    {"value": "太原市尖草坪区恒山23社区卫生服务站","address": ""},
    {"value": "吕梁市中医院","address": ""},
    {"value": "长治市潞城区晋水社区卫生服务站","address": ""},
    {"value": "长子县南陈镇卫生院西堡头分院","address": ""},
    {"value": "交口福康医院","address": ""},
    {"value": "山西兰花沁裕煤矿医务室","address": ""},
    {"value": "太原市万柏林区玉园南社区卫生服务站","address": ""},
    {"value": "天镇康宁康济医院","address": ""},
    {"value": "大同瑞慈医学影像诊断中心有限公司","address": ""},
    {"value": "太原达美门诊管理有限公司杏花岭旱西关口腔门诊部","address": ""},
    {"value": "山阴县医疗集团马营乡卫生院","address": ""},
    {"value": "原平市苏龙口镇中心卫生院","address": ""},
    {"value": "西山煤电（集团）有限责任公司职业病防治所","address": ""},
    {"value": "繁峙县医疗集团繁峙砂河镇卫生院","address": ""},
    {"value": "运城市盐湖区工业园区复旦大街社区卫生服务站","address": ""},
    {"value": "夏县水头镇中心卫生院","address": ""},
    {"value": "晋城经济开发区东吕匠社区卫生服务站","address": ""},
    {"value": "平遥县中医院","address": ""},
    {"value": "五台博爱医院","address": ""},
    {"value": "太原长城骨伤手外科医院","address": ""},
    {"value": "晋城市城区北街街道办事处古书院矿绿苑社区卫生服务站","address": ""},
    {"value": "交口县医疗集团人民医院","address": ""},
    {"value": "静乐县丰润镇中心卫生院","address": ""},
    {"value": "太原市小店区山毛社区卫生服务站","address": ""},
    {"value": "永济岐黄轩中医馆","address": ""},
    {"value": "洪洞县刘家垣镇卫生院（洪洞县医疗集团刘家垣镇卫生院）","address": ""},
    {"value": "太原市杏花岭区鼓楼社区卫生服务中心","address": ""},
    {"value": "晋中市榆次区新建街道佳地社区卫生服务站","address": ""},
    {"value": "乡宁县中医医院","address": ""},
    {"value": "寿阳县妇幼保健计划生育服务中心(寿阳县妇幼保健院）","address": ""},
    {"value": "山西兰花集团莒山煤矿有限公司综合门诊部","address": ""},
    {"value": "汾阳友爱医院","address": ""},
    {"value": "古交西山马兰医院","address": ""},
    {"value": "怀仁光明眼科医院","address": ""},
    {"value": "汾阳市太和桥街道社区卫生服务中心","address": ""},
    {"value": "太原市杏花岭区胜利东街社区卫生服务站","address": ""},
    {"value": "大同康寿合中医医院","address": ""},
    {"value": "临县白文镇中心卫生院","address": ""},
    {"value": "双塔西街三社区卫生服务站","address": ""},
    {"value": "临汾建宁妇产医院","address": ""},
    {"value": "介休广誉远中医诊疗服务有限公司紫光苑门诊部","address": ""},
    {"value": "原平康宁医院","address": ""},
    {"value": "介休市城区绿都门诊部","address": ""},
    {"value": "长治市第三人民医院","address": ""},
    {"value": "神池县烈堡乡中心卫生院","address": ""},
    {"value": "阳泉市矿区医疗集团（阳泉市矿区医院）贵石沟社区卫生服务中心","address": ""},
    {"value": "太原市伊凡口腔科技有限公司健康北街口腔门诊部","address": ""},
    {"value": "应县康乐医院","address": ""},
    {"value": "山西泽鑫药业有限公司","address": ""},
    {"value": "永济市韩阳镇卫生院首阳分院","address": ""},
    {"value": "太原市迎泽区龙城新苑第一社区卫生服务站","address": ""},
    {"value": "偏关县中医院","address": ""},
    {"value": "临汾和平耳鼻喉专科医院","address": ""},
    {"value": "汾阳敬仁医院","address": ""},
    {"value": "偏关县天峰坪卫生院","address": ""},
    {"value": "晋城雅贝健康管理有限公司口腔门诊部","address": ""},
    {"value": "平定县柏井镇卫生院","address": ""},
    {"value": "山西医科大学第二医院（西院）","address": ""},
    {"value": "临汾市尧都区医疗集团枕头乡卫生院","address": ""},
    {"value": "山西齿科医院","address": ""},
    {"value": "临猗县北景乡中心卫生院大闫分院","address": ""},
    {"value": "灵石县翠峰镇卫生院水峪分院","address": ""},
    {"value": "方山县马坊镇中心卫生院","address": ""},
    {"value": "平顺县西沟乡卫生院","address": ""},
    {"value": "临汾市尧都区医疗集团县底镇卫生院","address": ""},
    {"value": "广灵县医疗集团宜兴乡卫生院","address": ""},
    {"value": "潞城区史回乡卫生院","address": ""},
    {"value": "寿阳仁康口腔医疗有限公司博大街口腔门诊部","address": ""},
    {"value": "山西荣华坞城医疗门诊部（有限公司）","address": ""},
    {"value": "长子县鲍店镇中心卫生院南常分院（长子县医疗集团鲍店分院南常分部）","address": ""},
    {"value": "朔州市大医院","address": ""},
    {"value": "云冈区和顺泰荣里社区卫生服务中心","address": ""},
    {"value": "灵丘县柳科乡卫生院","address": ""},
    {"value": "侯马市浍滨街道办事处一公司东社区卫生服务站","address": ""},
    {"value": "太原市小店区6904社区卫生服务站","address": ""},
    {"value": "万荣惠仁中西医结合医院","address": ""},
    {"value": "高平市野川镇卫生院（高平市人民医院野川分院）","address": ""},
    {"value": "沁水县嘉峰镇中心卫生院","address": ""},
    {"value": "榆次区乌金山镇中心卫生院什贴分院（榆次区医疗集团乌金山镇中心卫生院什贴分院）","address": ""},
    {"value": "平遥县南政乡卫生院","address": ""},
    {"value": "平遥县香乐乡卫生院西王智卫生站","address": ""},
    {"value": "壶关博康医院","address": ""},
    {"value": "晋城宏圣建筑工程有限公司医务所","address": ""},
    {"value": "云冈瑞婕皮肤病门诊部","address": ""},
    {"value": "中化二建集团医院","address": ""},
    {"value": "长治医学院附属和平医院","address": ""},
    {"value": "太原钢铁（集团）有限公司总医院（山西医科大学第六医院、山西医科大学第六临床医学院）","address": ""},
    {"value": "西山煤电（集团）有限责任公司职业病防治所汾河门诊部","address": ""},
    {"value": "山西益康脉管炎医院","address": ""},
    {"value": "山西省针灸医院（山西中医药大学附属针灸推拿医院）","address": ""},
    {"value": "山西省一○九医院","address": ""},
    {"value": "山西省肿瘤医院（山西省第三人民医院、山西医科大学附属肿瘤医院、山西省癌症中心）","address": ""},
    {"value": "山西省煤炭中心医院","address": ""},
    {"value": "山西省中西医结合医院（山西中医药大学附属中西医结合医院、太原铁路中心医院）","address": ""},
    {"value": "通用环球中铁太原医院","address": ""},
    {"value": "太原北方医院","address": ""},
    {"value": "山西省中医药研究院（山西省中医院）","address": ""},
    {"value": "山西省心血管病医院（山西省心血管病研究所）","address": ""},
    {"value": "山西省荣军医院（山西综合康复医院）","address": ""},
    {"value": "山西省社会福利精神康宁医院（山西省康宁医院）","address": ""},
    {"value": "山西医科大学第二医院（山西医科大学第二临床医学院）","address": ""},
    {"value": "山西白求恩医院（山西医学科学院）","address": ""},
    {"value": "山西省长治市潞州区马厂镇卫生院","address": ""},
    {"value": "太原市迎泽区文庙社区卫生服务站","address": ""},
    {"value": "太原市妇幼保健计划生育服务中心（太原市妇幼保健院、太原市儿童医院、太原市第六人民医院）","address": ""},
    {"value": "太原九州中西医结合皮肤病医院","address": ""},
    {"value": "山西和济肾脏病医院","address": ""},
    {"value": "太原市小店区锦绣苑社区卫生服务站","address": ""},
    {"value": "太原市第七人民医院","address": ""},
    {"value": "太原市小店区八一社区卫生服务站","address": ""},
    {"value": "太原康明眼科门诊部","address": ""},
    {"value": "太原泽亿风湿糖尿病医院","address": ""},
    {"value": "太原市小店区易城社区卫生服务站","address": ""},
    {"value": "太原市中心医院","address": ""},
    {"value": "太原市迎泽区新建南路第三社区卫生服务站","address": ""},
    {"value": "太原市迎泽区宁化府社区卫生服务站","address": ""},
    {"value": "山西肛泰肛肠医院","address": ""},
    {"value": "太原市精神病医院（太原市第五人民医院、山西医科大学附属精神卫生医院、山西医科大学精神卫生学院）","address": ""},
    {"value": "太原显微手外科医院有限公司","address": ""},
    {"value": "振华南街绿园社区卫生服务站","address": ""},
    {"value": "太原东辉综合门诊部","address": ""},
    {"value": "忻州手足创伤骨科医院","address": ""},
    {"value": "浑源县医疗集团沙圪坨镇卫生院","address": ""},
    {"value": "神池县人民医院","address": ""},
    {"value": "山西臻享健康管理咨询有限公司平阳北街综合门诊部","address": ""},
    {"value": "临猗县楚侯乡卫生院（临猗县医疗集团楚侯乡卫生院）","address": ""},
    {"value": "太原市尖草坪区兴华苑社区卫生服务站","address": ""},
    {"value": "太原市万柏林区精神病医院","address": ""},
    {"value": "孝义康明眼科医院","address": ""},
    {"value": "吕梁市离石区医疗集团莲花池街道办社区卫生服务中心","address": ""},
    {"value": "长治市屯留区丰宜镇卫生院西流寨分院","address": ""},
    {"value": "榆次区路西街道社区卫生服务中心","address": ""},
    {"value": "绛县开发区润月牙科","address": ""},
    {"value": "国药同煤二医院","address": ""},
    {"value": "国药同煤老年病医院","address": ""},
    {"value": "国药同煤结核病防治院","address": ""},
    {"value": "国药同煤肿瘤医院","address": ""},
    {"value": "长治市屯留区仁和医院","address": ""},
    {"value": "运城市盐湖区南城办事处马家窑社区卫生服务站","address": ""},
    {"value": "阳泉煤业（集团）有限责任公司职业病防治院","address": ""},
    {"value": "中铁十二局集团第三工程有限公司机关门诊部","address": ""},
    {"value": "太原康明眼科医院有限公司","address": ""},
    {"value": "太原义丰口腔门诊有限公司小店翡翠华庭口腔门诊部","address": ""},
    {"value": "忻州市和平街社区卫生服务中心","address": ""},
    {"value": "临县凤城社区卫生服务站","address": ""},
    {"value": "翼城康安福医院","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处友谊街卫生服务站","address": ""},
    {"value": "山西现代妇产医院","address": ""},
    {"value": "襄汾县大邓乡卫生院","address": ""},
    {"value": "运城市盐湖区北城办事处禹香苑社区卫生服务站","address": ""},
    {"value": "新华街锦绣花园社区卫生服务站","address": ""},
    {"value": "临汾京联外科医院","address": ""},
    {"value": "太原市晋源区义井一巷社区卫生服务站","address": ""},
    {"value": "汾阳市峪道河镇卫生院","address": ""},
    {"value": "阳泉市第一人民医院","address": ""},
    {"value": "平遥四贡医院（有限公司）","address": ""},
    {"value": "灵石县人民医院","address": ""},
    {"value": "大同市第四人民医院","address": ""},
    {"value": "临汾市尧都区南街办事处洪楼社区卫生服务站","address": ""},
    {"value": "临县医疗集团城庄镇中心卫生院","address": ""},
    {"value": "原平市轩岗镇卫生院龙宫社区卫生服务站","address": ""},
    {"value": "高平市丹河路海红盲人按摩中心","address": ""},
    {"value": "天镇县民康医院有限责任公司","address": ""},
    {"value": "云冈新悦康中西医结合医医院","address": ""},
    {"value": "山西琦磊口腔门诊有限公司尖草坪兴龙苑口腔门诊部","address": ""},
    {"value": "太原市迎泽区郝庄镇中心卫生院","address": ""},
    {"value": "昔阳县大寨中心卫生院洪水分院","address": ""},
    {"value": "大同恒善心理医院","address": ""},
    {"value": "盂县晋慷医院有限责任公司","address": ""},
    {"value": "大同市新荣区花园屯乡卫生院镇川分院","address": ""},
    {"value": "太原市尖草坪区新城社区卫生服务中心","address": ""},
    {"value": "长治市潞城区潞康生活区社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区五一路北社区卫生服务站","address": ""},
    {"value": "和顺县人民医院","address": ""},
    {"value": "太原市迎泽区南巷社区卫生服务站","address": ""},
    {"value": "黎城县妇幼保健计划生育服务中心","address": ""},
    {"value": "太原博远口腔门诊管理有限公司杏花岭北大街口腔门诊部","address": ""},
    {"value": "朔州益康中医院","address": ""},
    {"value": "太原爱尔眼科医院（有限公司）","address": ""},
    {"value": "山西贞德妇儿医院","address": ""},
    {"value": "太原矿机医院","address": ""},
    {"value": "太原市小店区开元社区卫生服务站","address": ""},
    {"value": "太原市迎泽区察院后社区卫生服务站","address": ""},
    {"value": "太原市盲人按摩医院（太原市康复医院）","address": ""},
    {"value": "太原市人民医院","address": ""},
    {"value": "太原市迎泽区桃园路四社区卫生服务站","address": ""},
    {"value": "太原五洲口腔门诊部","address": ""},
    {"value": "太原市小店区北营街道肉联社区卫生服务站","address": ""},
    {"value": "太原市第四人民医院（山西医科大学附属肺科医院、太原市结核病医院）","address": ""},
    {"value": "太原市第八人民医院（太原市老年病医院）","address": ""},
    {"value": "太原侯丽萍风湿骨病中医医院","address": ""},
    {"value": "山西长峰血管瘤医院","address": ""},
    {"value": "山西黄河医院","address": ""},
    {"value": "太原市黄寨精神病医院","address": ""},
    {"value": "山西老年康复医院","address": ""},
    {"value": "山西爱尔眼科医院","address": ""},
    {"value": "山西恒依菲尔口腔门诊有限公司太原蓝山口腔门诊部","address": ""},
    {"value": "太原市第二人民医院","address": ""},
    {"value": "太原和平医院","address": ""},
    {"value": "太原市小店区坞南社区卫生服务站","address": ""},
    {"value": "太原市小店区博泰普通专科门诊部","address": ""},
    {"value": "太原市小店区长治二社区卫生服务站","address": ""},
    {"value": "太原市小店区平北西一社区卫生服务站","address": ""},
    {"value": "太原市小店区山针社区卫生服务站","address": ""},
    {"value": "太原来康福医疗门诊部","address": ""},
    {"value": "太原市小店区国际大都会社区卫生服务站","address": ""},
    {"value": "太原市小店区长治路三社区卫生服务站","address": ""},
    {"value": "太原市小店区并南西一社区卫生服务站","address": ""},
    {"value": "太原市小店区体育二社区卫生服务站","address": ""},
    {"value": "太原市小店区南内环一社区卫生服务站","address": ""},
    {"value": "太原市小店区平阳三社区卫生服务站","address": ""},
    {"value": "太原市小店区并南三社区卫生服务站","address": ""},
    {"value": "太原市小店区南内环二社区卫生服务站","address": ""},
    {"value": "长治市潞州区太行西街街道城北东街社区卫生服务中心","address": ""},
    {"value": "长治潞州瑞波特口腔门诊部","address": ""},
    {"value": "太原尔雅口腔门诊部","address": ""},
    {"value": "长治蓝天医院","address": ""},
    {"value": "中阳县医疗集团武家庄镇卫生院张家庄分院","address": ""},
    {"value": "盂县阳光百姓药房有限公司学府门诊","address": ""},
    {"value": "灵石县坛镇乡卫生院","address": ""},
    {"value": "太原煤气化职工医院","address": ""},
    {"value": "沁水县龙港镇卫生院王寨分院","address": ""},
    {"value": "太原美亚口腔门诊部","address": ""},
    {"value": "介休市义棠镇中心卫生院西堡门诊","address": ""},
    {"value": "太原市晋源区武家庄社区卫生服务站","address": ""},
    {"value": "交口县宏康医院","address": ""},
    {"value": "中阳县医疗集团下枣林乡卫生院","address": ""},
    {"value": "左权县龙泉乡卫生院（左权县医疗集团龙泉乡卫生院）","address": ""},
    {"value": "大同市平城区飞视眼科医院有限责任公司","address": ""},
    {"value": "长治市潞州区太行东街街道社区卫生服务中心","address": ""},
    {"value": "长治市潞州区太行西街街道中天社区卫生服务站","address": ""},
    {"value": "离石区医疗集团田家会社区卫生服务中心","address": ""},
    {"value": "吕梁市离石区医疗集团滨河街道办社区卫生服务中心","address": ""},
    {"value": "太原市万柏林区王封乡卫生院","address": ""},
    {"value": "新建北路花园里社区卫生服务站","address": ""},
    {"value": "太原澳莱口腔门诊有限公司学府园区体育路口腔门诊部","address": ""},
    {"value": "晋城合聚康复医疗中心","address": ""},
    {"value": "高平市康复医院","address": ""},
    {"value": "和顺县平松乡卫生院","address": ""},
    {"value": "天镇县贾家屯乡卫生院将军庙分院","address": ""},
    {"value": "灵丘骨伤医院","address": ""},
    {"value": "交城县医疗集团岭底卫生院","address": ""},
    {"value": "岢岚县水峪贯乡卫生院","address": ""},
    {"value": "高平市洁美口腔门诊部","address": ""},
    {"value": "泽州县医疗集团大箕镇卫生院","address": ""},
    {"value": "太原市杏花岭区白桦苑社区卫生服务站","address": ""},
    {"value": "介休市北关社区卫生服务中心","address": ""},
    {"value": "静乐县鹅城镇卫生院西坡崖分院","address": ""},
    {"value": "太原弘善中医医院","address": ""},
    {"value": "吉县医疗集团柏山寺乡卫生院（吉县柏山寺乡卫生院）","address": ""},
    {"value": "吕梁市离石区滨河街道办东关社区卫生服务站","address": ""},
    {"value": "中阳县双宇杏林中医馆","address": ""},
    {"value": "清徐县柳杜乡卫生院","address": ""},
    {"value": "运城市盐湖区北城办事处御沁园社区卫生服务站","address": ""},
    {"value": "大同现代医院","address": ""},
    {"value": "晋城市城区西上庄街道办事处社区卫生服务中心","address": ""},
    {"value": "沁县南里乡中心卫生院","address": ""},
    {"value": "岢岚县医疗集团王家岔乡卫生院","address": ""},
    {"value": "太原康医堂中医门诊部","address": ""},
    {"value": "太原市小店区长治路一社区卫生服务站","address": ""},
    {"value": "太原海燕口腔门诊部有限公司","address": ""},
    {"value": "山西齿贝佳口腔门诊有限公司小店旺景家园门诊部","address": ""},
    {"value": "太原市小店区大营盘社区卫生服务站","address": ""},
    {"value": "太原市小店区黄陵社区卫生服务中心（太原市小店区黄陵妇幼保健计划生育服务站）","address": ""},
    {"value": "太原市小店区人民医院","address": ""},
    {"value": "太原市小店区小店社区卫生服务中心（太原市小店区小店妇幼保健计划生育服务站）","address": ""},
    {"value": "太原市小店区巩家堡社区卫生服务站","address": ""},
    {"value": "太原市小店区滨东社区卫生服务站","address": ""},
    {"value": "太原市小店区并南西二社区卫生服务站","address": ""},
    {"value": "太原市小店区北营社区卫生服务中心（太原市小店区北营妇幼保健计划生育服务站）","address": ""},
    {"value": "太原良医堂中医专科门诊部","address": ""},
    {"value": "太原市小店区南环社区卫生服务站","address": ""},
    {"value": "太原癫痫病医院","address": ""},
    {"value": "大同市瑞和门诊部","address": ""},
    {"value": "太原市迎泽区枣园社区卫生服务站","address": ""},
    {"value": "长治市潞州区太行西街街道社区卫生服务中心","address": ""},
    {"value": "汾阳市三泉中心卫生院","address": ""},
    {"value": "保德县东关镇卫生院","address": ""},
    {"value": "万荣县中医医院","address": ""},
    {"value": "太原西山杜儿坪医院","address": ""},
    {"value": "沁水县康复医疗中心","address": ""},
    {"value": "五台县耿镇镇卫生院","address": ""},
    {"value": "忻州市忻府区合索乡卫生院","address": ""},
    {"value": "浮山县北王镇中心卫生院北韩服务站","address": ""},
    {"value": "侯马市中医院","address": ""},
    {"value": "襄汾县南辛店乡卫生院贾罕分院","address": ""},
    {"value": "襄汾县汾城镇卫生院","address": ""},
    {"value": "新绛县泽掌镇卫生院（新绛县医疗集团泽掌镇卫生院）","address": ""},
    {"value": "晋中榆次恒康口腔门诊部（有限公司）","address": ""},
    {"value": "太原康定精神病医院有限公司","address": ""},
    {"value": "七府园社区卫生服务站","address": ""},
    {"value": "北大医疗潞安医院高河能源医务所","address": ""},
    {"value": "夏县妇幼保健计划生育服务中心（夏县妇幼保健院、夏县医疗集团妇幼保健院）","address": ""},
    {"value": "大同市云冈区高山镇卫生院","address": ""},
    {"value": "榆次区新华街道社区卫生服务中心","address": ""},
    {"value": "阳城县白桑镇卫生院","address": ""},
    {"value": "盐湖仁医堂中医馆","address": ""},
    {"value": "平定县娘子关镇卫生院","address": ""},
    {"value": "阳泉三鹤血液透析中心","address": ""},
    {"value": "古交市医疗集团镇城底镇卫生院","address": ""},
    {"value": "山西兰花煤炭实业集团有限公司伯方煤矿医务室","address": ""},
    {"value": "长治市安神专科医院","address": ""},
    {"value": "朔州市平鲁区凤凰城镇卫生院（朔州市平鲁区凤凰城镇卫生院）","address": ""},
    {"value": "昔阳县李家庄乡卫生院","address": ""},
    {"value": "襄汾泽华医院","address": ""},
    {"value": "双塔北路社区卫生服务站","address": ""},
    {"value": "古交市岔口乡卫生院（古交市医疗集团岔口乡卫生院）","address": ""},
    {"value": "太原市迎泽区王家峰社区卫生服务站","address": ""},
    {"value": "壶关县医疗集团石河沐分院","address": ""},
    {"value": "怀仁坪峪医院","address": ""},
    {"value": "晋中市榆次区西南街道城隍庙社区卫生服务站","address": ""},
    {"value": "应县医疗集团大黄巍乡卫生院","address": ""},
    {"value": "运城安誉医院","address": ""},
    {"value": "朔州市中医精神病医院","address": ""},
    {"value": "长治市潞州区太行西街街道省建社区卫生服务站","address": ""},
    {"value": "太原诺雅口腔门诊部","address": ""},
    {"value": "古交市桃园街道办事处福康苑社区卫生服务站","address": ""},
    {"value": "云冈区和顺泰吉社区卫生服务中心","address": ""},
    {"value": "太原市小店区长治路社区卫生服务站","address": ""},
    {"value": "太原市小店区嘉节社区卫生服务站","address": ""},
    {"value": "太原市小店区西吴社区卫生服务站","address": ""},
    {"value": "太原白癜风医院","address": ""},
    {"value": "太原市小店区平阳一社区卫生服务站","address": ""},
    {"value": "山西国信医疗服务有限公司小店国信嘉园综合门诊部","address": ""},
    {"value": "山西仁爱中医骨病医院","address": ""},
    {"value": "太原龙城中医白癜风医院","address": ""},
    {"value": "太原市迎泽区并州东街四社区卫生服务站","address": ""},
    {"value": "太原牙乐康口腔门诊部","address": ""},
    {"value": "太原市迎泽区双塔寺街三社区卫生服务站","address": ""},
    {"value": "太原可睦口腔门诊部","address": ""},
    {"value": "太原市迎泽区桃园路一社区卫生服务站","address": ""},
    {"value": "太原市迎泽区桥东北社区卫生服务站","address": ""},
    {"value": "太原市迎泽区并州东街三社区卫生服务站","address": ""},
    {"value": "太原市迎泽区庙前社区卫生服务中心","address": ""},
    {"value": "太原市迎泽区老军营社区卫生服务中心","address": ""},
    {"value": "太原市迎泽区新建路二社区卫生服务站","address": ""},
    {"value": "太原老战士中西医门诊部","address": ""},
    {"value": "太原精益齿科门诊部","address": ""},
    {"value": "阳泉市城区山东铝业阳泉矿社区卫生服务站","address": ""},
    {"value": "清徐县集义乡中心卫生院","address": ""},
    {"value": "阳泉慧康达医院","address": ""},
    {"value": "阳城县河北镇卫生院驾岭分院","address": ""},
    {"value": "太原简康口腔门诊有限公司杏花岭富康苑口腔门诊部","address": ""},
    {"value": "太原市小店区许坦二社区卫生服务站","address": ""},
    {"value": "沁源县医疗集团赤石桥乡卫生院","address": ""},
    {"value": "长治市潞州区紫金街道八一社区卫生服务站","address": ""},
    {"value": "太原拜尔口腔门诊部","address": ""},
    {"value": "太原市晋源区金胜社区卫生服务中心","address": ""},
    {"value": "大同市云冈区中医医院","address": ""},
    {"value": "山西省运城市中心医院","address": ""},
    {"value": "偏关东升医院","address": ""},
    {"value": "晋中市太谷区明星镇卫生院","address": ""},
    {"value": "五台县阳白乡卫生院红表社区服务站","address": ""},
    {"value": "孝义瑞康医院","address": ""},
    {"value": "泽州县惠民百姓医院有限公司惠民百姓医院","address": ""},
    {"value": "五台县医疗集团建安镇卫生院神西分院","address": ""},
    {"value": "阳城县町店镇卫生院","address": ""},
    {"value": "灵石仁康医院","address": ""},
    {"value": "吕梁仁济医院有限公司","address": ""},
    {"value": "盐湖优格口腔门诊部","address": ""},
    {"value": "阳城县城朝仁口腔门诊部","address": ""},
    {"value": "天镇县张西河乡卫生院","address": ""},
    {"value": "怀仁市医疗集团海北头卫生院","address": ""},
    {"value": "晋中鼎新口腔门诊部有限公司","address": ""},
    {"value": "河曲县旧县乡卫生院","address": ""},
    {"value": "张志强口腔门诊部","address": ""},
    {"value": "永济市杏北社区卫生服务站","address": ""},
    {"value": "大同玄济中西医结合医院","address": ""},
    {"value": "太原市杏花岭区大北门东社区卫生服务站","address": ""},
    {"value": "太原英才口腔门诊管理有限公司万柏林西山口腔门诊部","address": ""},
    {"value": "中阳县金罗镇卫生院","address": ""},
    {"value": "山西省眼科医院","address": ""},
    {"value": "太原糖尿病专科医院","address": ""},
    {"value": "大同市平城区文瀛湖街道卫生院","address": ""},
    {"value": "长治市潞州区西白兔乡卫生院","address": ""},
    {"value": "吉县医疗集团中垛乡卫生院（吉县中垛乡卫生院、吉县中垛乡中心卫生院）","address": ""},
    {"value": "大同济康中医医院","address": ""},
    {"value": "洪洞善旺齿科医院","address": ""},
    {"value": "介休市妇幼保健计划生育服务中心","address": ""},
    {"value": "河曲县鹿固乡卫生院","address": ""},
    {"value": "北大医疗潞安医院司马医务室","address": ""},
    {"value": "大同泰寿康医院有限公司","address": ""},
    {"value": "朔州仁济和中西医结合医院","address": ""},
    {"value": "故县镇南仁卫生二所","address": ""},
    {"value": "偏关县医疗集团老牛湾镇卫生院","address": ""},
    {"value": "太原恒伦金刚里口腔门诊部有限公司","address": ""},
    {"value": "山西中条山集团总医院","address": ""},
    {"value": "太原市迎泽区中医医院","address": ""},
    {"value": "太原市迎泽区骨伤科医院","address": ""},
    {"value": "山西夕阳红门诊部","address": ""},
    {"value": "山西恒依菲尔口腔门诊有限公司太原菜园口腔门诊部","address": ""},
    {"value": "山西广聚德中医药有限公司太原恒福堂中医门诊部","address": ""},
    {"value": "太原市杏花岭区杨家峪社区卫生服务中心","address": ""},
    {"value": "太原长城外科医院","address": ""},
    {"value": "太原市杏花岭区胜利桥东社区卫生服务站","address": ""},
    {"value": "太原立世达特口腔门诊管理有限公司杏花岭万科紫院门诊部","address": ""},
    {"value": "太原市杏花岭区五龙口社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区北大街西社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区医疗集团/太原市杏花岭区医疗集团中心医院/太原市杏花岭区中心医院","address": ""},
    {"value": "太原市杏花岭区杏花岭社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区杏花岭社区卫生服务中心","address": ""},
    {"value": "太原市杏花岭区三桥社区卫生服务中心","address": ""},
    {"value": "太原市杏花岭区中医医院","address": ""},
    {"value": "太原市口腔医院","address": ""},
    {"value": "太原市杏花岭区建设北路南社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区三桥社区卫生服务站","address": ""},
    {"value": "太原惠民康门诊部","address": ""},
    {"value": "太原市杏花岭区坝陵桥社区卫生服务中心","address": ""},
    {"value": "太原市杏花岭区新源里南社区卫生服务站","address": ""},
    {"value": "长治市潞州区太行东街街道容海社区卫生服务站","address": ""},
    {"value": "临汾段氏正骨微创医院","address": ""},
    {"value": "清徐县徐沟镇中心卫生院高花分院","address": ""},
    {"value": "晋城市宏恩口腔有限公司","address": ""},
    {"value": "太原恒伦茂业口腔门诊部","address": ""},
    {"value": "大同市平城区普仁中西医结合医院有限责任公司","address": ""},
    {"value": "沁源县景凤镇卫生院","address": ""},
    {"value": "太原微笑齿科有限公司恒山路口腔门诊部","address": ""},
    {"value": "沁县定昌镇西苑社区卫生服务站","address": ""},
    {"value": "平遥县计划生育妇幼保健服务中心","address": ""},
    {"value": "闻喜仁安医院","address": ""},
    {"value": "太谷县白塔社区卫生服务中心明星服务站","address": ""},
    {"value": "壶关县龙泉镇城南社区东卫生服务站","address": ""},
    {"value": "忻州和平口腔医院","address": ""},
    {"value": "临汾市尧都区水塔街办事处社区卫生服务中心","address": ""},
    {"value": "襄垣县妇幼保健计划生育服务中心","address": ""},
    {"value": "河津上康精神病医院","address": ""},
    {"value": "太原硕和医疗科技有限公司双塔西街口腔门诊部","address": ""},
    {"value": "侯马市学府路社区卫生服务站","address": ""},
    {"value": "临汾市尧都区医疗集团土门镇卫生院西头分院","address": ""},
    {"value": "临猗县牛杜镇卫生院（临猗县医疗集团牛杜镇卫生院）","address": ""},
    {"value": "吕梁正健微创疼痛医院","address": ""},
    {"value": "临汾博荣心脑病专科医院","address": ""},
    {"value": "怀仁市云中社区卫生服务中心","address": ""},
    {"value": "云冈区口泉社区卫生服务中心","address": ""},
    {"value": "山西金驹煤电化有限责任公司医务所","address": ""},
    {"value": "阳泉市郊区医疗集团荫营分院（阳泉市郊区荫营镇卫生院）","address": ""},
    {"value": "运城市盐湖区安邑办事处黄金水岸社区卫生服务站","address": ""},
    {"value": "长子惠民医院","address": ""},
    {"value": "临猗县楚侯乡卫生院李汉分院","address": ""},
    {"value": "晋城半边天妇科医院","address": ""},
    {"value": "大同市云州区聚乐乡卫生院阁老山社区卫生服务站","address": ""},
    {"value": "晋城市城区北石店矿区街道办事处社区卫生服务中心","address": ""},
    {"value": "芮城县大王镇卫生院","address": ""},
    {"value": "大同新城老年病医院","address": ""},
    {"value": "太原康士口腔医疗有限责任公司杏花岭东峰口腔门诊部","address": ""},
    {"value": "文水县南安镇卫生院南白分院","address": ""},
    {"value": "吕梁御康堂中医院","address": ""},
    {"value": "临县康承中医院","address": ""},
    {"value": "吕梁市卫生学校附属医院","address": ""},
    {"value": "高平市北诗镇卫生院（高平市人民医院北诗分院）","address": ""},
    {"value": "灵丘县赵北乡卫生院","address": ""},
    {"value": "陵川县潞城镇中心卫生院","address": ""},
    {"value": "太原市万柏林区长风北社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区柳溪街社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区白龙庙社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区金刚里社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区北大街东社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区东华苑社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区小东关社区卫生服务站","address": ""},
    {"value": "太原市尖草坪区阳曲镇卫生院（太原市尖草坪区医疗集团阳曲镇卫生院）","address": ""},
    {"value": "太原市尖草坪区柏板乡卫生院（太原市尖草坪区医疗集团柏板乡卫生院）","address": ""},
    {"value": "太原市尖草坪区赵庄社区卫生服务站","address": ""},
    {"value": "太原正德仁中医门诊部","address": ""},
    {"value": "太原市尖草坪区优山美郡社区卫生服务站","address": ""},
    {"value": "太原市尖草坪区马头水乡卫生院（太原市尖草坪区医疗集团马头水乡卫生院）","address": ""},
    {"value": "太原小苹果口腔门诊部","address": ""},
    {"value": "太原市尖草坪区汇丰社区卫生服务中心","address": ""},
    {"value": "太原市尖草坪区古城社区卫生服务中心","address": ""},
    {"value": "太原市尖草坪区利民社区卫生服务站","address": ""},
    {"value": "太原市尖草坪区骨科医院","address": ""},
    {"value": "太原市万柏林区丽华苑社区卫生服务站","address": ""},
    {"value": "太原市万柏林区众纺路社区卫生服务站","address": ""},
    {"value": "太原市万柏林区光华社区卫生服务站","address": ""},
    {"value": "太原市万柏林区滨汾苑社区卫生服务站","address": ""},
    {"value": "太原市万柏林区迎泽南社区卫生服务站","address": ""},
    {"value": "太原市万柏林区南内环西社区卫生服务站","address": ""},
    {"value": "太原市万柏林区荔梅社区卫生服务站","address": ""},
    {"value": "太原市万柏林区兴汾苑社区卫生服务站","address": ""},
    {"value": "太原市万柏林区建机社区卫生服务站","address": ""},
    {"value": "太原市万柏林区兴华南小区竹杏社区卫生服务站","address": ""},
    {"value": "太原市万柏林区玉河社区卫生服务站","address": ""},
    {"value": "太原市万柏林区晋祠路社区卫生服务站","address": ""},
    {"value": "太原市万柏林区漪汾苑社区卫生服务站","address": ""},
    {"value": "太原市万柏林区和平苑社区卫生服务站","address": ""},
    {"value": "太原市万柏林区西线东社区卫生服务站","address": ""},
    {"value": "太原市万柏林区永乐苑社区卫生服务站","address": ""},
    {"value": "太原市万柏林区千峰北路社区卫生服务站","address": ""},
    {"value": "太原市万柏林区北二条社区卫生服务站","address": ""},
    {"value": "太原同颜康中医门诊部","address": ""},
    {"value": "太原市万柏林区医疗集团（太原市万柏林区医疗集团中心医院、太原市万柏林区中心医院）","address": ""},
    {"value": "太原市万柏林区电石苑社区卫生服务站","address": ""},
    {"value": "太原市万柏林区兴华街办滨河社区卫生服务站","address": ""},
    {"value": "太原市万柏林区新建社区卫生服务站","address": ""},
    {"value": "忻州市忻府区长航医院","address": ""},
    {"value": "昔阳县中医院","address": ""},
    {"value": "介休众康门诊部","address": ""},
    {"value": "山西众植齿科医院有限公司小店晋阳街口腔门诊部","address": ""},
    {"value": "太原恒伦悦伦口腔医院有限公司","address": ""},
    {"value": "太原昊通晟达血液透析中心","address": ""},
    {"value": "太原锦丰奕宸华悦口腔医疗有限公司万柏林润景园著口腔门诊部","address": ""},
    {"value": "长治市上党区光明北路社区卫生服务站","address": ""},
    {"value": "永济福茂骨科专科医院","address": ""},
    {"value": "大同市云冈区云冈镇卫生院","address": ""},
    {"value": "太原硕和医疗科技有限公司双塔西街口腔门诊部","address": ""},
    {"value": "太原市尖草坪区滨河苑社区卫生服务站","address": ""},
    {"value": "万荣县里望乡卫生院","address": ""},
    {"value": "太原长江中医门诊部有限公司","address": ""},
    {"value": "长子县色头镇卫生院（长子县医疗集团色头镇卫生院）","address": ""},
    {"value": "蒲县医疗集团薛关镇卫生院","address": ""},
    {"value": "霍州治普医院","address": ""},
    {"value": "太原玛丽妇科医院有限公司","address": ""},
    {"value": "夏县裴介镇卫生院","address": ""},
    {"value": "大同市第一人民医院","address": ""},
    {"value": "长治市潞州区英雄南路街道梅辉坡社区卫生服务北站","address": ""},
    {"value": "山西转型综改示范区唐槐园区俐康综合门诊有限公司","address": ""},
    {"value": "长治市潞州区太行东街街道文卫社区卫生服务站","address": ""},
    {"value": "长征街办事处社区卫生服务中心","address": ""},
    {"value": "朔州市朔城区平朔社区卫生服务站","address": ""},
    {"value": "五台县第一人民医院","address": ""},
    {"value": "晋城华肤皮肤病专科医院","address": ""},
    {"value": "高平长平中西医结合医院","address": ""},
    {"value": "汾阳市安康医院","address": ""},
    {"value": "临县白文镇中心卫生院曜头社区卫生服务站","address": ""},
    {"value": "忻州秀容心脑血管病医院","address": ""},
    {"value": "阳泉市矿区医疗集团（阳泉市矿区医院）段南沟社区卫生服务站、阳泉市矿区段南沟社区卫生服务站","address": ""},
    {"value": "保德县土崖塔乡卫生院","address": ""},
    {"value": "太原市小店区康宁社区卫生服务站","address": ""},
    {"value": "太原市万柏林区官地社区卫生服务站","address": ""},
    {"value": "太原高新区诺贝尔口腔门诊部","address": ""},
    {"value": "吕梁市离石区妇幼保健计划生育服务中心","address": ""},
    {"value": "霍州市医疗集团开元社区卫生服务中心","address": ""},
    {"value": "盂县医疗集团南娄镇卫生院下曹分院","address": ""},
    {"value": "长治市上党区长安社区卫生服务站","address": ""},
    {"value": "晋中市委市政府机关大院医疗服务中心","address": ""},
    {"value": "定襄县医疗集团南王乡中心卫生院","address": ""},
    {"value": "运城京华肾病医院","address": ""},
    {"value": "太原医德医疗服务有限公司杏花岭同和仁门诊部","address": ""},
    {"value": "云岗区新胜育新路社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区桃园北路东社区卫生服务站","address": ""},
    {"value": "太原小店博凡口腔门诊部","address": ""},
    {"value": "太原市尖草坪区龙康新苑社区卫生服务站","address": ""},
    {"value": "兴县妇幼保健和计划生育服务中心","address": ""},
    {"value": "太原市小店区体育路社区卫生服务站","address": ""},
    {"value": "临汾康乐富康复医院有限责任公司","address": ""},
    {"value": "稷山康宁护理院","address": ""},
    {"value": "武乡县妇幼保健计划生育服务中心","address": ""},
    {"value": "运城和济康复医院","address": ""},
    {"value": "宁武县医疗集团凤凰镇卫生院滨河社区卫生服务站","address": ""},
    {"value": "临猗县人民医院","address": ""},
    {"value": "太原市万柏林区西岸社区卫生服务站","address": ""},
    {"value": "太原市万柏林区西华苑东社区卫生服务站","address": ""},
    {"value": "太原市万柏林区公园路社区卫生服务站","address": ""},
    {"value": "太原兴华妇幼专科门诊部","address": ""},
    {"value": "太原市万柏林区三益社区卫生服务站","address": ""},
    {"value": "太原市万柏林区迎泽北社区卫生服务站","address": ""},
    {"value": "太原纺织职工医院","address": ""},
    {"value": "太原市万柏林区西矿南社区卫生服务站","address": ""},
    {"value": "太原市万柏林区玉河北社区卫生服务站","address": ""},
    {"value": "太原市万柏林区千峰社区卫生服务中心","address": ""},
    {"value": "太原市万柏林区和平南路社区卫生服务站","address": ""},
    {"value": "太原市万柏林区新友谊社区卫生服务站","address": ""},
    {"value": "太原市万柏林区安广社区卫生服务站","address": ""},
    {"value": "太原市万柏林区东社社区卫生服务中心","address": ""},
    {"value": "太原市晋源区格林社区卫生服务站","address": ""},
    {"value": "太原市晋源区华都社区卫生服务站","address": ""},
    {"value": "太原市晋源区龙滩社区卫生服务站","address": ""},
    {"value": "太原市晋源区圣湖社区卫生服务站","address": ""},
    {"value": "清徐县第二人民医院","address": ""},
    {"value": "阳泉城南皮肤病医院","address": ""},
    {"value": "闻喜县后宫乡卫生院白石分院","address": ""},
    {"value": "五寨县砚城镇中心卫生院","address": ""},
    {"value": "芮城县永乐社区卫生服务站","address": ""},
    {"value": "娄烦县静游镇卫生院","address": ""},
    {"value": "平遥博健中医肛肠医院","address": ""},
    {"value": "太原恒伦口腔门诊管理有限公司万柏林千峰门诊部","address": ""},
    {"value": "阳泉市郊区医疗集团（阳泉市第二人民医院，阳泉市郊区人民医院，阳泉市郊区医疗集团人民医院）","address": ""},
    {"value": "五寨县孙家坪乡卫生院梁家坪分院","address": ""},
    {"value": "临猗康福精神病医院","address": ""},
    {"value": "山阴县医疗集团黑圪塔分院","address": ""},
    {"value": "晋城市康复医院","address": ""},
    {"value": "稷山同信骨科医院","address": ""},
    {"value": "广灵县医疗集团作疃镇卫生院","address": ""},
    {"value": "晋城市城区西街街道办事处汇晋社区卫生服务站","address": ""},
    {"value": "汾阳市栗家庄镇卫生院","address": ""},
    {"value": "翼城县里砦中心卫生院（翼城县医疗集团里砦中心卫生院、里砦镇妇幼保健计划生育服务站）","address": ""},
    {"value": "晋城市城区钟家庄街道办事处钟家庄社区卫生服务站","address": ""},
    {"value": "河曲县人民医院","address": ""},
    {"value": "山西汾西矿业集团水峪煤业有限责任公司综合门诊部","address": ""},
    {"value": "清徐县马峪乡卫生院","address": ""},
    {"value": "阳城惠泽口腔医院","address": ""},
    {"value": "岚县惠民医院有限公司","address": ""},
    {"value": "昔阳县赵壁中心卫生院凤居分院","address": ""},
    {"value": "长治市潞州区太行西街街道捉马社区卫生服务站","address": ""},
    {"value": "繁峙县砂河恩济医院","address": ""},
    {"value": "大同守佳消化病医院有限责任公司","address": ""},
    {"value": "太原市万柏林区化客头社区卫生服务中心","address": ""},
    {"value": "洪洞嘉和洪健医院","address": ""},
    {"value": "临汾康源血液净化中心有限公司","address": ""},
    {"value": "山西太原白疯风医院","address": ""},
    {"value": "河曲县土沟乡卫生院","address": ""},
    {"value": "太原恒伦柳巷口腔门诊部有限公司","address": ""},
    {"value": "中阳县医疗集团宁乡镇卫生院","address": ""},
    {"value": "永济华尔口腔门诊部","address": ""},
    {"value": "垣曲县毛家湾镇卫生院","address": ""},
    {"value": "吕梁康明眼科医院","address": ""},
    {"value": "山阴县医疗集团泥河分院","address": ""},
    {"value": "汾阳市肖家庄中心卫生院","address": ""},
    {"value": "沁水县胡底乡卫生院","address": ""},
    {"value": "临汾圣云创骨科医院","address": ""},
    {"value": "阳高县罗文皂镇卫生院","address": ""},
    {"value": "朔州安定精神病医院有限公司","address": ""},
    {"value": "阳曲县大盂镇卫生院（阳曲县医疗集团大盂镇卫生院）","address": ""},
    {"value": "阳曲县杨兴乡卫生院（阳曲县医疗集团杨兴乡卫生院）","address": ""},
    {"value": "阳曲县泥屯镇卫生院（阳曲县医疗集团泥屯镇卫生院）","address": ""},
    {"value": "阳曲县黄寨镇卫生院（阳曲县医疗集团黄寨镇卫生院）","address": ""},
    {"value": "娄烦县天池店乡卫生院","address": ""},
    {"value": "娄烦县马家庄乡卫生院（娄烦县医疗集团马家庄乡卫生院）","address": ""},
    {"value": "娄烦县医疗集团（娄烦县医疗集团人民医院、娄烦县人民医院）","address": ""},
    {"value": "娄烦县娄烦镇卫生院（娄烦县医疗集团娄烦镇卫生院）","address": ""},
    {"value": "娄烦县庙湾乡卫生院","address": ""},
    {"value": "临猗县三管镇卫生院","address": ""},
    {"value": "古交市东曲社区卫生服务中心","address": ""},
    {"value": "古交市中医医院","address": ""},
    {"value": "古交市桃园社区卫生服务中心","address": ""},
    {"value": "北关太阳城社区卫生服务站","address": ""},
    {"value": "北关叠翠园社区卫生服务站","address": ""},
    {"value": "南关凯德世家社区卫生服务站","address": ""},
    {"value": "大庆路大庆西路社区卫生服务站","address": ""},
    {"value": "北关绿洲花城社区卫生服务站","address": ""},
    {"value": "北关操场城社区卫生服务站","address": ""},
    {"value": "开源街延和路社区卫生服务站","address": ""},
    {"value": "北关北辰西苑社区卫生服务站","address": ""},
    {"value": "西街宁馨社区卫生服务站","address": ""},
    {"value": "南关御馨花都社区卫生服务站","address": ""},
    {"value": "大同通济中西医结合医院","address": ""},
    {"value": "新建南路振兴街社区卫生服务站","address": ""},
    {"value": "大同晋河中西医结合医院有限公司","address": ""},
    {"value": "大同市平城区鹿苑街道社区卫生服务中心（大同市中西医结合医院）","address": ""},
    {"value": "大同景慈中医医院","address": ""},
    {"value": "大同马应龙肛肠医院","address": ""},
    {"value": "大同市平城区清远街道社区卫生服务中心","address": ""},
    {"value": "大同美源口腔医院有限公司","address": ""},
    {"value": "大同建国综合门诊部","address": ""},
    {"value": "平城区新建南路金地福苑社区卫生服务站","address": ""},
    {"value": "太原利民普通专科门诊部","address": ""},
    {"value": "阳城县润城镇中心卫生院","address": ""},
    {"value": "应县医疗集团南泉乡卫生院","address": ""},
    {"value": "朔州市朔城区医疗集团贾庄乡卫生院","address": ""},
    {"value": "临县妇幼保健计划生育服务中心","address": ""},
    {"value": "太原爱德口腔门诊部有限公司","address": ""},
    {"value": "夏县南大里乡卫生院","address": ""},
    {"value": "新华街岳秀园社区卫生服务站","address": ""},
    {"value": "大同新世纪中医医院","address": ""},
    {"value": "南街福兴园社区卫生服务站","address": ""},
    {"value": "大同华星综合门诊部","address": ""},
    {"value": "城区向阳里新云中商城社区卫生服务站","address": ""},
    {"value": "新建北路大齿社区卫生服务站","address": ""},
    {"value": "向阳里向阳街社区卫生服务站","address": ""},
    {"value": "大同同康医院（普通合伙）","address": ""},
    {"value": "阳泉颐和堂中西医结合门诊部","address": ""},
    {"value": "高平残联康复医院","address": ""},
    {"value": "隰县龙泉镇雅美口腔门诊部","address": ""},
    {"value": "浮山县响水河镇中心卫生院","address": ""},
    {"value": "太原达美门诊管理有限公司迎泽桃南专科门诊部","address": ""},
    {"value": "太原华晋医院","address": ""},
    {"value": "保德县冯家川乡卫生院","address": ""},
    {"value": "朔州市经济开发区招远路社区卫生服务站","address": ""},
    {"value": "晋城市城区南街街道办事处西巷社区卫生服务站","address": ""},
    {"value": "太原市尖草坪区建工街第一社区卫生服务站","address": ""},
    {"value": "霍州三春馆中医门诊部","address": ""},
    {"value": "太原博冠口腔门诊部","address": ""},
    {"value": "山西省阳泉荫营煤业有限责任公司医院（山西省阳泉第一监狱医院）","address": ""},
    {"value": "北大医疗潞安医院","address": ""},
    {"value": "太原美年大健康双东门诊部有限公司","address": ""},
    {"value": "榆次区安宁街道社区卫生服务中心","address": ""},
    {"value": "朔州市朔城区北城街道办事处天和美域社区卫生服务站","address": ""},
    {"value": "平定县冶西镇卫生院（平定县医疗集团冶西镇分院）","address": ""},
    {"value": "交城天泽医院","address": ""},
    {"value": "平定县东升花园社区卫生服务站","address": ""},
    {"value": "云冈区新平旺社区卫生服务中心","address": ""},
    {"value": "怀仁市医疗集团金沙滩卫生院","address": ""},
    {"value": "吕梁正元创伤外科医院","address": ""},
    {"value": "长治市志强口腔医疗有限公司","address": ""},
    {"value": "定襄晋康医院","address": ""},
    {"value": "洪洞安康医院","address": ""},
    {"value": "太原恒伦富力城口腔门诊部","address": ""},
    {"value": "城区东街御河九号社区卫生服务站","address": ""},
    {"value": "大同市华夏东关门诊部","address": ""},
    {"value": "大同市平城区武定街道西马路社区卫生服务站","address": ""},
    {"value": "大同市平城区天俊门诊部","address": ""},
    {"value": "大同华康显微手外科医院","address": ""},
    {"value": "大同华瑞综合门诊部","address": ""},
    {"value": "南关兴国寺社区卫生服务站","address": ""},
    {"value": "大同市城区柳航门诊部","address": ""},
    {"value": "大同市城区华博门诊部","address": ""},
    {"value": "大同市城区同泰门诊部","address": ""},
    {"value": "临汾市尧都区南街办事处秦蜀社区卫生服务站","address": ""},
    {"value": "大同泽霖骨伤专科医院","address": ""},
    {"value": "东街财富官邸社区卫生服务站","address": ""},
    {"value": "大同市平城区文瀛湖街道御昌佳园社区卫生服务站","address": ""},
    {"value": "大同市平城区医疗集团（大同市平城区开源街社区卫生服务中心、大同市平城区医疗集团开源街社区卫生服务中心）","address": ""},
    {"value": "大同皮肤病医院","address": ""},
    {"value": "山西省太原市迎泽区老军营小区第二社区卫生服务站","address": ""},
    {"value": "云冈区新胜海驿社区卫生服务站","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处晨光花都社区卫生服务站","address": ""},
    {"value": "大同市凝德中医院有限公司","address": ""},
    {"value": "云冈区新胜平易街社区卫生服务站","address": ""},
    {"value": "原平九贞医院有限公司","address": ""},
    {"value": "大同市平城区双通综合门诊部有限责任公司","address": ""},
    {"value": "大同马龙综合门诊部","address": ""},
    {"value": "大同市平城区康顺门诊部","address": ""},
    {"value": "大同市平城区永泰街道红旗北街社区卫生服务站","address": ""},
    {"value": "大同市平城区马军营街道卫生院","address": ""},
    {"value": "侯马平安医院","address": ""},
    {"value": "山西省脑瘫康复医院","address": ""},
    {"value": "太原市万柏林区广圣堂中医门诊部","address": ""},
    {"value": "中阳县万丰欣医院","address": ""},
    {"value": "大同厚德精神病医院","address": ""},
    {"value": "河津明仁眼科","address": ""},
    {"value": "太原市万柏林区义井西社区卫生服务站","address": ""},
    {"value": "泽州县人民医院","address": ""},
    {"value": "襄汾县大邓乡卫生院土地殿分院","address": ""},
    {"value": "保德县杨家湾镇卫生院","address": ""},
    {"value": "长治泌尿专科医院","address": ""},
    {"value": "北大医疗潞安医院民爆医务所","address": ""},
    {"value": "广灵县仁爱医院有限公司","address": ""},
    {"value": "太原同善康复医院","address": ""},
    {"value": "太原市万柏林区神堂沟社区卫生服务中心","address": ""},
    {"value": "汾阳市阳城镇见喜卫生院","address": ""},
    {"value": "天镇县医疗集团卅里铺乡卫生院孙家店分院天镇县卅里铺乡卫生院孙家店分院","address": ""},
    {"value": "保德县韩家川乡卫生院","address": ""},
    {"value": "阳曲县安康医院","address": ""},
    {"value": "沁水博爱心脑血管病医院","address": ""},
    {"value": "太原市杏花岭区敦化坊社区卫生服务中心","address": ""},
    {"value": "太原德医尚口腔门诊管理有限公司万柏林西山口腔门诊部","address": ""},
    {"value": "太原紫藤口腔门诊有限公司杏花岭建设路口腔门诊部","address": ""},
    {"value": "太原恒伦恒大绿洲口腔门诊部","address": ""},
    {"value": "阳城县凤城镇芹安社区卫生服务站","address": ""},
    {"value": "芮城县第二人民医院（芮城县医疗集团第二人民医院，芮城县风陵渡镇卫生院）","address": ""},
    {"value": "临汾庄华骨关节病专科医院","address": ""},
    {"value": "文民医院","address": ""},
    {"value": "大同市新荣区医疗集团","address": ""},
    {"value": "大同市新荣区医疗集团人民医院、大同市新荣区人民医院","address": ""},
    {"value": "大同市新荣区复康医院","address": ""},
    {"value": "大同市新荣区德胜中医医院","address": ""},
    {"value": "平城区南关水泉湾龙园社区卫生服务站","address": ""},
    {"value": "北关北辰花园社区卫生服务站","address": ""},
    {"value": "南关永昌里社区卫生服务站","address": ""},
    {"value": "平城区向阳里青年路社区卫生服务站","address": ""},
    {"value": "阳高县古城镇卫生院下神峪分院(阳高县医疗集团古城镇卫生院下神峪分院)","address": ""},
    {"value": "阳高县长城乡卫生院（阳高县医疗集团长城乡卫生院阳高县长城乡妇幼保健计划生育服务站）","address": ""},
    {"value": "阳高县中医医院","address": ""},
    {"value": "阳高县下深井乡卫生院（阳高县医疗集团下深井乡卫生院阳高县下深井乡妇幼保健计划生育服务站","address": ""},
    {"value": "阳高县北徐屯乡卫生院（阳高县医疗集团北徐屯乡卫生院阳高县北徐屯乡妇幼保健计划生育服务站）","address": ""},
    {"value": "阳高明泽医院","address": ""},
    {"value": "阳高诺尔泰医院","address": ""},
    {"value": "阳高县下深井乡卫生院张官屯分院（阳高县医疗集团下深井乡卫生院张官屯分院）","address": ""},
    {"value": "阳高县王官屯镇卫生院（阳高县医疗集团王官屯镇卫生院阳高县王官屯镇妇幼保健计划生育服务站）","address": ""},
    {"value": "天镇县逯家湾镇卫生院宣家塔分院","address": ""},
    {"value": "天镇县医疗集团南河堡乡卫生院","address": ""},
    {"value": "天镇县中医医院","address": ""},
    {"value": "天镇县诚德医院有限公司","address": ""},
    {"value": "天镇县米薪关镇卫生院","address": ""},
    {"value": "天镇县医疗集团贾家屯乡卫生院（天镇县贾家屯乡卫生院）","address": ""},
    {"value": "山西省天镇县医疗集团新平镇卫生院","address": ""},
    {"value": "天镇县玉泉镇卫生院","address": ""},
    {"value": "天镇县医疗集团三十里铺乡卫生院","address": ""},
    {"value": "天镇县三十里铺乡卫生院","address": ""},
    {"value": "天镇县赵家沟乡卫生院","address": ""},
    {"value": "天镇县医疗集团谷前堡镇卫生院","address": ""},
    {"value": "天镇县医疗集团南高崖乡卫生院","address": ""},
    {"value": "天镇县医疗集团逯家湾镇卫生院","address": ""},
    {"value": "天镇县新平镇卫生院大营盘分院","address": ""},
    {"value": "天镇县南河堡乡卫生院东沙河分院","address": ""},
    {"value": "广灵县医疗集团作疃乡卫生院平城分院","address": ""},
    {"value": "广灵县医疗集团一斗泉乡卫生院","address": ""},
    {"value": "广灵县医疗集团加斗镇卫生院","address": ""},
    {"value": "广灵县医疗集团蕉山乡卫生院","address": ""},
    {"value": "广灵健安医院","address": ""},
    {"value": "广灵县医疗集团梁庄乡卫生院","address": ""},
    {"value": "广灵县医疗集团望狐乡卫生院","address": ""},
    {"value": "广灵县第二人民医院","address": ""},
    {"value": "灵丘县下关中心卫生院","address": ""},
    {"value": "灵丘县武灵镇卫生院唐之洼分院","address": ""},
    {"value": "灵丘县史庄乡卫生院","address": ""},
    {"value": "灵丘县上寨中心卫生院狼牙沟分院","address": ""},
    {"value": "灵丘县武灵镇卫生院高家庄分院","address": ""},
    {"value": "灵丘县落水河乡卫生院招柏分院","address": ""},
    {"value": "灵丘县白崖台中心卫生院","address": ""},
    {"value": "灵丘县落水河乡卫生院","address": ""},
    {"value": "灵丘县东河南中心卫生院","address": ""},
    {"value": "灵丘县妇幼保健院","address": ""},
    {"value": "灵丘县独峪乡卫生院三楼分院","address": ""},
    {"value": "山西省灵丘县人民医院（灵丘县医疗集团、灵丘县医疗集团人民医院）","address": ""},
    {"value": "浑源县东坊城乡卫生院荆庄分院（浑源县医疗集团东坊城乡卫生院荆庄分院）","address": ""},
    {"value": "浑源县东坊城乡卫生院（浑源县医疗集团东坊城乡卫生院）","address": ""},
    {"value": "浑源县大仁庄乡卫生院（浑源县医疗集团大仁庄乡卫生院）","address": ""},
    {"value": "浑源县王庄堡镇卫生院（浑源县医疗集团王庄堡镇卫生院）","address": ""},
    {"value": "浑源县永安镇卫生院（浑源县医疗集团永安镇卫生院）","address": ""},
    {"value": "浑源县青磁窑乡卫生院林场分院（浑源县医疗集团青磁窑乡卫生院林场分院）","address": ""},
    {"value": "浑源县千佛岭乡卫生院（浑源县医疗集团千佛岭乡卫生院）","address": ""},
    {"value": "浑源县大磁窑镇卫生院（浑源县医疗集团大磁窑镇卫生院）","address": ""},
    {"value": "浑源县黄花滩中心卫生院（浑源县医疗集团黄花滩乡卫生院）","address": ""},
    {"value": "浑源县青磁窑镇卫生院（浑源县医疗集团青磁窑镇卫生院）","address": ""},
    {"value": "浑源县永安镇卫生院思源分院（浑源县医疗集团永安镇卫生院思源分院）","address": ""},
    {"value": "浑源县官儿乡卫生院土岭分院（浑源县医疗集团官儿乡卫生院土岭分院）","address": ""},
    {"value": "浑源民康医院","address": ""},
    {"value": "浑源县南榆林乡卫生院（浑源县医疗集团南榆林乡卫生院）","address": ""},
    {"value": "左云县医疗集团（左云县人民医院、左云县医疗集团人民医院）","address": ""},
    {"value": "左云县医疗集团管家堡乡卫生院","address": ""},
    {"value": "左云县医疗集团管家堡乡卫生院威鲁分院","address": ""},
    {"value": "左云县医疗集团鹊儿山镇卫生院","address": ""},
    {"value": "左云县医疗集团小京庄乡卫生院","address": ""},
    {"value": "左云县医疗集团店湾镇卫生院","address": ""},
    {"value": "阳泉市矿区医疗集团（阳泉市矿区医院）沙坪社区卫生服务中心","address": ""},
    {"value": "高平市人民医院","address": ""},
    {"value": "文水县西城乡中心卫生院","address": ""},
    {"value": "尧都区同康口腔门诊部","address": ""},
    {"value": "临县医疗集团三交镇卫生院","address": ""},
    {"value": "太原化学工业集团有限公司职工医院","address": ""},
    {"value": "吕梁市离石区滨河街道办城东社区第二社区卫生服务站","address": ""},
    {"value": "大同市平城区绿洲西城社区卫生服务站","address": ""},
    {"value": "静乐县赤泥洼乡中心卫生院龙家庄分院","address": ""},
    {"value": "河曲县医疗集团刘家塔镇卫生院","address": ""},
    {"value": "太原市万柏林区闫家沟社区卫生服务站","address": ""},
    {"value": "太原尚宁综合门诊部","address": ""},
    {"value": "太原卓信医疗科技有限公司小店平阳路口腔门诊部","address": ""},
    {"value": "兴县城镇卫生院（兴县医疗集团城镇卫生院）","address": ""},
    {"value": "隰县医疗集团阳头升乡卫生院","address": ""},
    {"value": "太原健馨堂健康体检中心有限公司小店抱鼓巷综合门诊部","address": ""},
    {"value": "孝义市中心医院","address": ""},
    {"value": "霍州市医疗集团陶唐峪卫生院","address": ""},
    {"value": "北大医疗潞安医院煤基精细化学品公司医务室","address": ""},
    {"value": "太原市中医医院分病区","address": ""},
    {"value": "大同市云州区医疗集团许堡乡卫生院西册田社区卫生服务站","address": ""},
    {"value": "临汾市尧都区西街办事处学府社区卫生服务站","address": ""},
    {"value": "新绛县人民医院","address": ""},
    {"value": "清徐县徐沟镇中心卫生院","address": ""},
    {"value": "怀仁仁德综合医院","address": ""},
    {"value": "长治市潞州区太行西街街道英雄北路社区卫生服务站","address": ""},
    {"value": "太原市小店区殷家堡社区卫生服务站","address": ""},
    {"value": "山西省大同市新荣区破鲁人民医院","address": ""},
    {"value": "阳城县凤城镇中心卫生院尹庄分院","address": ""},
    {"value": "壶关县医疗集团常行分院","address": ""},
    {"value": "岢岚县岚漪镇卫生院（岢岚县医疗集团岚漪镇卫生院）","address": ""},
    {"value": "太原市挚友口腔门诊部","address": ""},
    {"value": "昔阳县乐平镇卫生院城区社区分院","address": ""},
    {"value": "忻州市忻府区疾控中心附属医院","address": ""},
    {"value": "长治市潞洲区延安南路街道惠丰社区卫生服务站","address": ""},
    {"value": "岢岚县李家沟乡卫生院","address": ""},
    {"value": "怀仁市街道社区卫生服务中心","address": ""},
    {"value": "河津市城区办事处莲池社区卫生服务站","address": ""},
    {"value": "朔州爱尔眼科医院","address": ""},
    {"value": "太谷博康医院","address": ""},
    {"value": "晋城市城区西街街道办事处前进路社区卫生服务站","address": ""},
    {"value": "永济宣德国医馆","address": ""},
    {"value": "大同矿区恒安健康乐门诊部","address": ""},
    {"value": "山阴县医疗集团合盛堡乡卫生院","address": ""},
    {"value": "山西连氏医疗管理有限公司祁县永康连氏中医综合门诊","address": ""},
    {"value": "晋中市榆次区安宁街道安新社区卫生服务站","address": ""},
    {"value": "云冈区平盛路社区卫生服务中心","address": ""},
    {"value": "曲沃县口腔医院","address": ""},
    {"value": "平遥县南政乡卫生院王家庄卫生站","address": ""},
    {"value": "临猗丰喜职工医院","address": ""},
    {"value": "运城福平医院","address": ""},
    {"value": "左云县医疗集团三屯乡卫生院","address": ""},
    {"value": "左云县医疗集团马道头乡卫生院","address": ""},
    {"value": "左云县医疗集团水窑乡卫生院","address": ""},
    {"value": "左云县正德医院有限公司","address": ""},
    {"value": "左云县康爱医院有限公司","address": ""},
    {"value": "左云县医疗集团第一中心卫生院","address": ""},
    {"value": "左云县正康医院有限公司","address": ""},
    {"value": "左云县医疗集团云兴镇卫生院","address": ""},
    {"value": "阳泉市第四人民医院（阳泉市妇产医院）","address": ""},
    {"value": "阳泉市第五人民医院（阳泉市精神病医院）","address": ""},
    {"value": "阳泉市口腔医院","address": ""},
    {"value": "阳泉市郊区荫营杏花园社区卫生服务站","address": ""},
    {"value": "阳泉桃河医院","address": ""},
    {"value": "阳泉惠慈中医门诊部","address": ""},
    {"value": "阳泉康爱医院（普通合伙）","address": ""},
    {"value": "阳泉经济技术开发区居馨花园综合门诊部","address": ""},
    {"value": "阳泉煤业（集团）有限责任公司第三医院","address": ""},
    {"value": "阳泉煤业（集团）有限责任公司总医院","address": ""},
    {"value": "阳泉康达医院","address": ""},
    {"value": "阳泉煤业（集团）有限责任公司第二医院","address": ""},
    {"value": "阳泉市妇幼保健院（阳泉市妇幼保健计划生育服务中心）","address": ""},
    {"value": "阳泉市老龄委医院","address": ""},
    {"value": "阳泉市第三人民医院","address": ""},
    {"value": "阳泉祥爱医院","address": ""},
    {"value": "阳泉市城区小北沟社区卫生服务站","address": ""},
    {"value": "阳泉市城区医疗集团（阳泉市城区晋东医院、阳泉市城区医疗集团晋东医院）","address": ""},
    {"value": "阳泉市城区万年花城社区卫生服务站","address": ""},
    {"value": "阳泉医养康复医院","address": ""},
    {"value": "阳泉市华康惠民门诊部（普通合伙）","address": ""},
    {"value": "阳泉静安医院","address": ""},
    {"value": "阳泉辰欣医院","address": ""},
    {"value": "阳泉日潭医院","address": ""},
    {"value": "阳泉庆林医院","address": ""},
    {"value": "阳泉市城区人民医院（阳泉市城区医疗集团城区人民医院）","address": ""},
    {"value": "阳泉市城区龙躺梁社区卫生服务站","address": ""},
    {"value": "阳泉市城区鸿龙湾社区卫生服务站","address": ""},
    {"value": "云冈区和顺泰盛里社区卫生服务站","address": ""},
    {"value": "晋城博润微创外科医院","address": ""},
    {"value": "长治市潞州区潞德医院","address": ""},
    {"value": "太原市迎泽区双塔寺一社区卫生服务站","address": ""},
    {"value": "长子县丹朱卫生院草坊分院（长子县医疗集团丹朱卫生院草坊分院）","address": ""},
    {"value": "太原市迎泽区绿地东山社区卫生服务站","address": ""},
    {"value": "平陆仁爱精神病医院","address": ""},
    {"value": "吕梁市离石区凤山街道办新华社区卫生服务站","address": ""},
    {"value": "大同市云州区医疗集团许堡乡卫生院（大同市云州区许堡乡卫生院）","address": ""},
    {"value": "大同市云州区西坪镇卫生院瓜园分院","address": ""},
    {"value": "大同市云州区医疗集团周士庄镇卫生院（大同市云州区周士庄镇卫生院）","address": ""},
    {"value": "大同市云州区医疗集团（大同市云州区人民医院）","address": ""},
    {"value": "大同市云州区医疗集团倍加造镇卫生院（大同市云州区倍加造镇卫生院）","address": ""},
    {"value": "大同市云州区医疗集团峰峪乡卫生院（大同市云州区峰峪乡卫生院）","address": ""},
    {"value": "大同市云州区医疗集团吉家庄乡卫生院麻峪口社区卫生服务站","address": ""},
    {"value": "大同市云州区医疗集团聚乐乡卫生院（大同市云州区聚乐乡卫生院）","address": ""},
    {"value": "大同市云州区医疗集团西坪镇卫生院（大同市云州区西坪镇卫生院）","address": ""},
    {"value": "大同市云州区中医医院","address": ""},
    {"value": "大同市云州区医疗集团党留庄乡卫生院（大同市云州区党留庄乡卫生院）","address": ""},
    {"value": "北大医疗潞安医院左权佳瑞医务室","address": ""},
    {"value": "阳泉市城区滨河社区卫生服务站","address": ""},
    {"value": "山西林佳仁爱中医有限公司阳泉林佳中医馆门诊部","address": ""},
    {"value": "阳泉市郊区杨家庄乡卫生院","address": ""},
    {"value": "阳泉市郊区医疗集团旧街分院（阳泉市郊区旧街乡卫生院）","address": ""},
    {"value": "阳泉市郊区医疗集团李家庄分院（阳泉市郊区李家庄乡卫生院）","address": ""},
    {"value": "阳泉河下中西医结合医院","address": ""},
    {"value": "阳泉市郊区荫营康馨社区卫生服务站","address": ""},
    {"value": "阳泉爱德生妇产医院","address": ""},
    {"value": "阳泉市郊区医疗集团河底分院（阳泉市郊区河底镇卫生院）","address": ""},
    {"value": "阳泉市郊区荫营淑华园社区卫生服务站","address": ""},
    {"value": "平定县张庄镇卫生院（平定县医疗集团张庄镇分院）","address": ""},
    {"value": "平定庆鸿口腔医疗有限公司庆鸿口腔门诊部","address": ""},
    {"value": "平定县中医医院","address": ""},
    {"value": "平定县广仁堂医院","address": ""},
    {"value": "平定县冠山镇卫生院（平定县医疗集团冠山镇分院）","address": ""},
    {"value": "平定县岔口乡卫生院（平定县医疗集团岔口乡分院）","address": ""},
    {"value": "平定县医疗集团、平定县医疗集团人民医院、平定县人民医院","address": ""},
    {"value": "平定民众复康医院","address": ""},
    {"value": "阳高县医疗集团龙泉镇卫生院","address": ""},
    {"value": "临汾锦冉心理康复医院（临汾锦冉精神病医院）","address": ""},
    {"value": "河曲县沙泉镇卫生院阴塔分院","address": ""},
    {"value": "长治市上党区荫城镇中心卫生院","address": ""},
    {"value": "太原市小店区许西社区卫生服务站","address": ""},
    {"value": "新绛县东城医院","address": ""},
    {"value": "太原市尖草坪区太纸社区卫生服务站","address": ""},
    {"value": "太原资善堂中医门诊部有限公司","address": ""},
    {"value": "晋城市人民医院","address": ""},
    {"value": "临汾市尧都区解放路办事处平阳社区卫生服务站","address": ""},
    {"value": "高平市神农镇卫生院（高平市医疗集团神农镇卫生院）","address": ""},
    {"value": "晋城市中医医院","address": ""},
    {"value": "山阴县医疗集团甘庄卫生院","address": ""},
    {"value": "朔州市平鲁区高石庄乡卫生院","address": ""},
    {"value": "晋城市城区东街街道办事处康乐社区卫生服务站","address": ""},
    {"value": "山西省吕梁市离石区西属巴街道办上安社区卫生服务站","address": ""},
    {"value": "临汾东霖种植齿科医院有限公司","address": ""},
    {"value": "古交刘瑞文口腔门诊部","address": ""},
    {"value": "太原市万柏林区大运专科门诊部","address": ""},
    {"value": "临汾市尧都区昌霖齿科有限公司","address": ""},
    {"value": "兴县恶虎滩乡卫生院","address": ""},
    {"value": "汾阳市西河街道社区卫生服务中心","address": ""},
    {"value": "岢岚鼓楼医院","address": ""},
    {"value": "北大医疗潞安医院五阳分院","address": ""},
    {"value": "晋城市城区南街街道办事处万苑社区卫生服务站","address": ""},
    {"value": "运城市荣军医院（运城市精神卫生中心）","address": ""},
    {"value": "太原天使儿童医院","address": ""},
    {"value": "襄汾县南贾镇卫生院","address": ""},
    {"value": "长治市上党区黎都街社区卫生服务站","address": ""},
    {"value": "大同市平城区永康综合门诊部有限公司","address": ""},
    {"value": "孝义市医疗集团西辛庄分院城区服务站","address": ""},
    {"value": "北关铁牛里社区卫生服务站","address": ""},
    {"value": "太原市迎泽区建南铁路社区卫生服务站","address": ""},
    {"value": "太原精华口腔门诊部有限公司","address": ""},
    {"value": "汾阳市阳城中心卫生院","address": ""},
    {"value": "盂县医疗集团苌池镇卫生院","address": ""},
    {"value": "盂县医疗集团西烟镇卫生院","address": ""},
    {"value": "盂县医疗集团（盂县医疗集团人民医院、盂县人民医院）","address": ""},
    {"value": "盂县蓝天中医针刀门诊","address": ""},
    {"value": "盂县医疗集团东梁乡卫生院","address": ""},
    {"value": "盂县医疗集团西潘乡卫生院","address": ""},
    {"value": "盂县医疗集团北下庄乡卫生院","address": ""},
    {"value": "盂县医疗集团梁家寨乡卫生院","address": ""},
    {"value": "盂县医疗集团上社镇卫生院","address": ""},
    {"value": "盂县福珍门诊","address": ""},
    {"value": "盂县韩氏中医门诊","address": ""},
    {"value": "盂县中医医院","address": ""},
    {"value": "盂县新阳光医院","address": ""},
    {"value": "盂县医疗集团南娄镇卫生院(下曹卫生服务站、西小坪卫生服务站)","address": ""},
    {"value": "长治德民信口腔门诊部","address": ""},
    {"value": "长治爱尔眼科医院","address": ""},
    {"value": "长治市妇幼保健院（长治市妇产医院、长治市儿童医院）","address": ""},
    {"value": "长治云峰医院","address": ""},
    {"value": "长治市中医研究所附属医院","address": ""},
    {"value": "长治市中医医院","address": ""},
    {"value": "长治市人民医院（长治市职业病防治院）","address": ""},
    {"value": "长治市屯留区盘秀社区卫生服务站","address": ""},
    {"value": "襄垣县医疗集团、襄垣县医疗集团人民医院、襄垣县人民医院","address": ""},
    {"value": "襄垣县王桥镇卫生院（襄垣县医疗集团王桥镇卫生院）","address": ""},
    {"value": "襄垣县县城城镇居民基本公共卫生服务中心（襄垣县社区卫生服务中心、襄垣县医疗集团社区卫生服务中心）","address": ""},
    {"value": "襄垣县中医医院","address": ""},
    {"value": "襄垣县虒亭镇中心卫生院（襄垣县医疗集团虒亭镇中心卫生院）","address": ""},
    {"value": "襄垣县侯堡中心卫生院（襄垣县医疗集团侯堡中心卫生院）","address": ""},
    {"value": "襄垣县善福镇卫生院","address": ""},
    {"value": "平顺县医疗集团（平顺县人民医院、平顺县医疗集团人民医院）","address": ""},
    {"value": "平顺博爱医院","address": ""},
    {"value": "平顺县青羊镇卫生院（平顺县医疗集团青羊镇卫生院）","address": ""},
    {"value": "平顺县玉峡关镇卫生院（平顺县医疗集团玉峡关镇卫生院）","address": ""},
    {"value": "太原市万柏林区千峰南路社区卫生服务站","address": ""},
    {"value": "太原艾美菲恩口腔门诊部","address": ""},
    {"value": "临汾奇康痔瘘医院","address": ""},
    {"value": "浑源现代医院","address": ""},
    {"value": "太原市杏花岭区小北关社区卫生服务站","address": ""},
    {"value": "太原市万柏林区十二院城社区卫生服务站","address": ""},
    {"value": "云冈区新泉社区卫生服务中心","address": ""},
    {"value": "太原市万柏林区康乐苑社区卫生服务站","address": ""},
    {"value": "平定县东回镇卫生院","address": ""},
    {"value": "晋城市城区东街街道办事处凤台社区卫生服务站","address": ""},
    {"value": "晋城市城区钟家庄街道办事处白水社区卫生服务站","address": ""},
    {"value": "太原众植齿科门诊部","address": ""},
    {"value": "介休刘双全华润苑口腔门诊部有限公司","address": ""},
    {"value": "左权县石匣乡卫生院（左权县医疗集团石匣乡卫生院）","address": ""},
    {"value": "太原奥蕊口腔门诊管理有限公司小店康宁雅苑口腔门诊部","address": ""},
    {"value": "大同市中医医院","address": ""},
    {"value": "盐湖华颐口腔门诊部","address": ""},
    {"value": "繁峙民政优抚医院","address": ""},
    {"value": "长治市潞州区太行东街街道金融社区卫生服务站","address": ""},
    {"value": "长治市潞州区西街街道长安社区卫生服务站","address": ""},
    {"value": "长治市潞州区太行东街街道澳瑞特社区卫生服务站","address": ""},
    {"value": "长治市潞州区东街街道安康社区卫生服务站","address": ""},
    {"value": "长治城区妇产医院","address": ""},
    {"value": "长治市潞州区东街街道下东社区卫生服务站","address": ""},
    {"value": "长治市潞州区英雄中路街道县前巷社区卫生服务站","address": ""},
    {"value": "长治市潞州区太行东街街道广场东社区卫生服务站","address": ""},
    {"value": "长治市潞州区英雄南路街道社区卫生服务中心","address": ""},
    {"value": "长治同康眼科医院","address": ""},
    {"value": "长治潞州晋新医院","address": ""},
    {"value": "长治市潞州区黄碾镇卫生院（长治市潞州区医疗集团黄碾镇卫生院）","address": ""},
    {"value": "长治市潞州区大辛庄镇中心卫生院（长治市潞州区医疗集团大辛庄镇中心卫生院）","address": ""},
    {"value": "长治县冯来有骨科医院","address": ""},
    {"value": "长治县南宋乡卫生院","address": ""},
    {"value": "长治县东和乡卫生院","address": ""},
    {"value": "长治县西火镇卫生院","address": ""},
    {"value": "长治县振兴新区卫生院","address": ""},
    {"value": "长治县鑫新妇产医院","address": ""},
    {"value": "长治市上党区人民医院","address": ""},
    {"value": "长治立新正骨医院","address": ""},
    {"value": "长治县朝阳医院","address": ""},
    {"value": "上党区北呈乡北呈乡卫生院","address": ""},
    {"value": "长治市上党区韩店镇中心卫生院","address": ""},
    {"value": "长治市上党区苏店镇中心卫生院","address": ""},
    {"value": "长治县八义镇中心卫生院","address": ""},
    {"value": "长治市上党区中医院","address": ""},
    {"value": "屯留县西贾乡卫生院（挂：屯留县医疗集团西贾乡卫生院）","address": ""},
    {"value": "长治市屯留区河神庙乡卫生院(挂：长治市屯留区医疗集团河神庙乡卫生院)","address": ""},
    {"value": "屯留县余吾镇卫生院（挂：屯留县医疗集团余吾镇卫生院）","address": ""},
    {"value": "屯留区丰宜镇卫生院（挂：屯留区医疗集团丰宜镇卫生院）","address": ""},
    {"value": "屯留嘉之慧医院","address": ""},
    {"value": "屯留县李高乡卫生院（挂：屯留县医疗集团李高乡卫生院）","address": ""},
    {"value": "长治市屯留区吾元镇卫生院（挂：长治市屯留区医疗集团吾元镇卫生院）","address": ""},
    {"value": "忻州市忻府区九原街街道社区卫生服务中心(忻州市忻府区医疗集团九原街街道社区卫生服务中心)","address": ""},
    {"value": "清徐县人民医院","address": ""},
    {"value": "黎城县东阳关镇卫生院","address": ""},
    {"value": "晋城市城区东街街道办事处凤翔社区卫生服务站","address": ""},
    {"value": "晋中康慈血液透析中心","address": ""},
    {"value": "沁县定昌镇东苑社区卫生一所","address": ""},
    {"value": "大同市云冈区人民医院","address": ""},
    {"value": "阳泉市城区小阳泉南社区卫生服务站","address": ""},
    {"value": "右玉县医疗集团白头里乡卫生院","address": ""},
    {"value": "右玉县杀虎口旅游区分院","address": ""},
    {"value": "右玉县医疗集团右卫镇卫生院","address": ""},
    {"value": "右玉县医疗集团威远镇卫生院","address": ""},
    {"value": "右玉县医疗集团牛心堡卫生院","address": ""},
    {"value": "右玉县医疗集团杨千河卫生院","address": ""},
    {"value": "右玉县医疗集团丁家窑卫生院","address": ""},
    {"value": "右玉县医疗集团元堡子镇卫生院","address": ""},
    {"value": "右玉县医疗集团李达窑卫生院","address": ""},
    {"value": "右玉县医疗集团高家堡卫生院","address": ""},
    {"value": "古交市医疗集团梭峪乡卫生院","address": ""},
    {"value": "晋中榆次仁信医院","address": ""},
    {"value": "隰县医疗集团陡坡乡卫生院","address": ""},
    {"value": "山西众植齿科医院有限公司桃园路门诊部","address": ""},
    {"value": "和顺县青城镇卫生院土岭分院","address": ""},
    {"value": "泽州县悦康医院","address": ""},
    {"value": "乡宁县医疗集团台头镇卫生院","address": ""},
    {"value": "晋中市太谷区白塔社区卫生服务中心","address": ""},
    {"value": "柳林康华中医院","address": ""},
    {"value": "大同爱尔眼科医院","address": ""},
    {"value": "太原市万柏林区和平社区卫生服务中心","address": ""},
    {"value": "朔州市平鲁区医疗集团，朔州市平鲁区医疗集团人民医院，朔州市平鲁区人民医院","address": ""},
    {"value": "五台县沟南乡卫生院刘家庄社区服务站","address": ""},
    {"value": "襄汾仁河医院","address": ""},
    {"value": "太原市第九人民医院","address": ""},
    {"value": "太原市迎泽区南内环街四社区卫生服务站","address": ""},
    {"value": "太原恒怡口腔门诊部","address": ""},
    {"value": "太原正医堂中医门诊部","address": ""},
    {"value": "太原牙一生口腔门诊部","address": ""},
    {"value": "晋城经济开发区东谢匠社区卫生服务站","address": ""},
    {"value": "太原柏善堂中医门诊部","address": ""},
    {"value": "运城市盐湖区东城办事处河东西街社区卫生服务站","address": ""},
    {"value": "汾阳爱尔眼科医院","address": ""},
    {"value": "武乡县墨镫卫生院","address": ""},
    {"value": "太原恒伦敦化路口腔门诊部有限公司","address": ""},
    {"value": "浮山县中医医院","address": ""},
    {"value": "大同市云州区吉家庄乡卫生院","address": ""},
    {"value": "中阳县仁和医院","address": ""},
    {"value": "河津市赵家庄街道社区卫生服务中心（河津市医疗集团赵家庄街道社区卫生服务中心）","address": ""},
    {"value": "祁县古县镇中心卫生院（祁县医疗集团古县镇中心卫生院）","address": ""},
    {"value": "太原市尖草坪区建工街第二社区卫生服务站","address": ""},
    {"value": "临汾恒信齿科医院","address": ""},
    {"value": "太原市晋源区金胜镇卫生院","address": ""},
    {"value": "兴县圪垯上乡卫生院","address": ""},
    {"value": "孝义市医疗集团南阳分院（孝义市南阳乡卫生院）","address": ""},
    {"value": "大同市新荣区新荣镇卫生院（大同市新荣区医疗集团新荣镇卫生院）","address": ""},
    {"value": "吉县医疗集团吉昌镇卫生院（吉县吉昌镇卫生院）","address": ""},
    {"value": "太原恒伦万科蓝山口腔门诊部","address": ""},
    {"value": "太原长风汉和门诊部有限公司","address": ""},
    {"value": "太原市迎泽区太堡社区卫生服务站","address": ""},
    {"value": "阳高县狮子屯乡卫生院","address": ""},
    {"value": "岢岚惠康医院","address": ""},
    {"value": "平城区南关社区服务中心","address": ""},
    {"value": "大同仁和医院","address": ""},
    {"value": "朔州市朔城区滋润乡卫生院汴子疃分院","address": ""},
    {"value": "临汾胜杰齿科医院向阳西路分院","address": ""},
    {"value": "长治市上党区西池乡卫生院","address": ""},
    {"value": "临汾市尧都区路东办事处天水社区卫生服务站","address": ""},
    {"value": "临县医疗集团湍水头镇卫生院","address": ""},
    {"value": "阳曲县东黄水镇卫生院（阳曲县医疗集团东黄水镇卫生院）","address": ""},
    {"value": "太原赵建功口腔门诊部","address": ""},
    {"value": "临汾市尧都区西街办事处仓颉社区卫生服务站","address": ""},
    {"value": "尚亨口腔门诊部","address": ""},
    {"value": "太原市迎泽区南内环街三社区卫生服务站","address": ""},
    {"value": "霍州市医疗集团李曹镇卫生院","address": ""},
    {"value": "太原市急救中心","address": ""},
    {"value": "保德县医疗集团林遮峪乡分院","address": ""},
    {"value": "保德县林遮峪乡卫生院","address": ""},
    {"value": "长治贝森口腔医院","address": ""},
    {"value": "汾阳市贾家庄镇卫生院","address": ""},
    {"value": "五台昌宏医院","address": ""},
    {"value": "平旺乡卫生院","address": ""},
    {"value": "阳城县蟒河镇卫生院桑林分院","address": ""},
    {"value": "原平市北城社区卫生服务中心","address": ""},
    {"value": "原平市原化医院卫生服务站","address": ""},
    {"value": "怀仁王坪医院","address": ""},
    {"value": "运城涑水创伤医院","address": ""},
    {"value": "方山百川中医院","address": ""},
    {"value": "太原市小店区大马社区卫生服务站","address": ""},
    {"value": "高平残联精神康复医院","address": ""},
    {"value": "永济忠愍医院","address": ""},
    {"value": "安泽县医疗集团和川镇罗云卫生服务站","address": ""},
    {"value": "晋中市太谷区中医院","address": ""},
    {"value": "运城市盐湖区东城办事处鸿晋社区卫生服务站","address": ""},
    {"value": "大同市云州区医疗集团西坪镇卫生院陈庄分院","address": ""},
    {"value": "山西大学医院大东关门诊部","address": ""},
    {"value": "大同宏盛康复医院","address": ""},
    {"value": "绛县仁心医院","address": ""},
    {"value": "大同市中北医院","address": ""},
    {"value": "朔州通济中西医结合医院","address": ""},
    {"value": "阳城县次营镇中心卫生院","address": ""},
    {"value": "临汾市第五人民医院","address": ""},
    {"value": "孝义市医疗集团大孝堡分院","address": ""},
    {"value": "临猗狮瑞血液透析中心","address": ""},
    {"value": "临猗县北景乡中心卫生院闫家庄分院（临猗县医疗集团北景乡闫家庄分院）","address": ""},
    {"value": "保德县医疗集团腰庄乡分院","address": ""},
    {"value": "平陆县人民医院","address": ""},
    {"value": "太原市晋源区郭志芳口腔门诊部","address": ""},
    {"value": "临汾市尧都区南街办事处尧乡社区卫生服务站","address": ""},
    {"value": "五台县台怀镇中心卫生院","address": ""},
    {"value": "太原文武口腔门诊管理有限公司杏花岭享堂口腔门诊部","address": ""},
    {"value": "天镇县福康寿敬老院医务室","address": ""},
    {"value": "太原市尖草坪区东方社区卫生服务站","address": ""},
    {"value": "万荣平康中医医院","address": ""},
    {"value": "朔州颐康医院","address": ""},
    {"value": "古交市嘉乐泉乡中心卫生院（古交市医疗集团嘉乐泉乡卫生院）","address": ""},
    {"value": "朔州惠康宇天中西医结合医院有限公司","address": ""},
    {"value": "积翠乡卫生院","address": ""},
    {"value": "应县医疗集团杏寨乡中心卫生院","address": ""},
    {"value": "孝义市医疗集团中阳楼分院（孝义市中阳楼街道社区卫生服务中心）","address": ""},
    {"value": "襄汾县赵康镇卫生院丰盈分院","address": ""},
    {"value": "阳高县罗文皂镇卫生院太平堡分院","address": ""},
    {"value": "长治市上党区府后街社区卫生服务站","address": ""},
    {"value": "沁水县固县乡卫生院","address": ""},
    {"value": "文水县南安镇卫生院","address": ""},
    {"value": "大同市云冈区和瑞泰美里社区卫生服务站","address": ""},
    {"value": "文水县西槽头乡卫生院","address": ""},
    {"value": "昔阳中兴东南医院（有限公司）","address": ""},
    {"value": "上党区医疗集团郝家庄卫生院","address": ""},
    {"value": "绛县忠德医院","address": ""},
    {"value": "大同机车厂医院","address": ""},
    {"value": "临猗国仁医院","address": ""},
    {"value": "晋中市榆次区西南街道思凤社区卫生服务站","address": ""},
    {"value": "文水光明医院","address": ""},
    {"value": "天镇县米薪关镇卫生院谷大屯分院","address": ""},
    {"value": "浑源县医疗集团西留乡卫生院","address": ""},
    {"value": "山西尔湾妇产医院","address": ""},
    {"value": "代县雁桥医院","address": ""},
    {"value": "河津同德医院","address": ""},
    {"value": "阳高县友宰镇卫生院","address": ""},
    {"value": "晋城大医院王台分院","address": ""},
    {"value": "临汾现代妇产医院","address": ""},
    {"value": "高平市建宁乡卫生院（高平市医疗集团建宁乡卫生院）","address": ""},
    {"value": "阳泉市康复医院","address": ""},
    {"value": "晋城煤业集团总医院天溪医务所","address": ""},
    {"value": "太原市小店区平阳二社区卫生服务站","address": ""},
    {"value": "平顺县阳高乡卫生院","address": ""},
    {"value": "侯马仁馨综合医院","address": ""},
    {"value": "吕梁市离石区莲花池街道办六一街社区卫生服务站","address": ""},
    {"value": "古交市东曲街道办事处青年路社区卫生服务站","address": ""},
    {"value": "太原市社会福利精神康宁医院","address": ""},
    {"value": "襄汾县永固乡卫生院","address": ""},
    {"value": "沁源县人民医院","address": ""},
    {"value": "昔阳县赵壁中心卫生院白羊峪分院","address": ""},
    {"value": "山西省大同市新荣区上深涧乡卫生院","address": ""},
    {"value": "应县医疗集团下社镇卫生院","address": ""},
    {"value": "临汾市尧都区南街办事处太茅社区卫生服务站","address": ""},
    {"value": "吕梁市离石区人民医院吕梁市离石区医疗集团人民医院","address": ""},
    {"value": "太原市杏花岭区西苑社区卫生服务站","address": ""},
    {"value": "沁源县法中乡卫生院","address": ""},
    {"value": "晋中市太谷区人民医院","address": ""},
    {"value": "山西省太谷仁术医院","address": ""},
    {"value": "长治潞州启航医院","address": ""},
    {"value": "平遥县东泉中心卫生院孟山卫生站","address": ""},
    {"value": "晋城大医院寺河分院","address": ""},
    {"value": "长治盈康老年护理院","address": ""},
    {"value": "应县医疗集团白马石乡卫生院","address": ""},
    {"value": "太原叁陆伍口腔医疗科技有限公司万柏林信达口腔门诊部","address": ""},
    {"value": "太原捷通综合门诊部","address": ""},
    {"value": "晋城市城区西街街道办事处五龙河西社区卫生服务站","address": ""},
    {"value": "大同市平城区德冠综合门诊部有限公司","address": ""},
    {"value": "石楼民康医院","address": ""},
    {"value": "晋城和平耳鼻喉专科医院","address": ""},
    {"value": "襄汾县西贾乡卫生院","address": ""},
    {"value": "灵石县翠峰镇卫生院","address": ""},
    {"value": "太原市万柏林区下元社区卫生服务中心","address": ""},
    {"value": "原平市吉祥街道社区卫生服务中心","address": ""},
    {"value": "宁武县医疗集团宁化镇卫生院","address": ""},
    {"value": "临汾市尧都区医疗集团贾得乡卫生院大苏分院","address": ""},
    {"value": "河曲县精神病医院（河曲县第三人民医院）","address": ""},
    {"value": "娄烦县杜交曲镇卫生院","address": ""},
    {"value": "阳高县医疗集团龙泉镇卫生院孙仁堡分院（阳高县龙泉镇卫生院孙仁堡分院）","address": ""},
    {"value": "平定县冠山镇姑姑寺社区卫生服务站","address": ""},
    {"value": "云冈区新胜校北街社区卫生服务站","address": ""},
    {"value": "侯马市路西街道办事处社区卫生服务中心","address": ""},
    {"value": "灵丘县医疗集团红石塄乡卫生院","address": ""},
    {"value": "平陆县杜马乡卫生院（平陆县医疗集团杜马乡卫生院）","address": ""},
    {"value": "长治市潞州区故县街道创业社区卫生服务站","address": ""},
    {"value": "太原晓琼口腔门诊部有限公司","address": ""},
    {"value": "神池县妇幼保健服务中心","address": ""},
    {"value": "晋城市城区矿区街道办事处凤凰山矿社区卫生服务站","address": ""},
    {"value": "保德县南河沟乡卫生院","address": ""},
    {"value": "太原恒达口腔门诊部","address": ""},
    {"value": "垣曲县新城镇卫生院","address": ""},
    {"value": "永济市城西街道任阳卫生院","address": ""},
    {"value": "五寨县第二人民医院（五寨县医疗集团第二人民医院）","address": ""},
    {"value": "应县医疗集团白马石乡卫生院双钱树分院","address": ""},
    {"value": "寿阳县景尚乡卫生院（寿阳县景尚乡妇幼保健计划生育服务站、寿阳县医疗集团景尚乡卫生院）","address": ""},
    {"value": "永和县桑壁镇卫生院","address": ""},
    {"value": "介休市龙凤镇卫生院","address": ""},
    {"value": "柳林县中医院","address": ""},
    {"value": "闻喜同德医院","address": ""},
    {"value": "临汾市尧都区车站街办事处社区卫生服务中心","address": ""},
    {"value": "吕梁华康烧伤专科医院","address": ""},
    {"value": "介休市绵山镇中心卫生院","address": ""},
    {"value": "河曲县医疗集团文笔镇卫生院","address": ""},
    {"value": "高平市永录乡卫生院（高平市人民医院集团永录分院）","address": ""},
    {"value": "太原市尖草坪区青楼社区卫生服务站","address": ""},
    {"value": "太原市小店区西温庄乡卫生院","address": ""},
    {"value": "大同广合中医医院","address": ""},
    {"value": "翼城曹维权齿科","address": ""},
    {"value": "新绛县北张镇中心卫生院（新绛县医疗集团北张镇中心卫生院）","address": ""},
    {"value": "太原恒伦口腔医院","address": ""},
    {"value": "万荣县妇幼保健院","address": ""},
    {"value": "永济市中医医院","address": ""},
    {"value": "定襄县医疗集团杨芳乡卫生院（定襄县杨芳乡卫生院）","address": ""},
    {"value": "襄垣县古韩镇卫生院（襄垣县医疗集团古韩镇卫生院）","address": ""},
    {"value": "沁水县苏庄乡卫生院","address": ""},
    {"value": "太原市杏花岭区旱西关东社区卫生服务站","address": ""},
    {"value": "应县医疗集团金城镇社区卫生服务中心","address": ""},
    {"value": "运城市美年大健康体检有限公司禹都大道门诊部","address": ""},
    {"value": "长治市潞城区李秀风综合门诊部","address": ""},
    {"value": "山西工商学院医务室","address": ""},
    {"value": "洪洞县兴唐寺乡卫生院（洪洞县医疗集团兴唐寺乡卫生院）","address": ""},
    {"value": "平城区新建北路惠达社区卫生服务站","address": ""},
    {"value": "太原正德堂中医门诊部","address": ""},
    {"value": "太原奥伯伦口腔门诊部有限公司","address": ""},
    {"value": "晋能控股装备制造集团有限公司铁路运输分公司医务所","address": ""},
    {"value": "霍州发电厂职工医务室","address": ""},
    {"value": "中阳县医疗集团枝柯镇卫生院","address": ""},
    {"value": "黎城县平头乡卫生院","address": ""},
    {"value": "大同市云冈区老平旺社区卫生服务中心","address": ""},
    {"value": "盂县阳光百姓药房有限公司家乐门诊","address": ""},
    {"value": "朔州市朔城区利民镇卫生院暖崖分院","address": ""},
    {"value": "偏关县老营卫生院","address": ""},
    {"value": "应县康养医院","address": ""},
    {"value": "保德明泰口腔门诊部","address": ""},
    {"value": "太原金秋益中医门诊部","address": ""},
    {"value": "云冈恒康健门诊部","address": ""},
    {"value": "古交市屯兰街道办事处屯乐苑社区卫生服务站","address": ""},
    {"value": "忻州市忻府区北义井乡卫生院","address": ""},
    {"value": "稷山县医疗集团翟店镇卫生院","address": ""},
    {"value": "云冈区新胜安居街社区卫生服务站","address": ""},
    {"value": "大宁县医疗集团徐家垛乡卫生院","address": ""},
    {"value": "泽州县医疗集团山河镇土河卫生院","address": ""},
    {"value": "方山县妇幼保健计划生育服务中心","address": ""},
    {"value": "祁县第二人民医院（祁县东观镇中心卫生院、祁县医疗集团东观镇中心卫生院）","address": ""},
    {"value": "大同市平城区水泊寺街道卫生院","address": ""},
    {"value": "晋中慈苑综合门诊部","address": ""},
    {"value": "太原市晋源区晋祠镇中心卫生院","address": ""},
    {"value": "阳泉市强制隔离戒毒所戒毒医院","address": ""},
    {"value": "临汾市尧都区辛寺街办事处东关社区卫生服务站","address": ""},
    {"value": "朔州市传染病医院","address": ""},
    {"value": "云冈区和顺泰兴里社区卫生服务站","address": ""},
    {"value": "太原市尖草坪区兴安社区卫生服务站","address": ""},
    {"value": "朔州神电中西医结合医院有限公司","address": ""},
    {"value": "云冈区和瑞社区卫生服务中心","address": ""},
    {"value": "临汾利贞医院","address": ""},
    {"value": "和顺县人口和计生妇幼服务中心","address": ""},
    {"value": "五台慈爱医院","address": ""},
    {"value": "应县利民医院（普通合伙）","address": ""},
    {"value": "应县医疗集团臧寨乡中心卫生院（应县臧寨乡中心卫生院）","address": ""},
    {"value": "灵丘县赵北乡卫生院王成庄分院","address": ""},
    {"value": "文水县中医院","address": ""},
    {"value": "忻州恒伦口腔门诊部","address": ""},
    {"value": "临汾洛基眼科医院","address": ""},
    {"value": "晋城市城区钟家庄街道办事处下辇社区卫生服务站","address": ""},
    {"value": "昔阳县乐平镇卫生院安坪分院","address": ""},
    {"value": "昔阳县乐平镇卫生院巴洲分院","address": ""},
    {"value": "阳泉市郊区开发区上五渡社区卫生服务站","address": ""},
    {"value": "太原永约振华口腔门诊部有限公司","address": ""},
    {"value": "临汾市尧都区医疗集团金殿镇卫生院","address": ""},
    {"value": "大同晋河中西医结合医院","address": ""},
    {"value": "隰县医疗集团午城镇卫生院","address": ""},
    {"value": "吕梁泰化医院","address": ""},
    {"value": "国药同煤白洞医院","address": ""},
    {"value": "大同济林医院","address": ""},
    {"value": "霍州市医疗集团北环社区卫生服务中心","address": ""},
    {"value": "临汾安定医院有限公司","address": ""},
    {"value": "晋城汇杰健康体检站","address": ""},
    {"value": "长治市潞城区店上镇卫生院（长治市潞城区医疗集团店上镇卫生院）","address": ""},
    {"value": "襄汾宝泉齿科医院","address": ""},
    {"value": "夏县建国肾康血液透析中心","address": ""},
    {"value": "长治长兴口腔医院股份有限公司","address": ""},
    {"value": "阳泉市城区新华东街社区卫生服务站","address": ""},
    {"value": "阳泉康雅口腔医院","address": ""},
    {"value": "锦纶街道王湖西社区卫生服务站","address": ""},
    {"value": "云冈区同家梁社区卫生服务中心","address": ""},
    {"value": "古交市博康门诊部","address": ""},
    {"value": "云冈区和顺泰兴第二社区卫生服务站","address": ""},
    {"value": "平定县冠山镇卫生院南坳分院","address": ""},
    {"value": "晋城长城医院","address": ""},
    {"value": "晋城市城区惠民门诊","address": ""},
    {"value": "侯马市新田乡卫生院（侯马市医疗集团新田乡卫生院）","address": ""},
    {"value": "朔州市朔城区北城街道办事处平安社区卫生服务站","address": ""},
    {"value": "平陆崇济医院","address": ""},
    {"value": "阳城阳光眼科医院","address": ""},
    {"value": "兴县人民医院","address": ""},
    {"value": "太原市万柏林区建筑街社区卫生服务站","address": ""},
    {"value": "云冈郭惠清口腔门诊部","address": ""},
    {"value": "五寨县医疗集团韩家楼中心卫生院","address": ""},
    {"value": "垣曲县解峪乡卫生院","address": ""},
    {"value": "壶关县龙泉镇卫生院","address": ""},
    {"value": "平陆凯炎康复医院","address": ""},
    {"value": "岚县秀容社区卫生服务站","address": ""},
    {"value": "中国人民解放军32703部队药材供应站","address": ""},
    {"value": "大同市新荣区郭家窑乡卫生院","address": ""},
    {"value": "太原市万柏林区朝南社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区桃园二巷西社区卫生服务站","address": ""},
    {"value": "平城国药同煤综合门诊部","address": ""},
    {"value": "清徐恒康医院","address": ""},
    {"value": "长治市屯留区张店镇卫生院","address": ""},
    {"value": "五寨县第一人民医院","address": ""},
    {"value": "长治市潞洲区太行西街街道威远门中路社区卫生服务站","address": ""},
    {"value": "吕梁市离石区中医院","address": ""},
    {"value": "稷山县医疗集团太阳乡卫生院","address": ""},
    {"value": "安泽县医疗集团中医医院","address": ""},
    {"value": "翼城安康医养结合医院","address": ""},
    {"value": "太原优选口腔门诊部","address": ""},
    {"value": "太原西山西铭医院康乐苑病区","address": ""},
    {"value": "武乡县贾豁卫生院","address": ""},
    {"value": "山阴众康医院","address": ""},
    {"value": "浮山县寨圪塔乡卫生院","address": ""},
    {"value": "朔州祥生中西医结合医院有限公司","address": ""},
    {"value": "大同美源口腔医院有限公司龙园门诊分公司","address": ""},
    {"value": "平顺怡康医养结合医院","address": ""},
    {"value": "太原市万柏林区金域阅山社区卫生服务站","address": ""},
    {"value": "左权县麻田镇泽城卫生院（左权县医疗集团麻田镇泽城卫生院）","address": ""},
    {"value": "太原市晋源区慈寿福养老服务中心医务室","address": ""},
    {"value": "灵丘县武灵镇卫生院","address": ""},
    {"value": "大同精神卫生防治院","address": ""},
    {"value": "北大医疗潞安医院太阳能医务所","address": ""},
    {"value": "晋城市蓝康门诊有限公司","address": ""},
    {"value": "榆次区北关街道社区卫生服务中心","address": ""},
    {"value": "繁峙县康复医院","address": ""},
    {"value": "离石区王志刚中医诊疗所","address": ""},
    {"value": "太原市晋源区绿地社区卫生服务站","address": ""},
    {"value": "大同现代脑科医院","address": ""},
    {"value": "吕梁市离石区医疗集团西属巴街道办社区卫生服务中心","address": ""},
    {"value": "曲沃县医疗集团北董乡中心卫生院下裴分院","address": ""},
    {"value": "应县医疗集团下马峪乡卫生院","address": ""},
    {"value": "朔州市平鲁区下水头乡卫生院","address": ""},
    {"value": "大宁县中医院","address": ""},
    {"value": "北大医疗潞安医院颐龙湾医务室","address": ""},
    {"value": "太原市晋源区晋阳湖南社区卫生服务站","address": ""},
    {"value": "黎城县医疗集团西井镇卫生院南委泉分院","address": ""},
    {"value": "芮城县风陵渡镇卫生院汉渡分院（芮城县医疗集团风陵渡镇卫生院汉渡分院）","address": ""},
    {"value": "灵石县南关镇卫生院","address": ""},
    {"value": "山西省戒毒康复医院","address": ""},
    {"value": "山西和谐医疗健康中心有限公司","address": ""},
    {"value": "平城区誉泉中医门诊部","address": ""},
    {"value": "河津市清涧办事处朝霞社区卫生服务站","address": ""},
    {"value": "山西皓雅医疗科技有限公司小店坞城北街口腔门诊部","address": ""},
    {"value": "太原市晋源区恒康口腔门诊部","address": ""},
    {"value": "晋城市城区钟家庄街道办事处凤鸣社区卫生服务站","address": ""},
    {"value": "山西省商业供销职工医院（山西气管炎专科医院）","address": ""},
    {"value": "太原市杏花岭区大北门西社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区东边街社区卫生服务站","address": ""},
    {"value": "盂县医疗集团孙家庄镇卫生院土塔分院","address": ""},
    {"value": "运城恒泰精神病医院","address": ""},
    {"value": "长治市潞州区堠北庄镇卫生院店上分院","address": ""},
    {"value": "山西省汾西县对竹中心卫生院","address": ""},
    {"value": "孝义市妇幼保健和计划生育服务中心","address": ""},
    {"value": "原平市长梁沟镇卫生院（原平市医疗集团长梁沟镇卫生院）","address": ""},
    {"value": "山西医科大学第一医院","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处贺家河社区卫生服务站","address": ""},
    {"value": "阳泉市肿瘤防治研究所","address": ""},
    {"value": "鹅城镇卫生院","address": ""},
    {"value": "尧都一博口腔门诊部","address": ""},
    {"value": "襄汾县襄陵镇卫生院","address": ""},
    {"value": "大汖温泉景区医务室","address": ""},
    {"value": "云冈区清泉街社区卫生服务中心","address": ""},
    {"value": "双塔北路社区卫生服务站","address": ""},
    {"value": "大同卫氏中医医院","address": ""},
    {"value": "长子县石哲中心卫生院岳阳分院（长子县医疗集团石哲分院岳阳分部）","address": ""},
    {"value": "沁县妇幼保健计划生育服务中心","address": ""},
    {"value": "晋城大医院赵庄分院","address": ""},
    {"value": "山阴县中医医院","address": ""},
    {"value": "运城市盐湖区北城办事处干河社区卫生服务站","address": ""},
    {"value": "应县万豪中医康复医院","address": ""},
    {"value": "静乐县丰润镇中心卫生院步六社分院","address": ""},
    {"value": "兴县华康医院","address": ""},
    {"value": "安泽县医疗集团马壁乡石槽卫生服务站","address": ""},
    {"value": "山西煤炭运销集团阳泉郊区有限公司医务室","address": ""},
    {"value": "晋煤集团总医院富士康医务室","address": ""},
    {"value": "大同宏德血液透析有限责任公司","address": ""},
    {"value": "兰花百盛煤业有限公司医务室","address": ""},
    {"value": "晋城大医院凤凰山分院","address": ""},
    {"value": "太原市尖草坪区恒大御景湾社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区晋安西街社区卫生服务站","address": ""},
    {"value": "山阴县医疗集团下喇叭乡卫生院","address": ""},
    {"value": "御东学府区社区卫生服务中心","address": ""},
    {"value": "晋中太谷夕阳红疗养院","address": ""},
    {"value": "荣康爱民精神病医院","address": ""},
    {"value": "太原市晋源区西峪街二社区卫生服务站","address": ""},
    {"value": "盂县北下庄乡卫生院东木口分院","address": ""},
    {"value": "文水县马西乡卫生院","address": ""},
    {"value": "太原市杏花岭区中涧河镇小返卫生院","address": ""},
    {"value": "吕梁市离石区凤山街道办生态园社区卫生服务站","address": ""},
    {"value": "太原科大门诊部","address": ""},
    {"value": "太原市迎泽区双塔寺二社区卫生服务站","address": ""},
    {"value": "北关龙园尚府社区卫生服务站","address": ""},
    {"value": "阳城县西河乡卫生院","address": ""},
    {"value": "灵丘惠民医院","address": ""},
    {"value": "晋中市榆次区新华街道榆和东社区卫生服务站","address": ""},
    {"value": "灵石县交口乡卫生院","address": ""},
    {"value": "乡宁县双鹤乡卫生院南崖服务站","address": ""},
    {"value": "宁武县医疗集团石家庄镇中心卫生院","address": ""},
    {"value": "沁水县龙港镇卫生院","address": ""},
    {"value": "交城县医疗集团会立卫生院","address": ""},
    {"value": "永济市蒲州镇卫生院文学分院","address": ""},
    {"value": "绛县健康医院","address": ""},
    {"value": "太原优贝口腔门诊部有限公司","address": ""},
    {"value": "盂县众康口腔医疗有限公司口腔门诊部","address": ""},
    {"value": "屯留区妇幼保健计划生育服务中心","address": ""},
    {"value": "神池县大严备乡卫生院","address": ""},
    {"value": "万荣县王显乡卫生院","address": ""},
    {"value": "沁水县龙港镇卫生院杏峪分院","address": ""},
    {"value": "泽州县医疗集团晋庙铺镇中心卫生院","address": ""},
    {"value": "沁源县王和镇中心卫生院","address": ""},
    {"value": "榆社县迎春南路社区卫生服务站","address": ""},
    {"value": "代县滨河社区卫生服务站","address": ""},
    {"value": "吕梁市离石区交口街道办丽华苑社区卫生服务站","address": ""},
    {"value": "西山煤电（集团）有限责任公司古交矿区总医院屯兰分院","address": ""},
    {"value": "太原雅美康口腔门诊部有限公司","address": ""},
    {"value": "平城区五州帝景社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区西缉虎营社区卫生服务站","address": ""},
    {"value": "曲沃仁沃医院","address": ""},
    {"value": "屯留区渔泽镇卫生院","address": ""},
    {"value": "国药同煤三医院","address": ""},
    {"value": "太原市晋源区阳光汾河湾社区卫生服务站","address": ""},
    {"value": "曲沃县妇幼保健计划生育服务中心","address": ""},
    {"value": "石楼县灵泉镇卫生院西卫分院（石楼县医疗集团灵泉镇卫生院西卫分院）","address": ""},
    {"value": "忻州市人民医院","address": ""},
    {"value": "太原市尖草坪区欣园社区卫生服务站","address": ""},
    {"value": "长治市潞州区紫金街道社区卫生服务中心","address": ""},
    {"value": "大同市康顺连锁门诊部有限公司","address": ""},
    {"value": "晋城市城区北街街道办事处社区卫生服务中心","address": ""},
    {"value": "长治市屯留区人民医院","address": ""},
    {"value": "新绛县横桥镇中心卫生院支北庄分院（新绛县医疗集团横桥镇中心卫生院支北庄分院）","address": ""},
    {"value": "晋中市榆次区锦纶街道社区卫生服务中心","address": ""},
    {"value": "襄汾温泉医院","address": ""},
    {"value": "长治市潞州区英雄中路街道东营社区卫生服务站","address": ""},
    {"value": "晋城康宁手外科医院","address": ""},
    {"value": "应县医疗集团义井乡卫生院","address": ""},
    {"value": "闻喜县桐城镇卫生院岭西东分院","address": ""},
    {"value": "忻州老年医院","address": ""},
    {"value": "宁武县人民医院","address": ""},
    {"value": "长治粮机医院","address": ""},
    {"value": "大同市云州区吉家庄乡卫生院麻峪口社区卫生服务站","address": ""},
    {"value": "临汾市尧都区滨河办事处滨河社区卫生服务中心","address": ""},
    {"value": "山西省长治经坊煤业有限公司医务室","address": ""},
    {"value": "长治市潞州区长北社区卫生服务中心","address": ""},
    {"value": "垣曲树生中医心脑血管病专科医院","address": ""},
    {"value": "隰县医疗集团城南乡卫生院","address": ""},
    {"value": "阳泉煤业（集团）有限责任公司总医院四矿医院","address": ""},
    {"value": "山西晋丰煤化工有限责任公司医务所","address": ""},
    {"value": "晋中市榆次区经纬街道第五社区卫生服务站","address": ""},
    {"value": "静乐利民医院","address": ""},
    {"value": "五台县东雷乡卫生院","address": ""},
    {"value": "右玉县医疗集团新城镇卫生院","address": ""},
    {"value": "太原市尖草坪区兴安苑社区卫生服务站","address": ""},
    {"value": "岢岚县人民医院","address": ""},
    {"value": "太原市小店区营盘社区卫生服务中心","address": ""},
    {"value": "新绛县泉掌镇卫生院（新绛县医疗集团泉掌镇卫生院）","address": ""},
    {"value": "晋中榆次戴氏亚美口腔门诊部（有限公司）","address": ""},
    {"value": "朔州祥生中西医结合医院","address": ""},
    {"value": "晋城市城区钟家庄街道办事处晓庄社区卫生服务站","address": ""},
    {"value": "晋中榆次宝来乐门诊部（有限公司）","address": ""},
    {"value": "保德县医疗集团东关镇分院","address": ""},
    {"value": "山西长治惠丰医院","address": ""},
    {"value": "大同市平城区欧乐口腔门诊部","address": ""},
    {"value": "古交市东曲街道办事处建设路社区卫生服务站","address": ""},
    {"value": "太原十安专科门诊部","address": ""},
    {"value": "灵丘瑞福医院","address": ""},
    {"value": "临汾市尧都区医疗集团县底镇卫生院城隍分院","address": ""},
    {"value": "兴县交楼申乡卫生院（兴县医疗集团交楼申乡卫生院））","address": ""},
    {"value": "五台县沟南乡卫生院","address": ""},
    {"value": "首钢长治钢铁有限公司医院","address": ""},
    {"value": "运城骨科矫形医院","address": ""},
    {"value": "临汾馨康源血液透析中心","address": ""},
    {"value": "广灵县医疗集团人民医院","address": ""},
    {"value": "晋中市榆次区晋华街道道西社区卫生服务站","address": ""},
    {"value": "忻州市忻府区医疗集团","address": ""},
    {"value": "兴县东会乡卫生院","address": ""},
    {"value": "阳泉市矿区医疗集团（阳泉市矿区医院）大院社区卫生服务站","address": ""},
    {"value": "山阴县医疗集团岱岳镇分院","address": ""},
    {"value": "太原市迎泽区东岗路社区卫生服务站","address": ""},
    {"value": "晋中市榆次区新建街道液铁社区卫生服务站","address": ""},
    {"value": "屯留县麟绛镇卫生院","address": ""},
    {"value": "壶关县石坡乡卫生院","address": ""},
    {"value": "阳城县人民医院新阳东街门诊","address": ""},
    {"value": "临县曲峪镇卫生院小甲头社区卫生服务站","address": ""},
    {"value": "晋城市城区妇幼保健计划生育服务中心","address": ""},
    {"value": "兴县瓦塘镇中心卫生院","address": ""},
    {"value": "忻州市忻府区三交镇中心卫生院阳坡分院","address": ""},
    {"value": "太原尤根艾口腔门诊部","address": ""},
    {"value": "山西三针脑血管病医院","address": ""},
    {"value": "文水县孝义镇卫生院","address": ""},
    {"value": "太原雅贝鑫口腔门诊部","address": ""},
    {"value": "侯马市路东街道办事处侯马发电厂社区卫生服务站","address": ""},
    {"value": "太原市中医医院","address": ""},
    {"value": "长治市潞州区东街街道社区卫生服务中心","address": ""},
    {"value": "太原东润口腔门诊部","address": ""},
    {"value": "太原市小店区恒大社区卫生服务站","address": ""},
    {"value": "大同朝聚安康眼科医院有限公司","address": ""},
    {"value": "繁峙县精神病医院","address": ""},
    {"value": "运城德全骨科医院","address": ""},
    {"value": "太原中西医结合医院","address": ""},
    {"value": "吕梁学院医院","address": ""},
    {"value": "泽州县医疗集团山河镇中心卫生院","address": ""},
    {"value": "洪洞新兴外科医院有限责任公司","address": ""},
    {"value": "原平市轩岗镇卫生院后口社区卫生服务站","address": ""},
    {"value": "原平市沿沟乡卫生院","address": ""},
    {"value": "晋中榆次欧美洁口腔门诊部","address": ""},
    {"value": "文水明仁医院","address": ""},
    {"value": "襄汾县赵康镇卫生院","address": ""},
    {"value": "长治市潞州区英雄南路街道演武社区卫生服务站","address": ""},
    {"value": "太原市万柏林区南寒社区卫生服务中心","address": ""},
    {"value": "太原马平口腔门诊部有限责任公司","address": ""},
    {"value": "太原市尖草坪区恒大名都社区卫生服务站","address": ""},
    {"value": "吉县妇幼保健计划生育服务中心","address": ""},
    {"value": "晋城和美大健康体检中心","address": ""},
    {"value": "吕梁市离石区滨河街道办龙山社区卫生服务站","address": ""},
    {"value": "临县安家庄乡卫生院","address": ""},
    {"value": "平陆县医疗集团三门镇卫生院","address": ""},
    {"value": "山西医科大学口腔医院","address": ""},
    {"value": "大同创生骨科医院","address": ""},
    {"value": "朔州市朔城区第二人民医院","address": ""},
    {"value": "霍州煤电总医院","address": ""},
    {"value": "孝义交通门诊","address": ""},
    {"value": "大同市云冈区新诺口腔门诊部有限公司","address": ""},
    {"value": "长治市潞州区第二人民医院（长治市精神卫生中心）","address": ""},
    {"value": "中阳县妇幼保健计划生育服务中心","address": ""},
    {"value": "运城市盐湖区三路里镇卫生院","address": ""},
    {"value": "祁县妇幼保健计划生育服务中心","address": ""},
    {"value": "闻喜县薛店镇卫生院","address": ""},
    {"value": "山西省戒毒康复管理所附属医院","address": ""},
    {"value": "太原市万柏林区白家庄社区卫生服务中心","address": ""},
    {"value": "阳城县河北镇卫生院","address": ""},
    {"value": "朔州中医药集团医院","address": ""},
    {"value": "临汾市尧都区辛寺街办事处挂甲社区卫生服务站","address": ""},
    {"value": "朔州澳利康医院","address": ""},
    {"value": "太原市杏花岭区巨轮社区卫生服务中心","address": ""},
    {"value": "静乐县中医院","address": ""},
    {"value": "大同市新荣区古店镇卫生院","address": ""},
    {"value": "平遥县宁固中心卫生院净化卫生站","address": ""},
    {"value": "侯马市晋都西路社区卫生服务站","address": ""},
    {"value": "太原肤康中医皮肤病医院有限公司","address": ""},
    {"value": "临汾市尧都区医疗集团乔李镇卫生院","address": ""},
    {"value": "阳泉阳大医院","address": ""},
    {"value": "太谷县福康老年公寓医务室","address": ""},
    {"value": "河津市城区办事处文苑社区卫生服务站","address": ""},
    {"value": "曲沃县人民医院","address": ""},
    {"value": "大同安定精神病医院","address": ""},
    {"value": "隰县医疗集团黄土镇卫生院","address": ""},
    {"value": "河津市清涧社区卫生服务中心龙门分院","address": ""},
    {"value": "保德县窑洼乡卫生院","address": ""},
    {"value": "洪洞县山目乡卫生院（洪洞县医疗集团山目乡卫生院）","address": ""},
    {"value": "吉县医疗集团（吉县医疗集团人民医院）","address": ""},
    {"value": "安泽爱心养老服务中心医务室","address": ""},
    {"value": "太原纳尔斯口腔门诊部有限公司贞观口腔门诊部","address": ""},
    {"value": "中铁十七局集团有限公司中心医院","address": ""},
    {"value": "临汾市尧都区医疗集团尧庙镇卫生院","address": ""},
    {"value": "原平市西镇乡卫生院薛孤社区卫生服务站","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处七里河社区卫生服务站","address": ""},
    {"value": "临汾西京眼耳鼻喉医院","address": ""},
    {"value": "晋城大医院成庄分院","address": ""},
    {"value": "五台县陈家庄乡卫生院","address": ""},
    {"value": "临县曲峪镇卫生院、临县医疗集团曲峪镇卫生院","address": ""},
    {"value": "代县上磨坊乡卫生院","address": ""},
    {"value": "大同市新荣区堡子湾乡卫生院拒墙分院","address": ""},
    {"value": "太原市迎泽区桥东社区卫生服务中心","address": ""},
    {"value": "五台县医疗集团门限石乡卫生院","address": ""},
    {"value": "浑源县医疗集团驼峰乡卫生院","address": ""},
    {"value": "云冈区平泉盛秀苑社区卫生服务站","address": ""},
    {"value": "山西省特殊教育中等专业学校附属按摩医院","address": ""},
    {"value": "阳高县古城镇卫生院","address": ""},
    {"value": "大同市平城润和综合门诊部","address": ""},
    {"value": "沁源县沁河镇卫生院李元分院（沁源县医疗集团）（山西沁新能源集团股份有限公司职工医院）","address": ""},
    {"value": "中阳县医疗集团中医院","address": ""},
    {"value": "河曲县妇幼保健服务中心","address": ""},
    {"value": "泽州县医疗集团高都镇中心卫生院","address": ""},
    {"value": "临县凤凰社区卫生服务中心","address": ""},
    {"value": "阳泉天佑安康安宁疗护中心","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处安泰街社区卫生服务站","address": ""},
    {"value": "忻州市社会福利精神康宁医院","address": ""},
    {"value": "太原璞真中医门诊部","address": ""},
    {"value": "阳高县狮子屯乡卫生院后营分院","address": ""},
    {"value": "山西长治惠丰医院12院楼区医务室","address": ""},
    {"value": "汾西县团柏乡卫生院","address": ""},
    {"value": "太原皓圣口腔门诊部","address": ""},
    {"value": "介休晓文口腔门诊部","address": ""},
    {"value": "盐湖嘉悦口腔门诊部","address": ""},
    {"value": "盐湖惠美佳口腔门诊部","address": ""},
    {"value": "安泽县医疗集团府城镇三交卫生服务站","address": ""},
    {"value": "安泽县医疗集团府城镇卫生院","address": ""},
    {"value": "安泽县医疗集团和川中心卫生院","address": ""},
    {"value": "安泽县医疗集团良马镇卫生院","address": ""},
    {"value": "安泽县医疗集团良马镇英寨服务站","address": ""},
    {"value": "安泽县医疗集团马壁镇卫生院","address": ""},
    {"value": "安泽县医疗集团冀氏中心卫生院","address": ""},
    {"value": "安泽县医疗集团唐城镇中心卫生院","address": ""},
    {"value": "蒲县妇幼保健计划生育服务中心","address": ""},
    {"value": "兴县孟家坪乡卫生院小善分院","address": ""},
    {"value": "朔州华仲康宁医院","address": ""},
    {"value": "晋城市思迈尔口腔门诊有限公司","address": ""},
    {"value": "阳高县医疗集团大白登镇卫生院","address": ""},
    {"value": "山西大同大学附属医院","address": ""},
    {"value": "河津市人民医院王家岭卫生服务室","address": ""},
    {"value": "河曲县六固乡卫生院寺也分院","address": ""},
    {"value": "晋中市榆次区安宁街道纺配社区卫生服务站","address": ""},
    {"value": "平定县锁簧镇卫生院（平定县锁簧镇妇幼保健计划生育服务站、平定县医疗集团锁簧镇分院）","address": ""},
    {"value": "昔阳县界都乡卫生院瓦邱分院","address": ""},
    {"value": "运城市盐湖区上王乡卫生院","address": ""},
    {"value": "长治市潞州区太行西街街道佳美绿洲社区卫生服务站","address": ""},
    {"value": "侯马仁安医院有限责任公司","address": ""},
    {"value": "晋城市城区西街街道办事处西大街社区卫生服务站","address": ""},
    {"value": "太原市迎泽区水西关三社区卫生服务站","address": ""},
    {"value": "五台县台城镇卫生院","address": ""},
    {"value": "太原市迎泽区劲松社区卫生服务站","address": ""},
    {"value": "洪洞县山焦医院","address": ""},
    {"value": "古交西山镇城底医院","address": ""},
    {"value": "定昌镇西段庄卫生二所","address": ""},
    {"value": "临汾市尧都区南街办事处尧园社区卫生服务站","address": ""},
    {"value": "柳林县医疗集团三交镇中心卫生院分院","address": ""},
    {"value": "平定县冠山镇卫生院十字街分院","address": ""},
    {"value": "大同市云州区杜庄乡卫生院","address": ""},
    {"value": "太原市晋源区一电厂社区卫生服务站","address": ""},
    {"value": "洪洞县曲亭镇中心卫生院（洪洞县医疗集团曲亭镇中心卫生院）","address": ""},
    {"value": "曲沃福荣口腔门诊部","address": ""},
    {"value": "晋城市城区西上庄街道办事处西马匠社区卫生服务站","address": ""},
    {"value": "阳城县董封乡卫生院","address": ""},
    {"value": "朔州市朔城区南城街道办事处东兴街社区卫生服务站","address": ""},
    {"value": "运城安国中医结核病医院","address": ""},
    {"value": "宁武康福医院","address": ""},
    {"value": "太原市尖草坪区钢东社区卫生服务站","address": ""},
    {"value": "临猗县角杯镇中心卫生院（临猗县医疗集团角杯镇中心卫生院）","address": ""},
    {"value": "忻府区阳坡乡卫生院后河堡社区卫生服务站","address": ""},
    {"value": "平定县冠山镇东关街社区卫生服务站","address": ""},
    {"value": "永济市栲栳镇卫生院常青分院","address": ""},
    {"value": "晋城市城区东街街道办事处社区卫服务中心","address": ""},
    {"value": "临汾市尧都区医疗集团土门镇卫生院","address": ""},
    {"value": "太原钢铁（集团）有限公司总医院曙光医院","address": ""},
    {"value": "晋城职业技术学院医务室","address": ""},
    {"value": "霍州益康血液透析中心","address": ""},
    {"value": "太钢总医院尖山分院","address": ""},
    {"value": "太原康乃馨血液透析中心","address": ""},
    {"value": "平陆西城肝胆病专科医院","address": ""},
    {"value": "河曲县医疗集团社梁乡卫生院","address": ""},
    {"value": "永济市虞乡镇卫生院（永济市医疗集团虞乡镇卫生院）","address": ""},
    {"value": "灵石县绵山眼科门诊","address": ""},
    {"value": "清徐县孟封镇中心卫生院","address": ""},
    {"value": "应县夕阳红华康医院","address": ""},
    {"value": "武乡县中医院","address": ""},
    {"value": "晋中安康医院","address": ""},
    {"value": "太原市万柏林区杜儿坪社区卫生服务中心","address": ""},
    {"value": "平城区新建南路社区卫生服务中心","address": ""},
    {"value": "运城明济堂乳腺病专科医院","address": ""},
    {"value": "晋能控股煤业集团有限公司职业病防治院","address": ""},
    {"value": "朔州芙桦苑康复医院","address": ""},
    {"value": "平城区大庆路社区卫生服务中心","address": ""},
    {"value": "山西众植齿科医院","address": ""},
    {"value": "北大医疗潞安医院西区医务室","address": ""},
    {"value": "阳高县大白登镇卫生院潘寺分院","address": ""},
    {"value": "大同市云冈区民胜世纪瑞旺社区卫生服务站","address": ""},
    {"value": "灵空山镇中心卫生院","address": ""},
    {"value": "宁武县迭台寺乡圪谬分院","address": ""},
    {"value": "泽州县医疗集团南岭乡卫生院","address": ""},
    {"value": "大同顺泰医院","address": ""},
    {"value": "石楼县仁康医院","address": ""},
    {"value": "临汾市尧都区滨河办事处郭家庄社区卫生服务站","address": ""},
    {"value": "汾西县医疗集团邢家要乡卫生院","address": ""},
    {"value": "太原市民营区社区卫生服务中心","address": ""},
    {"value": "山西泰济医院有限公司泰济医院","address": ""},
    {"value": "山西汾西中兴煤业有限责任公司医务室","address": ""},
    {"value": "太原市三和堂中医馆有限公司三和堂中医门诊部","address": ""},
    {"value": "太原恒伦新建路口腔门诊部有限公司","address": ""},
    {"value": "永济市蒲园社区卫生服务站","address": ""},
    {"value": "沁水县十里乡卫生院东峪分院","address": ""},
    {"value": "运城枫之逸护理院","address": ""},
    {"value": "原平北大医院","address": ""},
    {"value": "偏关县窑头乡卫生院","address": ""},
    {"value": "万荣县荣河镇卫生院宝井分院","address": ""},
    {"value": "山西襄矿集团职工医院","address": ""},
    {"value": "云冈区平泉南秀苑社区卫生服务站","address": ""},
    {"value": "原平市东社镇卫生院上庄社区卫生服务站","address": ""},
    {"value": "太原胜利桥黄河门诊部","address": ""},
    {"value": "太原化建挚诚口腔门诊部","address": ""},
    {"value": "绛县中城区第二社区卫生服务站","address": ""},
    {"value": "永济肛肠医院","address": ""},
    {"value": "朔州市朔城区医疗集团南榆林乡卫生院","address": ""},
    {"value": "浑源县中医医院","address": ""},
    {"value": "芮城县永乐社区第二卫生服务站","address": ""},
    {"value": "长子县仁康医院有限公司","address": ""},
    {"value": "太原嘉廷医疗服务有限公司柳巷口腔门诊部","address": ""},
    {"value": "临汾市尧都区路东办事处金汾社区卫生服务站","address": ""},
    {"value": "浑源县医疗集团黄花滩乡卫生院官王铺分院","address": ""},
    {"value": "阳泉市郊区泉西社区卫生服务站","address": ""},
    {"value": "夏县瑶峰镇卫生院郭道分院","address": ""},
    {"value": "太原菩瑞中医门诊部有限公司","address": ""},
    {"value": "阳泉市城区小阳泉北社区卫生服务站","address": ""},
    {"value": "岢岚县温泉乡卫生院","address": ""},
    {"value": "浮山县医疗集团槐埝乡卫生院","address": ""},
    {"value": "太原市杏花岭区背圪洞社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区坝陵北街社区卫生服务站","address": ""},
    {"value": "石楼县医疗集团（石楼县人民医院）","address": ""},
    {"value": "国药大同口腔医院","address": ""},
    {"value": "盂县医疗集团孙家庄镇卫生院","address": ""},
    {"value": "晋城经济开发区社区卫生服务中心","address": ""},
    {"value": "五寨县杏岭子乡卫生院（五寨县医疗集团杏岭子乡卫生院）","address": ""},
    {"value": "运城市盐湖区东城办事处高家垣社区卫生服务站","address": ""},
    {"value": "太原迎新街全富专科门诊部","address": ""},
    {"value": "高平康宁医院","address": ""},
    {"value": "太原万柏林区冀康口腔专科门诊部","address": ""},
    {"value": "太原市第三人民医院（太原市传染病医院、太原市肝病研究所）","address": ""},
    {"value": "运城市盐湖区新城区社区卫生服务中心（运城同德医院北院）","address": ""},
    {"value": "太原市迎泽区新建南路第一社区卫生服务站","address": ""},
    {"value": "阳白乡卫生院","address": ""},
    {"value": "运城市盐湖区东城办事处吉祥社区卫生服务站","address": ""},
    {"value": "中阳县人民医院","address": ""},
    {"value": "太原依齿口腔门诊部","address": ""},
    {"value": "阳城城西门诊部","address": ""},
    {"value": "太原德易门诊部","address": ""},
    {"value": "太原市万柏林区长西专科门诊部","address": ""},
    {"value": "大同谷仁堂中医门诊有限公司","address": ""},
    {"value": "大同市云州区西坪镇卫生院中高庄社区卫生服务站","address": ""},
    {"value": "振华南街新世纪花园社区卫生服务站","address": ""},
    {"value": "霍州志新齿科医院有限公司","address": ""},
    {"value": "兴县中医院","address": ""},
    {"value": "太原市晋源区晋源社区卫生服务中心","address": ""},
    {"value": "定襄县医疗集团河边镇中心卫生院南庄分院","address": ""},
    {"value": "孝义市医疗集团驿马分院（孝义市驿马乡卫生院）","address": ""},
    {"value": "侯马市路东街道办事处社区卫生服务中心","address": ""},
    {"value": "阳城县横河镇卫生院杨柏分院","address": ""},
    {"value": "岢岚县神堂坪乡卫生院","address": ""},
    {"value": "太原美博口腔门诊部","address": ""},
    {"value": "代县骨科医院","address": ""},
    {"value": "盐湖春娟口腔门诊部","address": ""},
    {"value": "应县医疗集团白马石乡卫生院三条岭分院","address": ""},
    {"value": "太原鼎植口腔医院","address": ""},
    {"value": "静乐县妇幼保健服务中心","address": ""},
    {"value": "吕梁中医结核骨病医院","address": ""},
    {"value": "太原市迎泽区解南社区卫生服务中心","address": ""},
    {"value": "原平市妇幼保健服务中心","address": ""},
    {"value": "泽州县医疗集团大东沟镇卫生院","address": ""},
    {"value": "晋中榆次精品雅康口腔门诊部（有限公司）","address": ""},
    {"value": "阳泉友爱医院","address": ""},
    {"value": "太谷县侯城乡卫生院（太谷县医疗集团侯城乡卫生院）","address": ""},
    {"value": "广灵县博爱妇幼专科医院","address": ""},
    {"value": "大同新建康医院","address": ""},
    {"value": "运城市盐湖区安邑办事处外滩首府社区卫生服务站","address": ""},
    {"value": "泽州县医疗集团山河镇衙道卫生院","address": ""},
    {"value": "翼城县妇幼保健计划生育服务中心","address": ""},
    {"value": "朔州肛肠医院","address": ""},
    {"value": "山西晋柴机械制造有限公司职工医院","address": ""},
    {"value": "河曲县医疗集团巡镇镇卫生院","address": ""},
    {"value": "陵川县中医院","address": ""},
    {"value": "忻州市忻府区东楼乡卫生院（忻州市忻府区医疗集团东楼卫生院）","address": ""},
    {"value": "广灵县医疗集团壶泉镇卫生院王洼分院","address": ""},
    {"value": "大同怡宁医院有限公司","address": ""},
    {"value": "阳曲县医疗集团（阳曲县医疗集团人民医院、阳曲县人民医院）","address": ""},
    {"value": "浑源县医疗集团吴城乡卫生院大洼分院","address": ""},
    {"value": "太原市小店区煤机社区卫生服务站","address": ""},
    {"value": "霍州市南环社区卫生服务站","address": ""},
    {"value": "临县百嘉医院","address": ""},
    {"value": "左云县马道头乡郭家坪","address": ""},
    {"value": "晋中爱尔眼科医院","address": ""},
    {"value": "国药控股山西有限公司太原唐槐园区正源国医堂中医门诊部","address": ""},
    {"value": "阳泉市精神康宁医院","address": ""},
    {"value": "晋中市榆次区安宁街道鸣李社区卫生服务站","address": ""},
    {"value": "武乡县蟠龙中心卫生院","address": ""},
    {"value": "壶关县龙泉镇新南社区树人南卫生服务站","address": ""},
    {"value": "盐湖谢静口腔门诊部","address": ""},
    {"value": "娄烦县中医医院","address": ""},
    {"value": "孝义市医疗集团南阳分院城区服务站","address": ""},
    {"value": "清徐县清源镇卫生院","address": ""},
    {"value": "吕梁市离石区凤山街道办吉祥社区卫生服务站","address": ""},
    {"value": "榆社县西马乡卫生院","address": ""},
    {"value": "偏关县医疗集团楼沟乡卫生院","address": ""},
    {"value": "太原市尖草坪区江阳社区卫生服务站","address": ""},
    {"value": "太原理工大学校医院","address": ""},
    {"value": "孝义市医疗集团下堡分院城区服务站","address": ""},
    {"value": "大同市坤特中医门诊有限公司","address": ""},
    {"value": "晋城新太行中医医院","address": ""},
    {"value": "霍州市医疗集团鼓楼社区卫生服务中心","address": ""},
    {"value": "山西汾西矿业集团南关煤业有限责任公司综合门诊部","address": ""},
    {"value": "太原市杏花岭区大东关社区卫生服务中心","address": ""},
    {"value": "宁武县阳方口骨伤医院","address": ""},
    {"value": "大同糖尿病医院","address": ""},
    {"value": "太原市杏花岭区道场沟社区卫生服务站","address": ""},
    {"value": "文水仁爱医院","address": ""},
    {"value": "汾阳市演武镇卫生院","address": ""},
    {"value": "孝义市医疗集团振兴分院（孝义市振兴社区卫生服务中心）","address": ""},
    {"value": "太原市小店区星河湾社区卫生服务站","address": ""},
    {"value": "太原市尖草坪区汇丰苑新兴社区卫生服务站","address": ""},
    {"value": "长治市潞城区微子镇卫生院","address": ""},
    {"value": "金刚堰西社区卫生服务站","address": ""},
    {"value": "临汾市尧都区路东办事处三元社区卫生服务站","address": ""},
    {"value": "文水阳光医院","address": ""},
    {"value": "晋城市中医医院筹建事务中心综合门诊部","address": ""},
    {"value": "太原市辰居物业管理有限公司门诊部","address": ""},
    {"value": "古交市马兰镇中心卫生院（古交市医疗集团马兰镇中心卫生院）","address": ""},
    {"value": "浮山县妇幼保健计划生育服务中心","address": ""},
    {"value": "运城市盐湖区东城办事处振兴庄社区卫生服务站","address": ""},
    {"value": "中阳天佑康复医院有限公司","address": ""},
    {"value": "太原市晋源区新城社区卫生服务站","address": ""},
    {"value": "灵石县翠峰镇卫生院张家庄分院","address": ""},
    {"value": "平顺县北耽车乡卫生院","address": ""},
    {"value": "太原齿生福口腔门诊部","address": ""},
    {"value": "太原恒伦学府苑口腔门诊部","address": ""},
    {"value": "太原市小店区首开社区卫生服务站","address": ""},
    {"value": "北京同仁堂太原医疗管理连锁有限责任公司中医医院","address": ""},
    {"value": "文水县开栅镇中心卫生院","address": ""},
    {"value": "临县济民血液透析中心","address": ""},
    {"value": "吕梁市直机关综合门诊部","address": ""},
    {"value": "太原市杏花岭区小枣沟社区卫生服务站","address": ""},
    {"value": "壶关县树掌镇中心卫生院","address": ""},
    {"value": "盂县民康医院有限公司","address": ""},
    {"value": "长治市威远手足外科医院有限公司","address": ""},
    {"value": "柳林县医疗集团成家庄镇中心卫生院","address": ""},
    {"value": "太原众牙口腔门诊部","address": ""},
    {"value": "阳泉市城区南山社区卫生服务中心","address": ""},
    {"value": "大同市平城区古城街道社区卫生服务中心","address": ""},
    {"value": "襄汾县古城镇卫生院","address": ""},
    {"value": "大同市云冈区鸦儿崖乡卫生院","address": ""},
    {"value": "太原众博仕口腔门诊部有限公司","address": ""},
    {"value": "太原市太航医院","address": ""},
    {"value": "朔州市朔城区烧创伤医院","address": ""},
    {"value": "晋城市城区南街街道办事处金华社区卫生服务站","address": ""},
    {"value": "新绛县龙兴镇卫生院店头分院","address": ""},
    {"value": "应县利民医院","address": ""},
    {"value": "山西兰花煤炭实业集团有限公司大阳分公司职工医务室","address": ""},
    {"value": "翼城县隆化中心卫生院（翼城县医疗集团隆化中心卫生院、隆化镇妇幼保健计划生育服务站）","address": ""},
    {"value": "宁武县妇幼保健服务中心","address": ""},
    {"value": "太原惠美信口腔有限公司尖草坪千峰北路口腔门诊部","address": ""},
    {"value": "云冈区忻州窑社区卫生服务站","address": ""},
    {"value": "西山煤电（集团）有限责任公司职工总医院官地分院","address": ""},
    {"value": "沁县故县镇中心卫生院","address": ""},
    {"value": "古交市西曲社区卫生服务中心","address": ""},
    {"value": "蒲县医疗集团","address": ""},
    {"value": "黑龙关镇卫生院","address": ""},
    {"value": "蒲县医疗集团","address": ""},
    {"value": "太林乡卫生院","address": ""},
    {"value": "蒲县医疗集团","address": ""},
    {"value": "古县乡卫生院","address": ""},
    {"value": "蒲县医疗集团山中乡卫生院山口","address": ""},
    {"value": "分院","address": ""},
    {"value": "蒲县医疗集团克城镇卫生院公峪分院","address": ""},
    {"value": "蒲县医疗集团黑龙关镇卫生院化乐分院","address": ""},
    {"value": "山西工商学院北格校区医务室","address": ""},
    {"value": "运城市盐湖区北相镇卫生院","address": ""},
    {"value": "繁峙县普尔泰中西医结合医院有限公司","address": ""},
    {"value": "大同市云冈区新平旺安全路社区卫生服务站","address": ""},
    {"value": "岚县康宁医院有限责任公司","address": ""},
    {"value": "长治市潞州区大辛庄镇大化社区卫生服务站","address": ""},
    {"value": "阳泉市城区义井镇卫生院","address": ""},
    {"value": "阳高众和医院","address": ""},
    {"value": "平定县冠山镇新建路社区卫生服务站","address": ""},
    {"value": "平顺县妇幼保健计划生育服务中心","address": ""},
    {"value": "乡宁县医疗集团昌宁镇卫生院","address": ""},
    {"value": "中阳县医疗集团暖泉镇卫生院","address": ""},
    {"value": "岚县城南惠仁卫生服务站","address": ""},
    {"value": "繁峙县神堂堡乡卫生院","address": ""},
    {"value": "长治市潞州区太行西街街道长丰社区卫生服务站","address": ""},
    {"value": "太原美浩口腔门诊有限责任公司万柏林和平北路口腔门诊部","address": ""},
    {"value": "长治潞州赢德医院","address": ""},
    {"value": "夏县泗交镇中心卫生院","address": ""},
    {"value": "柳林县医疗集团石西乡卫生院","address": ""},
    {"value": "襄汾微笑口腔门诊部","address": ""},
    {"value": "晋能控股装备制造集团金鼎山西煤机有限责任公司医务所","address": ""},
    {"value": "平城区绿地璀璨天城社区卫生服务站","address": ""},
    {"value": "河津市人民医院电厂医疗服务站","address": ""},
    {"value": "城区向阳里源茂街社区卫生服务站","address": ""},
    {"value": "定襄县受禄乡中心卫生院(定襄县医疗集团受禄乡中心卫生院）","address": ""},
    {"value": "太原市杏花岭区上北关社区卫生服务站","address": ""},
    {"value": "孝义市新义街道办事处人印社区卫生服务站","address": ""},
    {"value": "晋中诚医堂中医门诊部","address": ""},
    {"value": "运城市盐湖区北城办事处恒大绿洲社区卫生服务站","address": ""},
    {"value": "运城爱尔眼科医院","address": ""},
    {"value": "大同精典口腔门诊部有限责任公司","address": ""},
    {"value": "阳城县东冶镇中心卫生院三窑分院","address": ""},
    {"value": "平城区振华口腔门诊部","address": ""},
    {"value": "朔州市朔城区北城街道办事处古北街社区卫生服务站","address": ""},
    {"value": "盂县福珍门诊部有限公司金龙门诊","address": ""},
    {"value": "太原市万柏林区西华苑社区卫生服务站","address": ""},
    {"value": "介休市西关社区卫生服务中心","address": ""},
    {"value": "太原晋康普通专科门诊部","address": ""},
    {"value": "平城金域口腔门诊部","address": ""},
    {"value": "晋中市第二人民医院","address": ""},
    {"value": "盐湖唯美口腔门诊部","address": ""},
    {"value": "芮城县西陌镇卫生院","address": ""},
    {"value": "大同市平城区开源街道开源嘉苑社区卫生服务站","address": ""},
    {"value": "芮城县魏园社区卫生服务站","address": ""},
    {"value": "新绛县万安镇卫生院","address": ""},
    {"value": "吕梁市离石区滨河街道办永宁东路社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区机床西社区卫生服务站","address": ""},
    {"value": "临汾市尧都区医疗集团一平垣乡卫生院","address": ""},
    {"value": "太原康达口腔门诊有限公司小店平阳南路口腔门诊部","address": ""},
    {"value": "霍州市医疗集团辛置镇卫生院","address": ""},
    {"value": "古交市东曲街道办事处川东小区社区卫生服务站","address": ""},
    {"value": "太原市万柏林区铁北社区卫生服务站","address": ""},
    {"value": "沁水县妇幼保健计划生育服务中心","address": ""},
    {"value": "长治市潞州区太行东街街道长兴北路社区卫生服务站","address": ""},
    {"value": "岢岚县爱民医院","address": ""},
    {"value": "文水金康医院","address": ""},
    {"value": "国药风雷医院","address": ""},
    {"value": "临县医疗集团雷家碛乡卫生院","address": ""},
    {"value": "乡宁县医疗集团管头镇卫生院下善分院","address": ""},
    {"value": "阳高精艺口腔医院","address": ""},
    {"value": "朔州市平鲁区南山社区卫生服务站","address": ""},
    {"value": "临猗县北景乡中心卫生院","address": ""},
    {"value": "山西正悦口腔门诊有限公司万柏林雅苑门诊部","address": ""},
    {"value": "太原美齿口腔门诊部","address": ""},
    {"value": "石楼县妇幼保健和计划生育服务中心","address": ""},
    {"value": "盂县仙人中心卫生院","address": ""},
    {"value": "太原龙橙口腔门诊有限公司万柏林南内环口腔门诊部","address": ""},
    {"value": "山西盛大齿科医院有限公司千峰路门诊部","address": ""},
    {"value": "太原市杏花岭区桃园二巷北社区卫生服务站","address": ""},
    {"value": "永济市第二人民医院清华分院","address": ""},
    {"value": "大同友谊医院","address": ""},
    {"value": "原平市沿沟乡卫生院大芳社区卫生服务站","address": ""},
    {"value": "临汾市尧都区医疗集团乡贤街办事处社区卫生服务中心","address": ""},
    {"value": "大同市三九八康复医院","address": ""},
    {"value": "汾阳市杨家庄镇卫生院","address": ""},
    {"value": "浑源县医疗集团西坊城镇卫生院","address": ""},
    {"value": "山西汾西矿业（集团）有限责任公司中盛煤业分公司职工医院","address": ""},
    {"value": "晋中榆次瑞尔口腔门诊部","address": ""},
    {"value": "山西尚宁健康体检中心有限公司小店体育西路综合门诊部","address": ""},
    {"value": "北石店镇鉴园社区卫生服务站","address": ""},
    {"value": "柳林县医疗集团陈家湾乡中心卫生院","address": ""},
    {"value": "应县陆元康心理医院","address": ""},
    {"value": "晋城大医院古书院分院","address": ""},
    {"value": "向阳里工农路社区卫生服务站","address": ""},
    {"value": "介休常氏骨伤科医院","address": ""},
    {"value": "太原市万柏林区义井东街社区卫生服务站","address": ""},
    {"value": "浑源县人民医院","address": ""},
    {"value": "忻州市忻府区高城乡卫生院","address": ""},
    {"value": "临汾市尧都区乡贤街办事处贡院社区卫生服务站","address": ""},
    {"value": "太原市小店区坞城社区卫生服务中心","address": ""},
    {"value": "应县东城医院","address": ""},
    {"value": "朔州市平鲁区康益门诊部","address": ""},
    {"value": "大同惠林医院有限公司","address": ""},
    {"value": "交城县医疗集团西营镇卫生院","address": ""},
    {"value": "方山县城商贸社区卫生服务中心","address": ""},
    {"value": "应县医疗集团义井乡卫生院边耀分院","address": ""},
    {"value": "乡宁县双鹤乡卫生院大坪服务站","address": ""},
    {"value": "陵川鸿润精神病医院","address": ""},
    {"value": "平城区建华口腔门诊部","address": ""},
    {"value": "芮城江成口腔医院","address": ""},
    {"value": "临汾市侯马经济开发区文良齿科医院有限公司","address": ""},
    {"value": "天镇佑安医院","address": ""},
    {"value": "太原市太航医院北院区","address": ""},
    {"value": "太原奇欣医疗服务有限公司万柏林万兴综合门诊部","address": ""},
    {"value": "定襄天宝集团医务室","address": ""},
    {"value": "运城市眼科医院","address": ""},
    {"value": "太原市晋源区洁欣口腔门诊部","address": ""},
    {"value": "孝义市胜溪门诊部","address": ""},
    {"value": "大同美中嘉和肿瘤医院有限责任公司","address": ""},
    {"value": "吕梁市离石区莲花池街道办昌宁居委社区卫生服务站","address": ""},
    {"value": "云冈区新诺口腔门诊部","address": ""},
    {"value": "山西师范大学医院","address": ""},
    {"value": "河津市城区办事处府西社区卫生服务站","address": ""},
    {"value": "灵石县南关镇卫生院富家滩分院","address": ""},
    {"value": "太原市小店区东岗路二社区卫生服务站","address": ""},
    {"value": "太原市尖草坪区向阳镇卫生院","address": ""},
    {"value": "临县中医院","address": ""},
    {"value": "太原市小店区学府街道社区卫生服务中心","address": ""},
    {"value": "晋中市榆次区安宁街道电力社区卫生服务站","address": ""},
    {"value": "太原恒伦桃园口腔门诊部有限公司","address": ""},
    {"value": "山西省天镇县民生医院有限公司","address": ""},
    {"value": "晋城市城区钟家庄街道办事处汇仟社区卫生服务站","address": ""},
    {"value": "长治市潞州区太行西街街道康宝社区卫生服务站","address": ""},
    {"value": "阳泉康贝齿科医院","address": ""},
    {"value": "繁峙永康医院","address": ""},
    {"value": "太原市迎泽区建北社区卫生服务站","address": ""},
    {"value": "山西天慈医院","address": ""},
    {"value": "阳泉市郊区医疗集团西南舁分院（阳泉市郊区西南舁乡卫生院）","address": ""},
    {"value": "临汾市尧都区路东办事处社区卫生服务中心","address": ""},
    {"value": "娄烦县米峪镇乡卫生院","address": ""},
    {"value": "太原钢铁（集团）有限公司总医院峨口矿分院","address": ""},
    {"value": "龙湾至尔益美口腔门诊部","address": ""},
    {"value": "临县仁爱中西医结合医院","address": ""},
    {"value": "赵家坪乡卫生院(兴县医疗集团赵家坪分院）","address": ""},
    {"value": "交城县医疗集团天宁镇卫生院","address": ""},
    {"value": "长子县石哲中心卫生院","address": ""},
    {"value": "长子县医疗集团石哲镇卫生院","address": ""},
    {"value": "北大医疗潞安医院世纪春天医务室","address": ""},
    {"value": "大同中科肿瘤医院","address": ""},
    {"value": "平陆安济医院","address": ""},
    {"value": "忻州新东方外科医院","address": ""},
    {"value": "沁源县第二人民医院","address": ""},
    {"value": "平顺县东寺头乡卫生院","address": ""},
    {"value": "太原市尖草坪区简易社区卫生服务站","address": ""},
    {"value": "岢岚县三井中心卫生院","address": ""},
    {"value": "国电华北电力有限公司太原第一热电厂综合门诊部","address": ""},
    {"value": "运城市盐湖区安邑办事处信合名都社区卫生服务站","address": ""},
    {"value": "太原美年大健康科技健康管理有限公司万达门诊部","address": ""},
    {"value": "运城为民精神病医院","address": ""},
    {"value": "大同康乐医院","address": ""},
    {"value": "灵石县南关镇卫生院西许分院","address": ""},
    {"value": "绛县彩铃口腔门诊部","address": ""},
    {"value": "云冈区晋华宫社区卫生服务中心","address": ""},
    {"value": "城区轩草堂中医门诊部","address": ""},
    {"value": "中阳县医疗集团武家庄镇卫生院","address": ""},
    {"value": "侯马纺职医院","address": ""},
    {"value": "忻州市忻府区西张镇卫生院","address": ""},
    {"value": "侯马市凤城乡卫生院","address": ""},
    {"value": "保德县中医院","address": ""},
    {"value": "临汾尧都丰华医院","address": ""},
    {"value": "汾阳市汾酒集团社区杏花园卫生站","address": ""},
    {"value": "平遥正德骨科医院","address": ""},
    {"value": "大宁县医疗集团三多乡卫生院","address": ""},
    {"value": "运城众康中医康复医院","address": ""},
    {"value": "永济市医疗集团蒲州镇卫生院","address": ""},
    {"value": "和顺县马坊乡卫生院","address": ""},
    {"value": "太原市小店区小吴社区卫生服务站","address": ""},
    {"value": "闻喜县医疗集团桐城镇卫生院西官庄分院","address": ""},
    {"value": "太原市杏花岭区柏杨树社区卫生服务站","address": ""},
    {"value": "平城区乐臻口腔门诊部","address": ""},
    {"value": "原平市段家堡乡卫生院官地社区卫生服务站","address": ""},
    {"value": "右玉万泰中西医结合医院","address": ""},
    {"value": "荣幸街社区卫生服务站","address": ""},
    {"value": "应县医疗集团镇子梁乡中心卫生院","address": ""},
    {"value": "长治市潞州区太行西街街道城北西街社区卫生服务中心","address": ""},
    {"value": "祁县中学校医务室","address": ""},
    {"value": "原平市崞阳镇卫生院石寺社区卫生服务站","address": ""},
    {"value": "长治市潞州区医疗集团长治市潞州区人民医院","address": ""},
    {"value": "岚县妇幼保健和计划生育服务中心","address": ""},
    {"value": "乡宁县关王庙乡中心卫生院","address": ""},
    {"value": "临县林家坪镇中心卫生院高家山社区卫生服务站","address": ""},
    {"value": "临汾尧都中山医院","address": ""},
    {"value": "杏花岭区敦化坊社区卫生服务站","address": ""},
    {"value": "山西惠民中西医结合医院","address": ""},
    {"value": "大同怡仁中西医结合医院有限公司","address": ""},
    {"value": "太原文武口腔门诊管理有限公司杏花岭中车口腔门诊部","address": ""},
    {"value": "和顺县阳光占乡卫生院","address": ""},
    {"value": "静乐五行中医馆","address": ""},
    {"value": "繁峙县岩头乡卫生院","address": ""},
    {"value": "平顺县石城中心卫生院（平顺县医疗集团石城中心卫生院）","address": ""},
    {"value": "平顺县中医院","address": ""},
    {"value": "平顺县龙溪镇中心卫生院（平顺县医疗集团医院龙溪镇中心卫生院）","address": ""},
    {"value": "平顺县北社乡卫生院（平顺县医疗集团北社乡卫生院）","address": ""},
    {"value": "平顺县青羊镇卫生院中五井分院","address": ""},
    {"value": "黎城县中医院（黎城县医疗集团中医院）","address": ""},
    {"value": "黎城县西仵乡卫生院（黎城县西仵乡妇幼保健计划生育服务站、黎城县医疗集团西仵乡卫生院）","address": ""},
    {"value": "黎城县程家山乡卫生院（黎城县程家山乡妇幼保健计划生育服务站、黎城县医疗集团程家山乡卫生院）","address": ""},
    {"value": "黎城县西井镇中心卫生院（黎城县西井镇妇幼保健计划生育服务站、黎城县医疗集团西井镇卫生院）","address": ""},
    {"value": "黎城县停河铺乡卫生院（黎城县停河铺乡妇幼保健计划生育服务站、黎城县医疗集团停河铺乡卫生院）","address": ""},
    {"value": "黎城县黎侯镇卫生院（黎城县黎侯镇妇幼保健计划生育服务站、黎城县医疗集团黎侯镇卫生院）","address": ""},
    {"value": "医疗集团洪井乡卫生院柏官庄分院黎城县柏官庄卫生院","address": ""},
    {"value": "黎城县医疗集团（黎城县人民医院、黎城县医疗集团人民医院）","address": ""},
    {"value": "黎城县柏峪乡卫生院","address": ""},
    {"value": "医疗集团上遥镇卫生院黎城县上遥镇卫生院","address": ""},
    {"value": "黎城县黄崖洞镇卫生院（黎城县黄崖洞镇妇幼保健计划生育服务站、黎城县医疗集团黄崖洞镇卫生院）","address": ""},
    {"value": "壶关县医疗集团集店镇卫生院","address": ""},
    {"value": "壶关县医疗集团","address": ""},
    {"value": "壶关县医疗集团人民医院","address": ""},
    {"value": "壶关县人民医院","address": ""},
    {"value": "壶关县大峡谷镇卫生院（壶关县大峡谷镇妇幼保健计划生育服务站）（壶关县医疗集团大峡谷镇卫生院）","address": ""},
    {"value": "壶关华康医院","address": ""},
    {"value": "壶关县店上镇中心卫生院（壶关县店上镇妇幼保健和计划生育服务站、壶关县医疗集团店上镇中心卫生院）","address": ""},
    {"value": "壶关县东井岭乡卫生院（壶关县东井岭乡妇幼保健计划生育服务站）（壶关县医疗集团东井岭乡卫生院）","address": ""},
    {"value": "壶关县五龙山乡卫生院","address": ""},
    {"value": "壶关县医疗集团五龙山乡卫生院","address": ""},
    {"value": "壶关县百尺镇中心卫生院（壶关县百尺镇妇幼和计划生育服务站、壶关县医疗集团百尺镇中心卫生院）","address": ""},
    {"value": "壶关县龙泉镇城南社区卫生服务站","address": ""},
    {"value": "壶关县晋庄镇中心卫生院（壶关县晋庄镇妇幼保健计划生育服务站、壶关县医疗集团晋庄镇中心卫生院）","address": ""},
    {"value": "壶关县龙泉镇西街社区卫生服务站","address": ""},
    {"value": "壶关县龙泉镇新南社区第二卫生服务站","address": ""},
    {"value": "壶关县黄山乡卫生院（壶关县黄山乡妇幼保健和计划生育服务站、壶关县医疗集团黄山乡卫生院）","address": ""},
    {"value": "壶关县龙泉镇城北社区卫生服务站","address": ""},
    {"value": "壶关郭向鸿医院","address": ""},
    {"value": "壶关县中医院","address": ""},
    {"value": "长子县石哲中心卫生院王峪分院（长子县医疗集团王峪景区卫生院）","address": ""},
    {"value": "长子县石哲中心卫生院横水分院（长子县医疗集团横水林区卫生院）","address": ""},
    {"value": "长子荣和医院","address": ""},
    {"value": "长子县中医院","address": ""},
    {"value": "长子县南漳镇卫生院（长子县医疗集团南漳镇卫生院）","address": ""},
    {"value": "长子县丹朱镇卫生院（长子县医疗集团丹朱镇卫生院）","address": ""},
    {"value": "长子县碾张乡卫生院（长子县医疗集团碾张乡卫生院）","address": ""},
    {"value": "长子县鲍店镇卫生院分院（长子县医疗集团鲍店镇卫生院分院）","address": ""},
    {"value": "长子县医疗集团（长子县人民医院、长子县医疗集团人民医院）","address": ""},
    {"value": "长子县南陈镇卫生院（长子县医疗集团南陈镇卫生院）","address": ""},
    {"value": "长子县常张乡卫生院（长子县医疗集团常张乡卫生院）","address": ""},
    {"value": "长子县大堡头镇卫生院（长子县医疗集团大堡头镇卫生院）","address": ""},
    {"value": "长子同瑞医院","address": ""},
    {"value": "武乡县涌泉卫生院","address": ""},
    {"value": "武乡县故县卫生院","address": ""},
    {"value": "武乡县故城中心卫生院","address": ""},
    {"value": "武乡县洪水中心卫生院（武乡县医疗集团洪水中心卫生院）（武乡县洪水妇幼保健计划生育服务站）","address": ""},
    {"value": "武乡县大有卫生院（武乡县医疗集团大有卫生院）（武乡县大有妇幼保健计划生育服务站）","address": ""},
    {"value": "武乡县监漳中心卫生院（武乡县医疗集团监漳中心卫生院）（武乡县监漳妇幼保健计划生育服务站）","address": ""},
    {"value": "武乡县韩北卫生院（武乡县医疗集团韩北卫生院）（武乡县韩北妇幼保健计划生育服务站）","address": ""},
    {"value": "武乡县医疗集团（武乡县人民医院）（武乡县医疗集团人民医院）","address": ""},
    {"value": "武乡县上司卫生院（武乡县医疗集团上司卫生院）（武乡县上司妇幼保健计划生育服务站）","address": ""},
    {"value": "沁县杨安乡卫生院（沁县医疗集团杨安乡卫生院、沁县杨安乡妇幼保健计划生育服务站）","address": ""},
    {"value": "沁县新店镇中心卫生院（沁县医疗集团新店镇中心卫生院、沁县新店镇妇幼保健计划生育服务站）","address": ""},
    {"value": "沁县中医医院","address": ""},
    {"value": "沁县定昌镇中心卫生院（沁县医疗集团定昌镇中心卫生院、沁县定昌镇妇幼保健计划生育服务站）","address": ""},
    {"value": "沁州医院","address": ""},
    {"value": "沁源县韩洪乡卫生院","address": ""},
    {"value": "沁源县交口乡卫生院（沁源县医疗集团交口乡卫生院、沁源县交口乡妇幼保健计划生育服务站）","address": ""},
    {"value": "沁源县聪子峪乡卫生院（沁源县医疗集团聪子峪乡卫生院、沁源县聪子峪乡妇幼保健计划生育服务站）","address": ""},
    {"value": "沁源县郭道镇中心卫生院（沁源县医疗集团郭道镇中心卫生院、沁源县郭道镇妇幼保健计划生育服务站）","address": ""},
    {"value": "沁源县中医医院（沁源县医疗集团中医医院）","address": ""},
    {"value": "晋城市骨伤专科医院","address": ""},
    {"value": "晋城国康肝胆胃病医院","address": ""},
    {"value": "晋城健齿口腔医院","address": ""},
    {"value": "西安拜尔口腔门诊部有限责任公司晋城分公司","address": ""},
    {"value": "晋城市荣军优抚医院","address": ""},
    {"value": "晋城立通高血压病专科医院","address": ""},
    {"value": "晋城现代妇产医院","address": ""},
    {"value": "晋城白云同德医院","address": ""},
    {"value": "晋城福康中西医院","address": ""},
    {"value": "高平市痔瘘专科医院","address": ""},
    {"value": "晋城合聚心脑血管病医院","address": ""},
    {"value": "晋城市传染病医院（晋城市第三人民医院）","address": ""},
    {"value": "晋城朝阳医院","address": ""},
    {"value": "晋城联袂齿科医院","address": ""},
    {"value": "晋城市职业病医院","address": ""},
    {"value": "晋城市老年病专科医院","address": ""},
    {"value": "晋城大医院","address": ""},
    {"value": "山西医科大学附属医院","address": ""},
    {"value": "晋城市职业病防治院","address": ""},
    {"value": "晋城大医院互联网医院","address": ""},
    {"value": "泽州利民中西医结合医院","address": ""},
    {"value": "晋城市城区西街街道办事处社区卫生服务中心（晋城博爱医院）","address": ""},
    {"value": "晋城市城区钟家庄街道办事处社区卫生服务中心（晋城市城区医疗集团钟家庄街道办事处社区卫生服务中心）","address": ""},
    {"value": "晋城市城区医疗集团（晋城市第二人民医院、晋城市城区医疗集团人民医院）","address": ""},
    {"value": "晋城圣糖中西医结合医院","address": ""},
    {"value": "晋城民盛皮肤门诊","address": ""},
    {"value": "晋城市安康烧伤专科医院","address": ""},
    {"value": "晋城市城区南街街道办事处社区卫生服务中心（晋城市城区医疗集团南街街道办事处社区卫生服务中心、晋城市钢铁厂职工医院）","address": ""},
    {"value": "晋城市城区牙医生口腔门诊部（普通合伙）","address": ""},
    {"value": "晋城金汇综合门诊","address": ""},
    {"value": "太原新北门诊部","address": ""},
    {"value": "交城县社会福利服务中心","address": ""},
    {"value": "祁县阳光医院","address": ""},
    {"value": "祁县来远镇卫生院（祁县医疗集团来远镇卫生院）","address": ""},
    {"value": "运城市盐湖区安邑办事处金海湾社区卫生服务站","address": ""},
    {"value": "大同陆元康心理医院","address": ""},
    {"value": "临猗瑞康医院","address": ""},
    {"value": "洪洞消化病医院有限责任公司","address": ""},
    {"value": "阳泉市郊区东城社区卫生服务站","address": ""},
    {"value": "长治华丰显微手足创伤骨科医院","address": ""},
    {"value": "晋中四处医院","address": ""},
    {"value": "长治市潞城区合室乡卫生院（长治市潞城区医疗集团合室乡卫生院）","address": ""},
    {"value": "长治市潞城区辛安泉镇卫生院（长治市潞城区","address": ""},
    {"value": "医疗集团辛安泉镇卫生院）","address": ""},
    {"value": "长治市潞城区医疗集团黄牛蹄乡卫生院","address": ""},
    {"value": "潞城仁惠医院","address": ""},
    {"value": "长治市潞城区医疗集团（长治市潞城区医疗集团人民医院、长治市潞城区人民医院）","address": ""},
    {"value": "长治市潞城区潞华办事处城南社区卫生服务站","address": ""},
    {"value": "长子县慈林镇卫生院（长子县精神病医院）长子县医疗集团慈林镇卫生院","address": ""},
    {"value": "河津市城区社区卫生服务中心（河津市医疗集团城区社区卫生服务中心）","address": ""},
    {"value": "太原市尖草坪区新华社区卫生服务站","address": ""},
    {"value": "榆次区乌金山镇中心卫生院（榆次区医疗集团乌金山镇中心卫生院）","address": ""},
    {"value": "吕梁市妇幼保健计划生育服务中心门诊部","address": ""},
    {"value": "山西绿洲纺织有限责任公司医务室","address": ""},
    {"value": "晋能控股煤业集团轩岗煤电有限责任公司吉祥花园中西医结合医院","address": ""},
    {"value": "临县医疗集团车赶乡卫生院","address": ""},
    {"value": "汾阳诚德堂医院","address": ""},
    {"value": "定襄县医疗集团季庄镇卫生院","address": ""},
    {"value": "长治市潞州区五马街道社区卫生服务中心","address": ""},
    {"value": "太原西山西铭医院","address": ""},
    {"value": "太原市尖草坪区兴华东社区卫生服务站","address": ""},
    {"value": "河曲县刘家塔镇卫生院树儿梁分院","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处金山社区卫生服务站","address": ""},
    {"value": "娄烦县盖家庄卫生院（娄烦县医疗集团盖家庄乡卫生院）","address": ""},
    {"value": "义门镇卫生院","address": ""},
    {"value": "山西省儿童医院（山西省妇幼保健院）","address": ""},
    {"value": "太原齿德口腔门诊有限公司迎泽太堡街口腔门诊部","address": ""},
    {"value": "广灵县中医医院","address": ""},
    {"value": "怀仁市医疗集团里八庄社区卫生服务站","address": ""},
    {"value": "山西大学医院","address": ""},
    {"value": "太原市迎泽区新建南路第一社区卫生服务站","address": ""},
    {"value": "晋城鹤翔疼痛专科医院","address": ""},
    {"value": "太原尚梵恒伦口腔门诊部","address": ""},
    {"value": "方山县方正综合医院","address": ""},
    {"value": "五台县陈家庄乡中心卫生院东峪口社区服务站","address": ""},
    {"value": "中阳县医疗集团车鸣峪乡卫生院","address": ""},
    {"value": "临汾兴山外科医院","address": ""},
    {"value": "朔州市朔城区文远路社区卫生服务站","address": ""},
    {"value": "长治市潞州区延安南路街道社区卫生服务中心","address": ""},
    {"value": "阳城县眼科医院","address": ""},
    {"value": "临猗国强口腔专科医院","address": ""},
    {"value": "霍州康人医院有限公司","address": ""},
    {"value": "山西齿科医院（有限公司）晋中门诊部","address": ""},
    {"value": "北大医疗潞安医院沁芳苑医务所","address": ""},
    {"value": "长子县石哲中心卫生院晋义分院","address": ""},
    {"value": "长子县医疗集团石哲分院晋义分部","address": ""},
    {"value": "大同和平医院有限公司","address": ""},
    {"value": "山西兰花煤炭实业集团有限公司唐安分公司医务室","address": ""},
    {"value": "阳泉市中医医院","address": ""},
    {"value": "晋城爱尚医院","address": ""},
    {"value": "黄孝武齿科门诊","address": ""},
    {"value": "晋城市城区中医院","address": ""},
    {"value": "晋城市眼科医院","address": ""},
    {"value": "沁水县十里乡卫生院（沁水县医疗集团十里乡卫生院）","address": ""},
    {"value": "沁水县医疗集团（沁水县人民医院、沁水县医疗集团人民医院）","address": ""},
    {"value": "沁水县第二人民医院","address": ""},
    {"value": "沁水县东方医院","address": ""},
    {"value": "沁水县武安创伤医院","address": ""},
    {"value": "沁水县郑庄镇中心卫生院（沁水县医疗集团郑庄镇中心卫生院）","address": ""},
    {"value": "沁水县柿庄镇中心卫生院（沁水县医疗集团柿庄镇中心卫生院）","address": ""},
    {"value": "沁水县复明眼科医院","address": ""},
    {"value": "沁水县中医医院","address": ""},
    {"value": "阳城县次营镇中心卫生院固隆分院（阳城县医疗集团次营镇中心卫生院固隆分院）","address": ""},
    {"value": "阳城县寺头乡卫生院","address": ""},
    {"value": "阳城县横河镇卫生院（阳城县医疗集团横河镇卫生院）","address": ""},
    {"value": "阳城县人民医院","address": ""},
    {"value": "阳城友谊医院","address": ""},
    {"value": "阳城县北留镇中心卫生院（阳城县医疗集团北留镇中心卫生院）","address": ""},
    {"value": "阳城县肿瘤医院（阳城县第二人民医院）","address": ""},
    {"value": "阳城县芹池镇中心卫生院羊泉分院（阳城县医疗集团芹池镇中心卫生院羊泉分院）","address": ""},
    {"value": "阳城县演礼镇卫生院","address": ""},
    {"value": "阳城县芹池镇中心卫生院（阳城县医疗集团芹池镇中心卫生院）","address": ""},
    {"value": "陵川县马圪当乡卫生院","address": ""},
    {"value": "陵川县秦家庄乡卫生院","address": ""},
    {"value": "陵川县西河底镇卫生院","address": ""},
    {"value": "陵川县六泉乡卫生院","address": ""},
    {"value": "陵川县古郊乡中心卫生院","address": ""},
    {"value": "陵川县夺火乡卫生院","address": ""},
    {"value": "陵川县礼义镇中心卫生院","address": ""},
    {"value": "陵川县妇幼保健计划生育服务中心（陵川县妇幼保健院）","address": ""},
    {"value": "陵川县附城镇中心卫生院","address": ""},
    {"value": "陵川恒泰康复医院","address": ""},
    {"value": "泽州县医疗集团大阳镇卫生院","address": ""},
    {"value": "泽州县杨洼社区卫生服务中心","address": ""},
    {"value": "泽州县医疗集团犁川镇卫生院（泽州县医疗集团犁川镇卫生院）","address": ""},
    {"value": "泽州县康复医院","address": ""},
    {"value": "泽州县医疗集团大箕镇南河西卫生院","address": ""},
    {"value": "泽州县医疗集团北义城镇卫生院","address": ""},
    {"value": "泽州县医疗集团川底乡卫生院（泽州县医疗集团川底乡卫生院）","address": ""},
    {"value": "晋城康德精神卫生专科医院","address": ""},
    {"value": "泽州县高都镇大兴卫生院（泽州县医疗集团高都镇大兴卫生院）","address": ""},
    {"value": "晋城普仁肛肠专科医院","address": ""},
    {"value": "保德县医疗集团杨家湾镇分院","address": ""},
    {"value": "古交市东曲街道义学路社区卫生服务站","address": ""},
    {"value": "太原市迎泽区上马二社区卫生服务站","address": ""},
    {"value": "云冈区姜家湾社区卫生服务中心","address": ""},
    {"value": "文水晋都医院","address": ""},
    {"value": "太原傅山医圣传习中医门诊部","address": ""},
    {"value": "太原欧然口腔管理有限公司小店小马花园口腔门诊部","address": ""},
    {"value": "临县医疗集团石白头乡卫生院","address": ""},
    {"value": "山西齿科医院（晋阳街）","address": ""},
    {"value": "山西中医药大学附属医院","address": ""},
    {"value": "长治市潞州区医疗集团老顶山镇中心卫生院","address": ""},
    {"value": "（长治心理康复医院）","address": ""},
    {"value": "盂县益康综合门诊部","address": ""},
    {"value": "偏关县水泉卫生院","address": ""},
    {"value": "朔州曙光（男科）医院","address": ""},
    {"value": "晋城市城区钟家庄街道办事处中原街社区卫生服务站","address": ""},
    {"value": "霍州市医疗集团师庄中心卫生院","address": ""},
    {"value": "太原颐美口腔门诊部","address": ""},
    {"value": "山西盛大齿科医院","address": ""},
    {"value": "忻州国馨医学检验实验室有限公司","address": ""},
    {"value": "临县医疗集团木瓜坪乡卫生院","address": ""},
    {"value": "太原市杏花岭区锦绣苑社区卫生服务站","address": ""},
    {"value": "忻州市忻府区建北社区卫生服务站","address": ""},
    {"value": "大同市云冈区同善中西医结合医院","address": ""},
    {"value": "原平市中阳乡卫生院","address": ""},
    {"value": "新绛县妇幼保健计划生育服务中心","address": ""},
    {"value": "武乡县石北卫生院","address": ""},
    {"value": "泽州县医疗集团柳树口镇中心卫生院","address": ""},
    {"value": "盂县阳光百姓药房有限公司君悦门诊","address": ""},
    {"value": "太原师范学院医院","address": ""},
    {"value": "太原市精华口腔门诊部","address": ""},
    {"value": "夏县安康精神病医院","address": ""},
    {"value": "晋中市太谷区新城社区卫生服务中心","address": ""},
    {"value": "朔州市朔城区医疗集团福善庄乡卫生院","address": ""},
    {"value": "朔城区福善庄乡卫生院","address": ""},
    {"value": "乡宁县关王庙乡中心卫生院云邱山服务站","address": ""},
    {"value": "临汾市尧都区滨河办事处上樊社区卫生服务站","address": ""},
    {"value": "大同市平城区新华街社区卫生服务中心","address": ""},
    {"value": "阳泉市矿区医疗集团（阳泉市矿区医院）龙泉社区卫生服务站、阳泉市矿区龙泉社区卫生服务站","address": ""},
    {"value": "晋中同舟雅康口腔门诊部","address": ""},
    {"value": "寿阳县朝阳镇社区卫生服务中心（寿阳县滨河城区管理委员会妇幼保健计划生育服务站、寿阳县医疗集团朝阳镇社区卫生服务中心）","address": ""},
    {"value": "朔州小峪医院","address": ""},
    {"value": "平城区恒大绿洲社区卫生服务站","address": ""},
    {"value": "晋中市榆次区牙天使口腔门诊部有限公司","address": ""},
    {"value": "长治市潞州区英雄南路街道梅辉坡社区卫生服务南站","address": ""},
    {"value": "沁源县沁河镇卫生院","address": ""},
    {"value": "运城市盐湖区东城办事处盐湖城社区卫生服务站","address": ""},
    {"value": "清徐县西谷乡卫生院","address": ""},
    {"value": "广灵县医疗集团壶泉镇卫生院","address": ""},
    {"value": "原平康宁医院铝厂医务室","address": ""},
    {"value": "平城区向阳里青年路社区卫生服务站","address": ""},
    {"value": "临汾尧都维乐口腔门诊部","address": ""},
    {"value": "泽州县医疗集团南岭镇李寨卫生院","address": ""},
    {"value": "泽州县痔瘘专科医院","address": ""},
    {"value": "泽州县巴公中心卫生院（泽州县第二人民医院）","address": ""},
    {"value": "泽州县巴公镇陈沟卫生院（泽州县医疗集团巴公镇陈沟卫生院）","address": ""},
    {"value": "高平武承谋骨伤专科医院永安分院","address": ""},
    {"value": "高平市河西镇卫生院（高平市人民医院河西分院）","address": ""},
    {"value": "高平众和医院","address": ""},
    {"value": "高平博爱医院","address": ""},
    {"value": "高平普济医院","address": ""},
    {"value": "高平友谊医院","address": ""},
    {"value": "高平市臻爱医院有限公司","address": ""},
    {"value": "高平复明眼科医院","address": ""},
    {"value": "高平民生烧伤专科医院","address": ""},
    {"value": "高平仁爱医院","address": ""},
    {"value": "高平市米山镇卫生院（高平市人民医院集团米山分院）","address": ""},
    {"value": "高平市寺庄镇卫生院（高平市医疗集团寺庄镇卫生院）","address": ""},
    {"value": "高平市石末乡卫生院（高平市医疗集团石末乡卫生院）","address": ""},
    {"value": "高平市中医医院","address": ""},
    {"value": "高平市友谊街哎牙口腔门诊部","address": ""},
    {"value": "高平市南城办事处社区卫生服务中心（高平市人民医院集团南城分院）","address": ""},
    {"value": "高平市陈区镇卫生院（高平市人民医院集团陈区分院）","address": ""},
    {"value": "高平市北城办事处社区卫生服务中心（高平市医疗集团北城社区卫生服务中心）","address": ""},
    {"value": "高平市东城办事处社区卫生服务中心（高平市人民医院集团东城分院）","address": ""},
    {"value": "高平红光老年病医院","address": ""},
    {"value": "高平市妇幼保健计划生育服务中心","address": ""},
    {"value": "朔州市中心医院","address": ""},
    {"value": "朔州泰康医院","address": ""},
    {"value": "朔州中信新健康医院","address": ""},
    {"value": "朔州市中医医院","address": ""},
    {"value": "朔州中医耳鼻咽喉医院","address": ""},
    {"value": "朔州市朔城区北城街道办事处建设路社区卫生服务站","address": ""},
    {"value": "朔州名都医院","address": ""},
    {"value": "朔州元禄医院","address": ""},
    {"value": "朔州名仁针灸医院","address": ""},
    {"value": "朔州市朔城区南城街道办事处水乡湾社区卫生服务站","address": ""},
    {"value": "朔州市妇幼保健院","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处社区卫生服务中心","address": ""},
    {"value": "朔州朔城新华中西医结合医院","address": ""},
    {"value": "朔州市朔城区窑子头乡卫生院梵王寺分院","address": ""},
    {"value": "朔州市朔城区南城街道办事处万象城社区卫生服务站","address": ""},
    {"value": "朔州市朔城区医疗集团神头镇朔城区神头镇卫生院","address": ""},
    {"value": "朔州市朔城区晋民中医医院","address": ""},
    {"value": "朔州市朔城区人民医院（朔州市人民医院）","address": ""},
    {"value": "朔州民福医院","address": ""},
    {"value": "朔州和泰中医皮肤专科医院","address": ""},
    {"value": "朔州东方妇产医院","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处厚德园社区卫生服务站","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处民福西街社区卫生服务站","address": ""},
    {"value": "朔州市朔城区南城街道办事处开发南路社区卫生服务站","address": ""},
    {"value": "朔州市朔城区北城街道办事处北关路社区卫生服务站","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处和丽园社区卫生服务站","address": ""},
    {"value": "朔州市朔城区窑子头乡卫生院","address": ""},
    {"value": "朔州市朔城区张蔡庄乡卫生院","address": ""},
    {"value": "朔州市朔城区小平易乡卫生院","address": ""},
    {"value": "朔州市朔城区医疗集团滋润乡卫生院","address": ""},
    {"value": "朔城区","address": ""},
    {"value": "滋润乡卫生院","address": ""},
    {"value": "朔州市朔城区南城街道办事处城壕堰社区卫生服务站","address": ""},
    {"value": "朔州市朔城区沙塄河乡卫生院","address": ""},
    {"value": "朔州市朔城区医疗集团南榆林乡卫生院神武分院","address": ""},
    {"value": "朔州市朔城区神头镇卫生院大夫庄分院","address": ""},
    {"value": "朔州市朔城区利民镇卫生院","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处锦华社区卫生服务站","address": ""},
    {"value": "朔州市朔城区北旺庄街道办事处敬德园社区卫生服务站","address": ""},
    {"value": "朔州慈仁医院","address": ""},
    {"value": "朔州市朔城区北城街道办事处社区卫生服务中心","address": ""},
    {"value": "太原市万柏林区小井峪社区卫生服务中心","address": ""},
    {"value": "中阳县医疗集团金罗镇卫生院张子山分院","address": ""},
    {"value": "吕梁市离石区莲花池街道办昌宁西区社区卫生服务站","address": ""},
    {"value": "临汾市尧都区乡贤街办事处乡东社区卫生服务站","address": ""},
    {"value": "临猗县妇幼保健计划生育服务中心","address": ""},
    {"value": "晋中榆次登登口腔门诊部（有限公司）","address": ""},
    {"value": "河曲县楼子营镇卫生院","address": ""},
    {"value": "兴县蔡家崖乡卫生院（兴县医疗集团蔡家崖乡卫生院）","address": ""},
    {"value": "太原市迎泽区中心医院","address": ""},
    {"value": "侯马市上马街道办事处社区卫生服务中心","address": ""},
    {"value": "霍州诚康血液透析中心有限公司","address": ""},
    {"value": "平陆县张店镇中心卫生院（平陆县医疗集团张店镇中心卫生院）","address": ""},
    {"value": "夏县人民医院","address": ""},
    {"value": "平城百夫子口腔门诊部","address": ""},
    {"value": "太原晋阳恒伦口腔门诊部","address": ""},
    {"value": "高平申氏口腔门诊部","address": ""},
    {"value": "临猗县角杯乡中心卫生院西张吴分院（临猗县医疗集团角杯乡中心卫生院西张吴分院）","address": ""},
    {"value": "洪洞晋南医院","address": ""},
    {"value": "浑源县医疗集团官儿乡卫生院","address": ""},
    {"value": "天镇县万家乐移民新区卫生服务站","address": ""},
    {"value": "临汾露德眼科医院","address": ""},
    {"value": "岢岚县医疗集团大涧乡卫生院","address": ""},
    {"value": "阳泉市矿区医疗集团（阳泉市矿区医院）西山社区卫生服务站、阳泉市矿区西山社区卫生服务站","address": ""},
    {"value": "运城市盐湖区北城办事处石化社区卫生服务站","address": ""},
    {"value": "五台县耿镇镇中心卫生院屋腔社区服务站","address": ""},
    {"value": "乡宁县关王庙乡中心卫生院后沟岭服务站","address": ""},
    {"value": "祁县惠民康宁医院","address": ""},
    {"value": "屯留县李高乡卫生院高头寺分院","address": ""},
    {"value": "盐湖疾病预防控制中心门诊部","address": ""},
    {"value": "垣曲县王茅镇中心卫生院（垣曲县医疗集团王茅镇中心卫生院）","address": ""},
    {"value": "大同蓝丁格尔妇女保健专科门诊有限公司","address": ""},
    {"value": "临汾市尧都区路东办事处冶建社区卫生服务站","address": ""},
    {"value": "太原市杏花岭区赛马场社区卫生服务站","address": ""},
    {"value": "山西汾西矿业集团两渡煤业有限责任公司综合门诊部","address": ""},
    {"value": "云冈区新平旺幸福路社区卫生服务站","address": ""},
    {"value": "孝义市强氏门诊部有限责任公司","address": ""},
    {"value": "忻州康福医院","address": ""},
    {"value": "七府坟南街第一社区卫生服务站","address": ""},
    {"value": "文水县凤城镇卫生院","address": ""},
    {"value": "霍州樊志峰外科医院","address": ""},
    {"value": "阳城民康医院","address": ""},
    {"value": "晋中市榆次区锦纶街道北郊东社区卫生服务站","address": ""},
    {"value": "长治市上党区新市街社区卫生服务站","address": ""},
    {"value": "河津紫金妇产医院","address": ""},
    {"value": "大型社区卫生服务站","address": ""},
    {"value": "临汾市尧都区路东办事处康庄社区卫生服务站","address": ""},
    {"value": "大同天佑中医医院","address": ""},
    {"value": "太原美兆健康科技有限公司摩码门诊部","address": ""},
    {"value": "阳泉市郊区开发区下五渡社区卫生服务站","address": ""},
    {"value": "原平市苏龙口镇卫生院白石社区卫生服务站","address": ""},
    {"value": "岚县乾伦网联中医门诊部","address": ""},
    {"value": "兴县奥家湾乡中心卫生院","address": ""},
    {"value": "交城县医疗集团天宁镇卫生院","address": ""},
    {"value": "山西华晋骨科医院","address": ""},
    {"value": "东方名城社区卫生服务站","address": ""},
    {"value": "太原市尖草坪区乾泽园社区卫生服务站","address": ""},
    {"value": "永济尖峰眼科医院","address": ""},
    {"value": "太原佰源口腔门诊部","address": ""},
    {"value": "朔州市市委社区卫生服务站","address": ""},
    {"value": "大同市新荣区郭家窑乡卫生院东胜庄分院","address": ""},
    {"value": "朔州仁医堂医院","address": ""},
    {"value": "大同市第三人民医院","address": ""},
    {"value": "晋城市城区钟家庄办事处花园头社区卫生服务站","address": ""},
    {"value": "怀仁市云东社区卫生服务中心","address": ""},
    {"value": "太原市迎泽区解放南路一社区卫生服务站","address": ""},
    {"value": "灵石县北城社区卫生服务站","address": ""},
    {"value": "太原市小店区德心专科门诊部","address": ""},
    {"value": "朔州市朔城区医疗集团下团堡乡卫生院","address": ""},
    {"value": "朔州市朔城区神头街道办事处社区卫生服务中心","address": ""},
    {"value": "仁康门诊部","address": ""},
    {"value": "朔州市平鲁区下面高乡卫生院（朔州市平鲁区医疗集团下面高乡卫生院）","address": ""},
    {"value": "朔州市平鲁区榆岭乡卫生院（朔州市平鲁区医疗集团榆岭乡卫生院）","address": ""},
    {"value": "朔州市平鲁区西水界乡卫生院（朔州市平鲁区医疗集团西水界乡卫生院）","address": ""},
    {"value": "朔州市平鲁区下水头乡卫生院（朔州市平鲁区医疗集团下水头乡卫生院）","address": ""},
    {"value": "朔州市平鲁区双碾乡卫生院（朔州市平鲁区医疗集团双碾乡卫生院）","address": ""},
    {"value": "朔州市平鲁区济民门诊部","address": ""},
    {"value": "朔州市平鲁区民福社区卫生服务站","address": ""},
    {"value": "朔州市平鲁区友谊门诊","address": ""},
    {"value": "朔州市平鲁区阻虎乡卫生院（朔州市平鲁区医疗集团阻虎乡卫生院）","address": ""},
    {"value": "朔州市平鲁区东坪社区卫生服务站","address": ""},
    {"value": "朔州市平鲁区北坪社区卫生服务站","address": ""},
    {"value": "朔州市平鲁区井坪镇卫生院（朔州市平鲁区医疗集团井坪镇卫生院）","address": ""},
    {"value": "朔州平鲁康杰医院","address": ""},
    {"value": "朔州市平鲁区中医医院","address": ""},
    {"value": "朔州市平鲁区白堂乡卫生院（朔州市平鲁区医疗集团白堂乡卫生院）","address": ""},
    {"value": "朔州市平鲁区井坪镇社区卫生服务中心（朔州市平鲁区医疗集团井坪镇社区卫生服务中心）","address": ""},
    {"value": "朔州市平鲁区向阳堡乡卫生院（朔州市平鲁区医疗集团向阳堡乡卫生院）","address": ""},
    {"value": "山阴县医疗集团古城镇中心卫生院","address": ""},
    {"value": "山阴益康中西医结合医院","address": ""},
    {"value": "山阴现代医院","address": ""},
    {"value": "山阴县北环路社区卫生服务所","address": ""},
    {"value": "山阴县医疗集团马营庄乡卫生院","address": ""},
    {"value": "山阴县医疗集团岱岳镇卫生院","address": ""},
    {"value": "山阴县医疗集团薛圐圙乡卫生院","address": ""},
    {"value": "山阴县医疗集团安荣乡卫生院","address": ""},
    {"value": "山阴县医疗集团后所乡中心卫生院","address": ""},
    {"value": "山阴县医疗集团张家庄乡卫生院","address": ""},
    {"value": "山阴县医疗集团吴马营乡卫生院","address": ""},
    {"value": "山阴县医疗集团北周庄镇中心卫生院","address": ""},
    {"value": "山阴县医疗集团（山阴县医疗集团人民医院、山阴县人民医院）","address": ""},
    {"value": "应县中医院","address": ""},
    {"value": "应县康泰骨外科医院","address": ""},
    {"value": "应县济民医院","address": ""},
    {"value": "右玉惠康医院","address": ""},
    {"value": "右玉县医疗集团（右玉县人民医院、右玉县医疗集团人民医院）","address": ""},
    {"value": "右玉县中医院","address": ""},
    {"value": "忻州市中医医院","address": ""},
    {"value": "忻州市妇幼保健计划生育服务中心","address": ""},
    {"value": "介休博爱医院","address": ""},
    {"value": "祁县尚巾眼科医院","address": ""},
    {"value": "左权康复医院","address": ""},
    {"value": "左权博爱医院","address": ""},
    {"value": "忻州市第二人民医院（忻州市肿瘤医院）","address": ""},
    {"value": "祁县优抚医院","address": ""},
    {"value": "晋中婴泰妇产医院","address": ""},
    {"value": "晋中仁安精神病医院","address": ""},
    {"value": "晋中开发区脑瘫康复医院","address": ""},
    {"value": "平遥晋芸正骨医院","address": ""},
    {"value": "平遥康和医院","address": ""},
    {"value": "平遥长龄脑血管病医院","address": ""},
    {"value": "忻州市忻府区中医院","address": ""},
    {"value": "忻州市忻府区庄磨镇卫生院（忻州市忻府区医疗集团庄磨镇卫生院）","address": ""},
    {"value": "忻州市忻府区豆罗镇中心卫生院","address": ""},
    {"value": "忻州市忻府区云中路街道社区卫生服务中心（忻州市忻府区医疗集团云中路街道社区卫生服务中心）","address": ""},
    {"value": "忻州市忻府区西张镇卫生院紫岩分院","address": ""},
    {"value": "忻州市忻府区阳坡乡卫生院","address": ""},
    {"value": "忻州市忻府区旭来街街道社区卫生服务中心","address": ""},
    {"value": "忻州市忻府区曹张乡卫生院（忻州市忻府区医疗集团曹张乡卫生院）","address": ""},
    {"value": "忻州市忻府区妇产医院","address": ""},
    {"value": "晋中榆次天玺医院","address": ""},
    {"value": "晋中莱茵口腔门诊部","address": ""},
    {"value": "晋中经纬医院（有限公司）","address": ""},
    {"value": "五台县医疗集团白家庄镇卫生院","address": ""},
    {"value": "右玉利康中医院","address": ""},
    {"value": "太原华夏医院","address": ""},
    {"value": "寿阳县医疗集团（寿阳县人民医院）","address": ""},
    {"value": "原平市大牛店镇卫生院","address": ""},
    {"value": "定襄县医疗集团河边镇中心卫生院李家庄分院","address": ""},
    {"value": "北大医疗潞安医院府秀江南医务所","address": ""},
    {"value": "怀仁新时代博爱医院","address": ""},
    {"value": "阳泉市矿区医疗集团（阳泉市矿区医院）平潭街社区卫生服务中心","address": ""},
    {"value": "临县医疗集团碛口镇中心卫生院","address": ""},
    {"value": "阳城县凤城镇中心卫生院","address": ""},
    {"value": "太原市小店区南站社区卫生服务站","address": ""},
    {"value": "怀仁市医疗集团河头卫生院","address": ""},
    {"value": "怀仁市医疗集团新家园卫生院（怀仁市新家园卫生院、怀仁市新家园乡妇幼保健计划生育服务站）","address": ""},
    {"value": "怀仁市中医院","address": ""},
    {"value": "怀仁市医疗集团马辛庄卫生院（怀仁市马辛庄卫生院、怀仁市马辛庄乡妇幼保健计划生育服务站）","address": ""},
    {"value": "怀仁市医疗集团亲和卫生院","address": ""},
    {"value": "怀仁市医疗集团毛皂卫生院","address": ""},
    {"value": "怀仁市医疗集团（怀仁市医疗集团人民医院、怀仁市人民医院）","address": ""},
    {"value": "太原天合口腔门诊管理有限公司万柏林晋祠路门诊部","address": ""},
    {"value": "朔州市朔城区南城街道办事处社区卫生服务中心","address": ""},
    {"value": "太原欣雅口腔门诊部","address": ""},
    {"value": "晋城市颐青园医疗服务有限公司","address": ""},
    {"value": "霍州市中医医院","address": ""},
    {"value": "古交市桃园街道办事处青山路社区卫生服务站","address": ""},
    {"value": "闻喜县河底镇中心卫生院","address": ""},
    {"value": "盂县阳光百姓药房有限公司西烟门诊","address": ""},
    {"value": "汾阳康美口腔医院","address": ""},
    {"value": "运城市盐湖区北城办事处阳光社区卫生服务站","address": ""},
    {"value": "沁水县康元堂医疗服务有限公司","address": ""},
    {"value": "襄垣县夏店中心卫生院","address": ""},
    {"value": "太原市万柏林区华清苑社区卫生服务站","address": ""},
    {"value": "阳泉市紧急医疗救援中心","address": ""},
    {"value": "定襄县医疗集团宏道镇中心卫生院","address": ""},
    {"value": "长治市屯留区中医院","address": ""},
    {"value": "灵丘县武灵镇卫生院分院","address": ""},
    {"value": "方山百川中医医院","address": ""},
    {"value": "尧都满意口腔门诊部","address": ""},
    {"value": "西山煤电（集团）有限责任公司古交矿区总医院西曲分院","address": ""},
    {"value": "山西荣隆泰安康医药有限公司安瑞疗综合门诊部","address": ""},
    {"value": "太原艾德仕医疗有限责任公司万柏林艾德仕义井口腔门诊部","address": ""},
    {"value": "盐湖卓越佳禾口腔门诊部","address": ""},
    {"value": "保德县医疗集团孙家沟乡分院","address": ""},
    {"value": "山西万峰医院","address": ""},
    {"value": "长治市潞城区医疗集团妇幼保健计划生育服务中心","address": ""},
    {"value": "清徐爱尔眼科门诊部","address": ""},
    {"value": "晋中市荣军优抚医院（晋中市心理卫生中心）","address": ""},
    {"value": "隰县晋西医院","address": ""},
    {"value": "太原众康乐口腔门诊部","address": ""},
    {"value": "同煦苑社区卫生服务站","address": ""},
    {"value": "山西大同大学医院平城区同大卫生服务中心","address": ""},
    {"value": "大同市云冈区安益康中西医结合医院有限公司","address": ""},
    {"value": "半坡西街社区卫生服务站","address": ""},
    {"value": "太原市迎泽区青年路一社区卫生服务站","address": ""},
    {"value": "临汾市尧都区车站街办事处建设社区卫生服务站","address": ""},
    {"value": "交口县水头镇卫生院","address": ""},
    {"value": "朔州市平鲁区利尔康门诊","address": ""},
    {"value": "新绛青燕妇产医院（新绛职工医院）","address": ""},
    {"value": "五寨县医疗集团小河头镇中心卫生院（五寨县小河头镇中心卫生院）","address": ""},
    {"value": "运城风陵渡开发区创伤医院","address": ""},
    {"value": "文华苑社区卫生服务站","address": ""},
    {"value": "阳城县蟒河镇卫生院","address": ""},
    {"value": "太原牛丽口腔门诊管理有限公司杏花岭万达口腔门诊部","address": ""},
    {"value": "临汾市尧都区解放路办事处社区卫生服务中心","address": ""},
    {"value": "晋中榆次雅西口腔门诊部（有限公司）","address": ""},
    {"value": "晋中榆次雷红口腔门诊部","address": ""},
    {"value": "晋中榆次雅康口腔门诊部","address": ""},
    {"value": "晋中刘双全蕴华口腔门诊部有限公司","address": ""},
    {"value": "晋中梁树彪口腔门诊部","address": ""},
    {"value": "晋中美康口腔门诊部（有限公司）","address": ""},
    {"value": "晋中榆次博康口腔门诊部","address": ""},
    {"value": "晋中民康医院","address": ""},
    {"value": "榆次长河骨伤科医院","address": ""},
    {"value": "晋中榆次新雅口腔门诊部","address": ""},
    {"value": "晋中榆次优德口腔门诊部","address": ""},
    {"value": "晋中榆次平安医院","address": ""},
    {"value": "榆社县学府社区卫生服务站","address": ""},
    {"value": "榆社县滨河社区卫生服务站","address": ""},
    {"value": "仁康医院","address": ""},
    {"value": "榆社县东花苑社区卫生服务站","address": ""},
    {"value": "榆社县文峰社区卫生服务站","address": ""},
    {"value": "榆社县东升社区卫生服务站","address": ""},
    {"value": "漳河医院","address": ""},
    {"value": "榆社县广生社区卫生服务站","address": ""},
    {"value": "太原钢铁（集团）有限公司总医院大关山矿分院","address": ""},
    {"value": "定襄县中医院","address": ""},
    {"value": "定襄县医疗集团（定襄县人民医院、定襄县医疗集团人民医院）","address": ""},
    {"value": "左权佑爱医院","address": ""},
    {"value": "和顺民生医院","address": ""},
    {"value": "五台县中医院","address": ""},
    {"value": "昔阳益民医院有限公司","address": ""},
    {"value": "昔阳大众医院","address": ""},
    {"value": "昔阳普济医院","address": ""},
    {"value": "昔阳康安心理医院","address": ""},
    {"value": "代县医疗集团(代县人民医院、代县医疗集团人民医院)","address": ""},
    {"value": "代县枣林镇中心卫生院（代县医疗集团枣林镇中心卫生院、代县枣林镇妇幼保健计划生育服务站）","address": ""},
    {"value": "代县雁门关乡卫生院（代县医疗集团雁门关乡卫生院、代县雁门关乡妇幼保健计划生育服务站）","address": ""},
    {"value": "代县峨口镇中心卫生院（代县医疗集团峨口镇中心卫生院、代县峨口镇妇幼保健计划生育服务站）","address": ""},
    {"value": "代县滩上镇中心卫生院（代县医疗集团滩上镇中心卫生院、代县滩上镇妇幼保健计划生育服务站）","address": ""},
    {"value": "代县上馆镇卫生院（代县医疗集团上馆镇卫生院、代县上馆镇妇幼保健计划生育服务站）","address": ""},
    {"value": "代县阳明堡镇中心卫生院（代县医疗集团阳明堡镇中心卫生院、代县阳明堡镇妇幼保健计划生育服务站）","address": ""},
    {"value": "代县新高乡卫生院（代县医疗集团新高乡卫生院、代县新高乡妇幼保健计划生育服务站）","address": ""},
    {"value": "代县医疗集团聂营镇卫生院（代县聂营镇卫生院）","address": ""},
    {"value": "寿阳县城西医院","address": ""},
    {"value": "繁峙县第二人民医院","address": ""},
    {"value": "晋中太谷骨科医院","address": ""},
    {"value": "繁峙县光裕堡乡卫生院（繁峙县医疗集团光裕堡乡卫生院）","address": ""},
    {"value": "繁峙县集义庄乡卫生院（繁峙县医疗集团集义庄乡卫生院、繁峙县集义庄乡妇幼保健计划生育服务站）","address": ""},
    {"value": "晋中太谷友好医院","address": ""},
    {"value": "繁峙县繁城镇卫生院","address": ""},
    {"value": "繁峙县医疗集团繁城镇卫生院","address": ""},
    {"value": "晋中太谷阳光妇产医院","address": ""},
    {"value": "太谷程氏骨科医院","address": ""},
    {"value": "晋中太谷白塔医院","address": ""},
    {"value": "繁峙县下茹越乡卫生院（繁峙县医疗集团下茹越乡卫生院、繁峙县下茹越乡妇幼保健计划生育服务站）","address": ""},
    {"value": "怀仁市医疗集团小峪社区卫生服务站","address": ""},
    {"value": "昔阳县大寨中心卫生院","address": ""},
    {"value": "侯马天主教眼科医院","address": ""},
    {"value": "山西仁安医院","address": ""},
    {"value": "西街大十字社区卫生服务站","address": ""},
    {"value": "稷山县中医院","address": ""},
    {"value": "北大医疗潞安医院煤基清洁能源医务室","address": ""},
    {"value": "山西兰花煤炭实业集团有限公司望云煤矿医务室","address": ""},
    {"value": "运城市盐湖区安邑办事处橄榄城社区卫生服务站","address": ""},
    {"value": "太原市万柏林区万柏林社区卫生服务中心","address": ""},
    {"value": "盂县医疗集团南娄镇卫生院西小坪工业园区分院","address": ""},
    {"value": "太原市尖草坪区心和堂门诊部","address": ""},
    {"value": "保德县桥头镇卫生院","address": ""},
    {"value": "定襄仁康医院","address": ""},
    {"value": "稷山县老年病专科医院","address": ""},
    {"value": "山西恒医美口腔门诊连锁有限公司尖草坪摄乐街口腔门诊部","address": ""},
    {"value": "临猗县城市社区卫生服务中心","address": ""},
    {"value": "定昌镇南关社区卫生三所","address": ""},
    {"value": "柳林县医疗集团王家沟乡卫生院","address": ""},
    {"value": "长子县妇幼保健计划生育服务中心","address": ""},
    {"value": "太原市万柏林区兴华社区卫生服务中心","address": ""},
    {"value": "沁水县胡底乡卫生院樊庄分院","address": ""},
    {"value": "临猗县七级镇卫生院（临猗县医疗集团七级镇卫生院）","address": ""},
    {"value": "洪洞县医疗集团（洪洞县人民医院、洪洞县医疗集团人民医院）","address": ""},
    {"value": "恒月口腔门诊部","address": ""},
    {"value": "阳城县凤城镇八甲口卫生院","address": ""},
    {"value": "太原市尖草坪区中心医院","address": ""},
    {"value": "云冈区和顺社区卫生服务中心","address": ""},
    {"value": "尧都区妇幼保健计划生育服务中心","address": ""},
    {"value": "太原奥蕊连锁口腔门诊管理有限公司小店寇庄西路口腔部","address": ""},
    {"value": "岢岚县西豹峪中心卫生院","address": ""},
    {"value": "稷山县人民医院","address": ""},
    {"value": "永济德善堂中医馆","address": ""},
    {"value": "左权县桐峪镇中心卫生院（左权县医疗集团桐峪镇中心卫生院）","address": ""},
    {"value": "山西齿科医院（有限公司）太原亲贤北街门诊部","address": ""},
    {"value": "定昌镇西湖社区卫生二所","address": ""},
    {"value": "晋城市城区东街街道办事处泽州北路社区卫生服务站","address": ""},
    {"value": "平城区德卓尔口腔门诊部","address": ""},
    {"value": "太原市万柏林区坑口社区卫生服务站","address": ""},
    {"value": "晋中刘双全文苑口腔门诊部有限公司","address": ""},
    {"value": "应县和谐医院","address": ""},
    {"value": "长子县慈林中心卫生院张店分院长子县医疗集团慈林分院张店分部","address": ""},
    {"value": "太原市迎泽区桃园路二社区卫生服务站","address": ""},
    {"value": "临县刘家会镇中心卫生院、临县医疗集团刘家会镇中心卫生院","address": ""},
    {"value": "太原市杏花岭区涧河社区卫生服务中心","address": ""},
    {"value": "大宁县医疗集团太古乡卫生院","address": ""},
    {"value": "曲沃京都医院","address": ""},
    {"value": "晋城市城区西街街道办事处前书院社区卫生服务站","address": ""},
    {"value": "古交市妇幼保健计划生育服务中心（古交市人民医院）","address": ""}]
export default hospital_name